
























import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseDialogFullScreen from '@/base/app/components/molecules/BaseDialogFullScreen.vue';
import TextEditor from '@/base/app/components/molecules/TextEditor.vue';

import { useCourseDescriptionDialog } from './CourseDescriptionDialogComposable';

export default defineComponent({
  name: 'ContentsCourseDescriptionDialog',
  components: { BaseDialogFullScreen, TextEditor },
  emits: ['done'],
  setup(_, { emit }: SetupContext) {
    return useCourseDescriptionDialog(emit);
  },
});
