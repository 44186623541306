import { ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { ProblemListItemClickAnchorPayload } from '@/base/app/components/molecules/ProblemListItemComposable';
import { ProblemUtilsProblem } from '@/base/app/utils/ProblemUtils';

export type ProblemItemDialogClickAnchorPayload = ProblemListItemClickAnchorPayload;

export function useProblemItemDialog(
  emit: (name: string, arg: ProblemItemDialogClickAnchorPayload) => void
) {
  const dialog = ref(false);
  const problem = ref<ProblemUtilsProblem>();

  function close() {
    dialog.value = false;
    problem.value = undefined;
  }
  watch(dialog, (newVal) => {
    if (newVal) return;
    close();
  });

  function open(target: { problem: ProblemUtilsProblem }) {
    problem.value = target.problem;
    dialog.value = true;
  }

  function clickAnchor(payload: ProblemListItemClickAnchorPayload) {
    emit('click-anchor', payload);
  }

  const msgs = useMessages({ prefix: 'contents.molecules.problemItemDialog' });
  return {
    dialog,
    problem,
    labelClose: msgs.of('close'),
    close,
    open,
    clickAnchor,
  };
}

export type ProblemItemDialog = ReturnType<typeof useProblemItemDialog>;
