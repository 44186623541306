













































import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import ErrorMessages from '@/base/app/components/molecules/ErrorMessages.vue';
import HintIcon from '@/base/app/components/molecules/HintIcon.vue';

import ContentVersionForm from '../molecules/ContentVersionForm.vue';
import { useContentConfirmEditingDialog } from './ContentConfirmEditingDialogComposable';

export default defineComponent({
  name: 'ContentsContentConfirmEditingDialog',
  components: { BaseButton, BaseMarkdown, ContentVersionForm, ErrorMessages, HintIcon },
  inheritAttrs: false,
  setup(_, { emit }: SetupContext) {
    return useContentConfirmEditingDialog(emit);
  },
});
