


















import { defineComponent, PropType } from '@vue/composition-api';

import { ContentItemContent, PropsContentItem, useContentItem } from './ContentItemComposable';

type Props = PropsContentItem;

export default defineComponent({
  name: 'ContentsContentItem',
  inheritAttrs: false,
  props: {
    content: { type: Object as PropType<ContentItemContent>, required: true },
    width: { type: String, default: 'min(400px, 80vw)' },
  },
  setup(props: Props) {
    return useContentItem(props);
  },
});
