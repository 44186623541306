import { computed, onMounted, ref, watch } from '@vue/composition-api';
import { useTimeoutFn } from '@vueuse/core';

import { useMessages } from '@/base/app';
import {
  ProblemViewChoiceValue,
  ProblemViewClickAnchorPayload,
} from '@/base/app/components/molecules/ProblemViewComposable';
import { DialogName, useDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { getElementRect } from '@/base/app/utils/DomUtils';
import {
  ProblemUtilsNavigator,
  ProblemUtilsNavigatorValue,
  ProblemUtilsProblem,
} from '@/base/app/utils/ProblemUtils';
import { useVuetify } from '@/base/app/utils/VuetifyUtils';
import { Optional } from '@/base/types';
import { GetCourseContentDetailsContent } from '@/contents/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { isDefined } from '@/utils/TsUtils';
import { useRoute } from '@/utils/VueUtils';

import {
  ContentHeaderDialog,
  ContentHeaderDialogRefreshPayload,
} from './ContentHeaderDialogComposable';
import { ExamBodyDialog, ExamBodyDialogRefreshPayload } from './ExamBodyDialogComposable';
import {
  ExamPassingStandardDialog,
  ExamPassingStandardDialogRefreshPayload,
} from './ExamPassingStandardDialogComposable';
import { ProblemListDialog, ProblemListDialogRefreshPayload } from './ProblemListDialogComposable';

const STYLE_EMPTY = {
  class: undefined as Optional<string>,
  style: undefined as Optional<Record<string, string>>,
};
const STYLES = {
  frame: STYLE_EMPTY,
  windows: STYLE_EMPTY,
  problem: { minHeight: '75vh' as Optional<string> },
};
type Styles = typeof STYLES;

type ContentExamProblemIndex = number;

export type ContentExamChoicePayload = {
  index: ContentExamProblemIndex;
  values: number[];
};

export type ContentExamToggleFlagPayload = {
  index: ContentExamProblemIndex;
};

export type ContentExamClickAnchorPayload = ProblemViewClickAnchorPayload;

export type ContentExamRefreshPayload =
  | ExamBodyDialogRefreshPayload
  | ContentHeaderDialogRefreshPayload
  | ProblemListDialogRefreshPayload
  | ExamPassingStandardDialogRefreshPayload;

export type PropsContentExam = {
  index: ProblemUtilsNavigatorValue;
  content: GetCourseContentDetailsContent;
  problems: ProblemUtilsProblem[];
  problemNavigator?: ProblemUtilsNavigator;
  isConfirmedEditing: boolean;
  showProminent?: boolean;
  shrinkLimit?: number;
  hide: boolean;
  disabled: boolean;
};

function useHeaderDialog(props: PropsContentExam) {
  const headerDialog = ref<ContentHeaderDialog>();
  const { getQuery, moveTo: moveToHeader } = useDialogQuery(DialogName.CONTENTS_HEADER);
  function watchHeaderDialogQuery() {
    if (!headerDialog.value) return;
    const q = getQuery();
    if (q) {
      headerDialog.value.open({ courseId: props.content.courseId, contentId: props.content.id });
    } else if (!q && headerDialog.value.opened()) {
      headerDialog.value.close();
    }
  }
  return { headerDialog, moveToHeader, watchHeaderDialogQuery };
}

function useBodyDialog(props: PropsContentExam) {
  const bodyDialog = ref<ExamBodyDialog>();
  const { getQuery, moveTo } = useDialogQuery(DialogName.CONTENTS_EXAM_BODY);
  function moveToBody(index?: Optional<number>) {
    if (index === undefined) moveTo();
    else moveTo({ index: `${index}` });
  }
  function watchBodyDialogQuery() {
    if (!bodyDialog.value) return;
    const q = getQuery();
    if (q && q.params && 'index' in q.params) {
      if (!Number.isFinite(parseInt(q.params.index, 10))) return;
      bodyDialog.value.open({
        id: props.content.id,
        isConfirmedEditing: props.isConfirmedEditing,
        index: parseInt(q.params.index, 10),
      });
    } else if (q) {
      bodyDialog.value.open({ id: props.content.id, isConfirmedEditing: props.isConfirmedEditing });
    } else if (!q && bodyDialog.value.opened()) {
      bodyDialog.value.close();
    }
  }
  return { bodyDialog, moveToBody, watchBodyDialogQuery };
}

function useProblemListDialog(props: PropsContentExam) {
  const problemListDialog = ref<ProblemListDialog>();
  const { getQuery, moveTo: moveToProblemList } = useDialogQuery(DialogName.CONTENTS_PROBLEM_LIST);
  function watchProblemListDialogQuery() {
    if (!problemListDialog.value) return;
    const q = getQuery();
    if (q) {
      problemListDialog.value.open({
        id: props.content.id,
        isConfirmedEditing: props.isConfirmedEditing,
        type: 'exam',
      });
    } else if (!q && problemListDialog.value.opened()) {
      problemListDialog.value.close();
    }
  }
  return { problemListDialog, moveToProblemList, watchProblemListDialogQuery };
}

function usePassingStandardDialog(props: PropsContentExam) {
  const passingStandardDialog = ref<ExamPassingStandardDialog>();
  function open() {
    assertIsDefined(passingStandardDialog.value);
    passingStandardDialog.value.open({
      contentId: props.content.id,
      isConfirmedEditing: props.isConfirmedEditing,
    });
  }
  return { passingStandardDialog, open };
}

export function useContentExam(
  props: PropsContentExam,
  emit: (
    name: string,
    arg:
      | ProblemUtilsNavigatorValue
      | ContentExamChoicePayload
      | ContentExamToggleFlagPayload
      | ContentExamClickAnchorPayload
      | ContentExamRefreshPayload
  ) => void
) {
  const msgs = useMessages({ prefix: 'contents.organisms.contentExam' });
  const route = useRoute();
  const { headerDialog, moveToHeader, watchHeaderDialogQuery } = useHeaderDialog(props);
  const { bodyDialog, moveToBody, watchBodyDialogQuery } = useBodyDialog(props);
  const { problemListDialog, moveToProblemList, watchProblemListDialogQuery } =
    useProblemListDialog(props);
  const { passingStandardDialog, open: openPassingStandard } = usePassingStandardDialog(props);

  function initDialog() {
    watchHeaderDialogQuery();
    watchBodyDialogQuery();
    watchProblemListDialogQuery();
  }

  function init() {
    initDialog();
  }

  function change(payload: ProblemUtilsNavigatorValue) {
    emit('change', payload);
  }

  function prev() {
    if (!props.problemNavigator || props.problemNavigator.prev.value === undefined) return;
    emit('change', props.problemNavigator.prev.value);
  }

  function next() {
    if (!props.problemNavigator || props.problemNavigator.next.value === undefined) return;
    emit('change', props.problemNavigator.next.value);
  }

  function choiceValue(v: ProblemViewChoiceValue) {
    if (props.index === 'results') return;
    emit('choice-value', { index: props.index, values: v });
  }

  function toggleFlag() {
    if (props.index === 'results') return;
    emit('toggle-flag', { index: props.index });
  }

  function clickAnchor(payload: ProblemViewClickAnchorPayload) {
    emit('click-anchor', payload);
  }

  function refresh(
    payload:
      | ExamBodyDialogRefreshPayload
      | ContentHeaderDialogRefreshPayload
      | ProblemListDialogRefreshPayload
      | ExamPassingStandardDialogRefreshPayload
  ) {
    emit('refresh', payload);
  }

  const navigatorLabel = computed(() => {
    if (props.disabled) return undefined;
    return msgs.of('problems', { size: props.problems.length }).value;
  });

  const requiredTime = computed(
    () => msgs.of('requiredTimeValue', { requiredTime: props.content.requiredTime }).value
  );
  const passingStandard = computed(() => {
    if (props.content.type !== 'exam') return undefined;
    const { passingStandard: v } = props.content.body;
    if (isDefined(v)) {
      const count = props.problems.length;
      let percent: string | number = '-';
      if (v === 0) percent = 0;
      else if (count > 0) percent = Math.round((v / count) * 1000) / 10;
      const s = msgs.of('passingStandardValue', {
        value: v,
        percent: percent > 100 ? '-' : percent,
      }).value;
      if (percent > 100) return `<span class="error--text">${s}</span>`;
      return s;
    }
    return msgs.of('passingStandardNone').value;
  });

  const { mobile } = useVuetify();
  const vWindow = ref<Vue>();
  const styles = ref<Styles>(STYLES);

  function calculateStyle() {
    if (!vWindow.value || props.hide) return;
    const rectVWindow = vWindow.value.$el.getBoundingClientRect();
    const { top: vwTop } = rectVWindow;
    if (props.index === 'results' || mobile.value) {
      styles.value = {
        ...STYLES,
        problem: { minHeight: 'calc(100vh - 48px)' },
      };
    } else {
      const rectMain = getElementRect('.content-exam');
      if (!rectMain) return;
      const rectBottom = getElementRect('.content-exam-bottom');
      styles.value = {
        ...STYLES,
        frame: { class: undefined, style: { maxHeight: `calc(100vh - ${rectMain.top + 8}px)` } },
        windows: { class: 'overflow-y-auto', style: undefined },
        problem: { minHeight: `calc(100vh - ${vwTop + (rectBottom?.height ?? 0) + 16}px)` },
      };
    }
  }
  onMounted(() => {
    init();
    useTimeoutFn(calculateStyle, 1000);
  });
  watch(
    () => [props.index, props.hide, mobile.value],
    () => useTimeoutFn(calculateStyle, 500)
  );
  watch(
    () => route.query,
    () => {
      initDialog();
      useTimeoutFn(calculateStyle, 1000);
    }
  );

  return {
    requiredTime,
    passingStandard,
    navigatorLabel,
    vWindow,
    styles,
    headerDialog,
    bodyDialog,
    problemListDialog,
    passingStandardDialog,
    labelAdd: msgs.of('add'),
    labelEdit: msgs.of('edit'),
    labelRequiredTime: msgs.of('requiredTime'),
    labelPassingStandard: msgs.of('passingStandard'),
    labelSort: msgs.of('sort'),
    labelRaiseFlag: msgs.of('raiseFlag'),
    labelNoData: msgs.of('noData'),
    moveToHeader,
    moveToBody,
    moveToProblemList,
    openPassingStandard,
    change,
    prev,
    next,
    choiceValue,
    toggleFlag,
    clickAnchor,
    refresh,
  };
}
