





























import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButtonIcon from '@/base/app/components/atoms/BaseButtonIcon.vue';
import ProblemListItem from '@/base/app/components/molecules/ProblemListItem.vue';

import { useProblemItemDialog } from './ProblemItemDialogComposable';

export default defineComponent({
  name: 'ContentProblemItemDialog',
  components: { ProblemListItem, BaseButtonIcon },
  inheritAttrs: false,
  props: {
    maxHeight: { type: String, default: '60vh' },
  },
  emits: ['click-anchor'],
  setup(_, { emit }: SetupContext) {
    return useProblemItemDialog(emit);
  },
});
