












import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseTable from '@/base/app/components/molecules/BaseTable.vue';

import { CourseTableItem, PropsCourseTable, useCourseTable } from './CourseTableComposable';

type Props = PropsCourseTable;

export default defineComponent({
  name: 'ContentsCourseTable',
  components: { BaseTable },
  inheritAttrs: false,
  props: {
    courses: { type: Array as PropType<CourseTableItem[]>, default: () => [] },
    headerKeys: {
      type: Array as PropType<string[]>,
      default: () => ['name', 'statusLabel', 'hasEditingContentLabel'],
    },
  },
  emits: ['action'],
  setup(props: Props, { emit }: SetupContext) {
    return useCourseTable(props, emit);
  },
});
