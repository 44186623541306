import { computed, ref } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';

import { useMessages } from '../../Messages';
import { BaseTable, BaseTableActionPayload } from './BaseTableComposable';

const HEADERS: DataTableHeader[] = [
  { value: 'no', text: '', width: 140, align: 'center' },
  { value: 'name', text: '', class: 'base-table-name' },
  { value: 'typeLabel', text: '', width: 140 },
  { value: 'requiredTime', text: '', width: 140, align: 'center' },
  { value: 'hasEditingVersionLabel', text: '', width: 140 },
  { value: 'preview', text: '', width: 100, align: 'center', sortable: false },
  { value: 'version', text: '', width: 140, align: 'center' },
];

function useTable() {
  const table = ref<BaseTable>();
  function clear() {
    if (table.value) table.value.clearSelection();
  }
  return { table, clear };
}

export type ContentTableItem = {
  name: string;
  id: string;
  type: string;
  requiredTime?: number;
  version?: number;
  hasEditingVersion?: boolean;
};

export type PropsContentTable = {
  contents: ContentTableItem[];
  headerKeys: string[];
};

export type ContentTableActionPayload = {
  event: string;
  selected: ContentTableItem[];
};

export function useContentTable(
  props: PropsContentTable,
  emit: (name: string, arg: ContentTableActionPayload) => void
) {
  const msgs = useMessages({ prefix: 'base.molecules.contentTable' });

  const headers = computed(() =>
    props.headerKeys
      .map((value) => HEADERS.find((h) => h.value === value))
      .filter((h) => !!h)
      .map((h) => ({ ...h, text: h ? msgs.of(h.value).value : '' }))
  );
  const items = computed(() => {
    const labelEditing = msgs.of('hasEditingVersion').value;
    const labelFixed = msgs.of('versionFixed').value;
    return props.contents.map((c, i) => ({
      ...c,
      no: i + 1,
      typeLabel: msgs.of(c.type).value,
      hasEditingVersionLabel: c.hasEditingVersion ? labelEditing : labelFixed,
    }));
  });

  function action(payload: BaseTableActionPayload) {
    const selected = payload.selected
      .map((id) => props.contents.find((c) => c.id === id))
      .filter((c) => c) as ContentTableItem[];
    emit('action', { ...payload, selected });
  }

  return { headers, items, action, ...useTable() };
}

export type ContentTable = ReturnType<typeof useContentTable>;
