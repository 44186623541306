import { nextTick, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { toQuery } from '@/base/app/components/atoms/ReturnButtonComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { waitTransition } from '@/base/app/utils/TransitionUtils';
import { isFailed, isSucceeded } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { useRoute, useRouter } from '@/utils/VueUtils';

import { useChangeEditingCourseContents, useGetCourseForEditing } from '../../../usecases';
import { ContentNameForm, ContentNameFormValue } from '../molecules/ContentNameFormComposable';

export function useContentAddDialog(emit: (name: string) => void) {
  const router = useRouter();
  const route = useRoute();

  const form = ref<ContentNameForm>();
  const dialog = ref(false);
  const loading = ref(false);
  const errors = ref<ErrorMessage[]>();
  const input = ref<ContentNameFormValue>({ name: '', type: 'text', requiredTime: 15 });
  const courseId = ref<string>();

  function close() {
    dialog.value = false;
    errors.value = undefined;
    courseId.value = undefined;
  }
  watch(dialog, (newVal) => {
    if (!newVal) close();
  });

  function open(payload: { courseId: string }) {
    input.value = { name: '', type: 'text', requiredTime: 15 };
    courseId.value = payload.courseId;
    dialog.value = true;
    nextTick(() => {
      if (form.value) form.value.reset();
    });
  }

  const getCourse = useGetCourseForEditing();
  async function fetchCourse() {
    assertIsDefined(courseId.value);
    const res = await getCourse.execute({ id: courseId.value });
    if (isSucceeded(res)) return res.course;
    return undefined;
  }

  const changeContents = useChangeEditingCourseContents();
  async function submit() {
    assertIsDefined(courseId.value);
    errors.value = undefined;

    loading.value = true;
    const course = await fetchCourse();
    loading.value = false;
    if (!course) return;

    loading.value = true;
    const res = await changeContents.execute({
      id: courseId.value,
      contents: [...course.contents, input.value],
    });
    if (isFailed(res)) {
      loading.value = false;
      errors.value = res.errors;
      return;
    }
    const [content] = res.course.contents.slice(-1);
    waitTransition(() => {
      loading.value = false;
      emit('done');
      close();
      if (!content) return;
      const query = { edit: '', ...toQuery(route, route.query) };
      router.push({ name: 'adminContent', params: { id: content.id, v: '1' }, query });
    });
  }

  const msgs = useMessages({ prefix: 'contents.organisms.contentAddDialog' });
  return {
    form,
    dialog,
    loading,
    errors,
    input,
    labelAdd: msgs.of('add'),
    labelClose: msgs.of('close'),
    close,
    open,
    submit,
  };
}

export type ContentAddDialog = ReturnType<typeof useContentAddDialog>;
