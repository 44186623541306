import { computed, nextTick, ref } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';
import {
  BaseDialogFullScreen,
  BaseDialogFullScreenValue,
} from '@/base/app/components/molecules/BaseDialogFullScreenComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { clearDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { ContentType } from '@/base/domains';
import { isSucceeded } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { useRoute, useRouter } from '@/utils/VueUtils';

import {
  GetCourseForEditingCourse,
  useChangeEditingCourseContents,
  useGetCourseForEditing,
} from '../../../usecases';

type Form = {
  name: string;
  requiredTime: string;
  id: string;
};

const DEFAULT_FORM: Form = { name: '', requiredTime: '', id: '' };

function useDialogFullScreen() {
  const dialogFullScreen = ref<BaseDialogFullScreen>();
  const dialog = ref<BaseDialogFullScreenValue>({ display: false });
  function opened() {
    return dialog.value.display;
  }
  function error(errors: ErrorMessage[]) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showErrorDialog(errors);
  }
  function info(msg: string, ok?: () => void) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showDialog(msg, ok);
  }
  return { dialogFullScreen, dialog, opened, error, info };
}

export type ContentHeaderDialogRefreshPayload = {
  item: ContentType;
};

export function useContentHeaderDialog(
  emit: (name: string, arg: ContentHeaderDialogRefreshPayload) => void
) {
  const msgs = useMessages({ prefix: 'contents.organisms.contentHeaderDialog' });
  const route = useRoute();
  const router = useRouter();

  const { dialogFullScreen, dialog, opened, error, info } = useDialogFullScreen();
  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const course = ref<GetCourseForEditingCourse>();
  const type = ref<ContentType>();
  const loading = ref(false);
  const input = ref<Form>({ ...DEFAULT_FORM });

  const getCourse = useGetCourseForEditing();
  async function fetch(courseId: string, contentId: string) {
    loading.value = true;
    const res = await getCourse.execute({ id: courseId });
    if (isSucceeded(res)) {
      const content = res.course?.contents.find((item) => item.id === contentId);
      if (content) {
        course.value = res.course;
        type.value = content.type;
        input.value = { ...content, requiredTime: content.requiredTime.toString() };
      } else {
        course.value = undefined;
        type.value = undefined;
        input.value = { ...DEFAULT_FORM };
      }
    } else {
      course.value = undefined;
      type.value = undefined;
      input.value = { ...DEFAULT_FORM };
    }
    loading.value = false;
  }

  function close() {
    dialog.value = { display: false };
    course.value = undefined;
    input.value = { ...DEFAULT_FORM };
    const to = clearDialogQuery(route);
    if (to) router.replace(to);
  }

  async function open(payload: { courseId: string; contentId: string }) {
    dialog.value = { display: true };
    await fetch(payload.courseId, payload.contentId);
    if (!course.value) info(msgs.of('noData').value, close);
  }

  const changeEditingCourse = useChangeEditingCourseContents();
  async function update(contentId: string, name: string, requiredTime: number) {
    assertIsDefined(course.value, 'course');
    const contents = [...course.value.contents];
    const index = contents.findIndex((item) => item.id === contentId);
    if (index === -1) return false;
    contents.splice(index, 1, { ...contents[index], name, requiredTime });
    dialog.value = { ...dialog.value, status: 'updating' };
    const res = await changeEditingCourse.execute({ id: course.value.id, contents });
    if (isSucceeded(res)) {
      dialog.value = { ...dialog.value, status: 'updated' };
      return true;
    }
    dialog.value = { ...dialog.value, status: 'changed' };
    error(res.errors);
    return false;
  }

  async function change() {
    dialog.value = { ...dialog.value, status: 'changed' };
    nextTick(async () => {
      assertIsDefined(observer.value);
      assertIsDefined(type.value, 'type');
      const valid = await observer.value.validate();
      if (!valid) return;
      const { id, name, requiredTime } = input.value;
      const ret = await update(id, name, parseInt(requiredTime, 10));
      if (ret) emit('refresh', { item: type.value });
    });
  }

  const notFound = computed(() => !course.value);
  const contentType = computed(() => {
    if (!type.value) return undefined;
    return msgs.of(type.value).value;
  });
  return {
    dialogFullScreen,
    dialog,
    observer,
    loading,
    notFound,
    contentType,
    input,
    labelTitle: msgs.of('editContent'),
    labelName: msgs.of('name'),
    labelRequiredTime: msgs.of('requiredTime'),
    labelRequiredTimeUnit: msgs.of('requiredTimeUnit'),
    close,
    open,
    opened,
    change,
  };
}

export type ContentHeaderDialog = ReturnType<typeof useContentHeaderDialog>;
