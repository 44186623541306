import { computed, nextTick, ref, watch } from '@vue/composition-api';
import { Location } from 'vue-router';

import { useMessages } from '@/base/app';
import { toQueryPath } from '@/base/app/components/atoms/ReturnButtonComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { waitTransition, waitTransitionLong } from '@/base/app/utils/TransitionUtils';
import { CourseId, CourseName } from '@/base/domains';
import { Optional } from '@/base/types';
import { isFailed } from '@/base/usecases';
import { CourseStatus } from '@/contents/domains';
import { useRouter } from '@/utils/VueUtils';

import {
  useChangeConfirmedCourseName,
  useChangeEditingCourseName,
  useCreateNewCourse,
} from '../../../usecases';
import { CourseNameForm, CourseNameFormValue } from '../molecules/CourseNameFormComposable';

type CourseNameDialogTarget =
  | {
      id: CourseId;
      status: CourseStatus;
      name: CourseName;
    }
  | {
      name: CourseName;
      originalId: CourseId;
    };

export type PropsCourseNameDialog = { from: string };

export function useCourseNameDialog(props: PropsCourseNameDialog, emit: (name: string) => void) {
  const router = useRouter();

  const dialog = ref(false);
  const target = ref<CourseNameDialogTarget>();

  const form = ref<CourseNameForm>();
  const input = ref<CourseNameFormValue>({ name: '' });
  const loading = ref(false);
  const errors = ref<ErrorMessage[]>();

  function close() {
    dialog.value = false;
    target.value = undefined;
    input.value = { name: '' };
    errors.value = undefined;
  }
  watch(dialog, (newVal) => {
    if (!newVal) close();
  });

  function open(payload?: CourseNameDialogTarget) {
    input.value = { name: payload?.name || '' };
    target.value = payload;
    dialog.value = true;
    nextTick(() => {
      if (form.value) form.value.reset();
    });
  }

  const create = useCreateNewCourse();
  const updateEditing = useChangeEditingCourseName();
  const updateConfirmed = useChangeConfirmedCourseName();
  async function submit() {
    errors.value = undefined;
    loading.value = true;
    let next: Optional<Location>;
    if (target.value && 'id' in target.value) {
      const { id, status, name: originalName } = target.value;
      if (input.value.name === originalName) {
        loading.value = false;
        close();
        return;
      }
      if (status === 'editing') {
        const res = await updateEditing.execute({ id, name: input.value.name });
        if (isFailed(res)) {
          loading.value = false;
          errors.value = res.errors;
          return;
        }
        waitTransitionLong(() => {
          loading.value = false;
          emit('done');
          close();
        });
        return;
      }
      const res = await updateConfirmed.execute({ id, name: input.value.name });
      if (isFailed(res)) {
        loading.value = false;
        errors.value = res.errors;
        return;
      }
    } else if (target.value && 'originalId' in target.value) {
      const { originalId } = target.value;
      const res = await create.execute({
        name: input.value.name,
        originalCourseId: originalId,
      });
      if (isFailed(res)) {
        loading.value = false;
        errors.value = res.errors;
        return;
      }
      next = {
        name: 'adminCourse',
        params: { id: res.course.id },
        query: { edit: '', ...toQueryPath(props.from) },
      };
    } else {
      const res = await create.execute({ name: input.value.name });
      if (isFailed(res)) {
        loading.value = false;
        errors.value = res.errors;
        return;
      }
      next = {
        name: 'adminCourse',
        params: { id: res.course.id },
        query: { edit: '', ...toQueryPath(props.from) },
      };
    }
    waitTransition(() => {
      loading.value = false;
      emit('done');
      close();
      if (next) router.push(next);
    });
  }

  const msgs = useMessages({ prefix: 'contents.organisms.courseNameDialog' });
  const labelChange = computed(() => {
    let key = 'add';
    if (target.value && 'id' in target.value) key = 'change';
    else if (target.value && 'originalId' in target.value) key = 'copy';
    return msgs.of(key).value;
  });

  return {
    form,
    dialog,
    loading,
    errors,
    input,
    labelChange,
    labelClose: msgs.of('close'),
    open,
    close,
    submit,
  };
}

export type CourseNameDialog = ReturnType<typeof useCourseNameDialog>;
