import { ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import {
  BaseDialogFullScreen,
  BaseDialogFullScreenValue,
} from '@/base/app/components/molecules/BaseDialogFullScreenComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { clearDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { isSucceeded } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { useRoute, useRouter } from '@/utils/VueUtils';

import {
  GetCourseForEditingCourse,
  useChangeConfirmedCourseContentsSort,
  useChangeEditingCourseContents,
  useGetCourseForEditing,
} from '../../../usecases';
import { ContentItemContent } from '../molecules/ContentItemComposable';

function useDialogFullScreen() {
  const dialogFullScreen = ref<BaseDialogFullScreen>();
  const dialog = ref<BaseDialogFullScreenValue>({ display: false });
  function opened() {
    return dialog.value.display;
  }
  function error(errors: ErrorMessage[]) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showErrorDialog(errors);
  }
  function info(message: string, ok: () => void) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showDialog(message, ok);
  }
  return { dialogFullScreen, dialog, opened, error, info };
}

export function useContentListDialog(emit: (name: string) => void) {
  const msgs = useMessages({ prefix: 'contents.organisms.contentListDialog' });

  const { dialog, dialogFullScreen, opened, error, info } = useDialogFullScreen();

  const route = useRoute();
  const router = useRouter();
  const loading = ref(false);
  const contents = ref<ContentItemContent[]>([]);
  const course = ref<GetCourseForEditingCourse>();
  const courseId = ref<string>();

  function close() {
    dialog.value = { display: false };
    contents.value = [];
    courseId.value = undefined;
    const to = clearDialogQuery(route);
    if (to) router.replace(to);
  }

  const getContents = useGetCourseForEditing();
  async function fetchCourse() {
    loading.value = true;
    assertIsDefined(courseId.value, 'courseId');
    const res = await getContents.execute({ id: courseId.value });
    if (isSucceeded(res)) {
      course.value = res.course;
      contents.value =
        res.course?.contents.map((item, i) => ({
          ...item,
          index: i,
        })) ?? [];
    } else {
      info(msgs.of('noData').value, close);
    }
    loading.value = false;
  }

  function open(payload: { id: string }) {
    courseId.value = payload.id;
    dialog.value = { display: true };
    fetchCourse();
  }

  const useUpdateContents = useChangeEditingCourseContents();
  const changeConfirmedCourseContentsSort = useChangeConfirmedCourseContentsSort();
  async function changeOrderOfContents() {
    assertIsDefined(courseId.value, 'courseId');
    dialog.value = { ...dialog.value, status: 'updating' };
    if (course.value?.status === 'enabled') {
      const contentIds = contents.value.map((c) => c.id);
      const res = await changeConfirmedCourseContentsSort.execute({
        id: courseId.value,
        contentIds,
      });
      if (isSucceeded(res)) {
        dialog.value = { ...dialog.value, status: 'updated' };
        contents.value = res.course.contents.map((item, i) => ({
          ...item,
          index: i,
        }));
        emit('done');
        return;
      }
      dialog.value = { ...dialog.value, status: 'changed' };
      error(res.errors);
      return;
    }
    const paramContents = contents.value.map((item) => ({
      id: item.id,
      type: item.type,
      name: item.name,
      requiredTime: item.requiredTime,
    }));
    const res = await useUpdateContents.execute({ id: courseId.value, contents: paramContents });
    if (isSucceeded(res)) {
      dialog.value = { ...dialog.value, status: 'updated' };
      contents.value = res.course.contents.map((item, i) => ({
        ...item,
        index: i,
      }));
      emit('done');
      return;
    }
    dialog.value = { ...dialog.value, status: 'changed' };
    error(res.errors);
  }

  return {
    open,
    opened,
    close,
    changeOrderOfContents,
    dialog,
    dialogFullScreen,
    loading,
    contents,
    labelNoData: msgs.of('noData'),
    title: msgs.of('editCourse'),
    subtitle: msgs.of('sortContents'),
  };
}

export type ContentListDialog = ReturnType<typeof useContentListDialog>;
