import { computed, onMounted, ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { toQuery } from '@/base/app/components/atoms/ReturnButtonComposable';
import {
  BaseDialogConfirm,
  BaseDialogConfirmClickAnchorPayload,
} from '@/base/app/components/molecules/BaseDialogConfirmComposable';
import { BaseDialogOk } from '@/base/app/components/molecules/BaseDialogOkComposable';
import {
  ErrorMessage,
  ReplaceError,
} from '@/base/app/components/molecules/ErrorMessagesComposable';
import { DialogAnchorConfirm } from '@/base/app/components/organisms/DialogAnchorConfirmComposable';
import { waitTransition, waitTransitionLong } from '@/base/app/utils/TransitionUtils';
import { ApplicationError } from '@/base/error';
import { COURSE_REMOVE_FAILED_GROUP_EXAM_IS_OPEN } from '@/base/ErrorCodes';
import { isFailed } from '@/base/usecases';
import { config } from '@/config';
import { assertIsDefined } from '@/utils/Asserts';
import { requiredInject, useRoute, useRouter } from '@/utils/VueUtils';

import { CourseStatus } from '../../../domains';
import { CORSE_CONTENT_EMPTY_PROBLEM_EXAM } from '../../../ErrorCodes';
import {
  useConfirmCourseEditing,
  useDisableCourse,
  useEnableCourse,
  useRemoveCourse,
} from '../../../usecases';
import { CoursesStoreKey } from '../../stores';
import {
  CourseTable,
  CourseTableActionPayload,
  CourseTableItem,
} from '../molecules/CourseTableComposable';
import { CourseNameDialog } from './CourseNameDialogComposable';

const MENUS_ENABLED = [
  { value: 'add' },
  { value: 'edit', single: true },
  { value: 'editName', single: true },
  { value: 'fix', required: true, mode: 'editing' },
  { value: 'copy', single: true, mode: 'fixed' },
  { value: 'disable', required: true, mode: 'fixed' },
  { value: 'removePermanently', required: true, mode: 'fixed' },
];

const MENUS_DISABLED = [
  { value: 'enable', required: true },
  { value: 'removePermanently', required: true },
];

function useTable() {
  const table = ref<CourseTable>();
  function clear() {
    if (table.value) table.value.clear();
  }
  return { table, clear };
}

function useConfirmDialog() {
  const confirmDialog = ref<BaseDialogConfirm>();
  function open(msg: string, ok: () => void) {
    assertIsDefined(confirmDialog.value);
    confirmDialog.value.open(msg, ok);
  }
  return { confirmDialog, open };
}

function useErrorDialog() {
  const errorDialog = ref<BaseDialogOk>();
  function error(errors: ErrorMessage[], replace?: ReplaceError[]) {
    assertIsDefined(errorDialog.value);
    errorDialog.value.error(errors, replace);
  }
  return { errorDialog, error };
}

function useAnchorDialog() {
  const anchorDialog = ref<DialogAnchorConfirm>();
  function clickAnchor(payload: BaseDialogConfirmClickAnchorPayload) {
    assertIsDefined(anchorDialog.value);
    anchorDialog.value.confirm(payload.event);
  }
  return { anchorDialog, clickAnchor };
}

function useCourseNameDialog() {
  const courseNameDialog = ref<CourseNameDialog>();
  function open(
    payload?: ({ id: string; status: CourseStatus } | { originalId: string }) & {
      name: string;
    }
  ) {
    assertIsDefined(courseNameDialog.value);
    courseNameDialog.value.open(payload);
  }
  return { courseNameDialog, open };
}

export function useCourses() {
  const msgs = useMessages({ prefix: 'contents.organisms.courses' });
  const router = useRouter();
  const route = useRoute();
  const { table, clear: clearTableSelection } = useTable();
  const { confirmDialog, open: confirm } = useConfirmDialog();
  const { errorDialog, error } = useErrorDialog();
  const { courseNameDialog, open: openCourseName } = useCourseNameDialog();

  const { courses, loading, fetch } = requiredInject(CoursesStoreKey);
  onMounted(fetch);

  function done() {
    clearTableSelection();
    fetch();
  }

  const mode = ref<'editing' | 'fixed'>();
  const disabled = ref(false);

  const fixCourse = useConfirmCourseEditing();
  async function fix(ids: string[]) {
    const res = await Promise.all(ids.map((id) => fixCourse.execute({ id })));
    const errors = res.map((r) => (isFailed(r) ? r.errors : [])).reduce((p, c) => p.concat(c), []);
    if (errors.length === 0) return true;
    return errors;
  }

  const enableCourse = useEnableCourse();
  async function enable(ids: string[]) {
    const res = await Promise.all(ids.map((id) => enableCourse.execute({ id })));
    const errors = res.map((r) => (isFailed(r) ? r.errors : [])).reduce((p, c) => p.concat(c), []);
    if (errors.length === 0) return true;
    return errors;
  }

  const disableCourse = useDisableCourse();
  async function disable(ids: string[]) {
    const res = await Promise.all(ids.map((id) => disableCourse.execute({ id })));
    const errors = res.map((r) => (isFailed(r) ? r.errors : [])).reduce((p, c) => p.concat(c), []);
    if (errors.length === 0) return true;
    return errors;
  }

  const removeCourse = useRemoveCourse();
  async function removePermanently(ids: string[]) {
    const res = await Promise.all(ids.map((id) => removeCourse.execute({ id })));
    const errors = res.map((r) => (isFailed(r) ? r.errors : [])).reduce((p, c) => p.concat(c), []);
    if (errors.length === 0) return true;
    return errors;
  }

  function removeCourseToMessageParams(errors: ApplicationError[]) {
    const ret = errors
      .map((e) => {
        if (!e.payload) return [];
        const p = e.payload as Parameters<
          typeof COURSE_REMOVE_FAILED_GROUP_EXAM_IS_OPEN.toApplicationError
        >[0];
        return p?.groups.map((g) => g.name || g.id) ?? '-';
      })
      .flat();
    return { groupNames: ret.join() };
  }

  function fixCourseToMessageParams(errors: ApplicationError[]) {
    const ret = errors
      .map((e) => {
        if (!e.payload) return [];
        const p = e.payload as Parameters<
          typeof CORSE_CONTENT_EMPTY_PROBLEM_EXAM.toApplicationError
        >[0];
        const courseName = courses.value.find((item) => item.id === p?.courseId)?.name ?? '';
        return p?.contents.map((c) => `${courseName}.${c.name || c.id}`) ?? '-';
      })
      .flat();
    return { contentNames: ret.join() };
  }

  async function action({ event, selected }: CourseTableActionPayload) {
    switch (event) {
      case 'add':
        openCourseName();
        break;
      case 'editName': {
        const [first] = selected;
        assertIsDefined(first, 'first');
        assertIsDefined(first.status, 'status');
        openCourseName({ id: first.id, name: first.name, status: first.status });
        break;
      }
      case 'edit':
      case 'show': {
        const [first] = selected;
        assertIsDefined(first, 'fist');
        const query = { edit: event === 'edit' ? '' : undefined, ...toQuery(route) };
        router.push({ name: 'adminCourse', params: { id: first.id }, query });
        break;
      }
      case 'fix': {
        if (selected.length === 0) return;
        const invalid = selected.some((item) => item.status !== 'editing');
        if (invalid) {
          error([msgs.of('errorFix').value]);
          return;
        }
        confirm(
          msgs.of('confirmFix', { helpUrl: config().app.confirmCourseHelpUrl }).value,
          async () => {
            loading.value = true;
            const ret = await fix(selected.map((item) => item.id));
            if (ret !== true) {
              error(ret, [
                {
                  from: 'CN0012',
                  to: {
                    prefix: 'contents.organisms.courses',
                    key: 'errorFixCourseEmptyProblems',
                    values: fixCourseToMessageParams,
                  },
                },
                {
                  from: 'CN0014',
                  to: {
                    prefix: 'contents.organisms.courses',
                    key: 'errorFixCourseInvalidPassingStandard',
                    values: fixCourseToMessageParams,
                  },
                },
              ]);
              loading.value = false;
              return;
            }
            waitTransitionLong(done);
          }
        );
        break;
      }
      case 'copy': {
        const [first] = selected;
        assertIsDefined(first, 'fist');
        if (first.status !== 'enabled') {
          error([msgs.of('errorCopy').value]);
          return;
        }
        openCourseName({
          originalId: first.id,
          name: msgs.of('copyOf', { name: first.name }).value,
        });
        break;
      }
      case 'removePermanently': {
        if (selected.length === 0) return;
        confirm(msgs.of('confirmRemovePermanently').value, async () => {
          loading.value = true;
          const ret = await removePermanently(selected.map((item) => item.id));
          if (ret !== true) {
            error(ret, [
              {
                from: 'BS0015',
                to: {
                  prefix: 'contents.organisms.courses',
                  key: 'errorRemovePermanentlyExamIsOpen',
                  values: removeCourseToMessageParams,
                },
              },
            ]);
            loading.value = false;
            return;
          }
          waitTransition(done);
        });
        break;
      }
      case 'enable': {
        if (selected.length === 0) return;
        const invalid = selected.some((item) => !item.status || item.status !== 'disabled');
        if (invalid) {
          error([msgs.of('errorEnable').value]);
          return;
        }
        loading.value = true;
        const ret = await enable(selected.map((item) => item.id));
        if (ret !== true) {
          error(ret);
          loading.value = false;
          return;
        }
        waitTransition(done);
        break;
      }
      case 'disable': {
        if (selected.length === 0) return;
        const invalid = selected.some((item) => !item.status || item.status !== 'enabled');
        if (invalid) {
          error([msgs.of('errorDisable').value]);
          return;
        }
        loading.value = true;
        const ret = await disable(selected.map((item) => item.id));
        if (ret !== true) {
          error(ret);
          loading.value = false;
          return;
        }
        waitTransition(done);
        break;
      }
      default:
    }
  }

  const menus = computed(() => {
    if (disabled.value) return MENUS_DISABLED.map((m) => ({ ...m, label: msgs.of(m.value).value }));
    return MENUS_ENABLED.filter((m) => !mode.value || !m.mode || m.mode === mode.value).map(
      (m) => ({ ...m, label: msgs.of(m.value).value })
    );
  });

  const items = computed<CourseTableItem[]>(() => {
    return courses.value
      .filter((c) => {
        if (disabled.value) return c.status === 'disabled';
        if (mode.value === 'editing') return c.status === 'editing';
        if (mode.value === 'fixed') return c.status === 'enabled';
        return c.status !== 'disabled';
      })
      .sort((a, b) => (a.name < b.name ? -1 : 1));
  });

  return {
    table,
    confirmDialog,
    errorDialog,
    courseNameDialog,
    loading,
    mode,
    disabled,
    menus,
    courses: items,
    labelFix: msgs.of('fix'),
    labelEditing: msgs.of('editing'),
    labelShowDisable: msgs.of('showDisable'),
    action,
    done,
    clearTableSelection,
    ...useAnchorDialog(),
  };
}
