import { computed, ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';

import { ContentVersionForm, ContentVersionValue } from './ContentVersionFormComposable';

export type ContentVersionEditorSubmitPayload = {
  value: ContentVersionValue;
  done: (errors?: ErrorMessage[]) => void;
};

export function useContentVersionEditor(
  emit: (name: string, args: ContentVersionEditorSubmitPayload) => void
) {
  const msgs = useMessages({ prefix: 'contents.molecules.contentVersionEditor' });

  const form = ref<ContentVersionForm>();
  const editing = ref(false);
  const updating = ref(false);
  const errors = ref<ErrorMessage[]>();

  function toggleEditing() {
    editing.value = !editing.value;
    errors.value = undefined;
    if (!editing.value && form.value) form.value.init();
  }

  function done(e?: ErrorMessage[]) {
    editing.value = !!e;
    updating.value = false;
    errors.value = e;
  }

  function submit(value: ContentVersionValue) {
    updating.value = true;
    errors.value = undefined;
    emit('submit', { value, done });
  }

  const labelEditing = computed(() => msgs.of(editing.value ? 'cancelEdit' : 'startEdit').value);

  return {
    form,
    editing,
    updating,
    errors,
    labelEditing,
    labelUpdate: msgs.of('update'),
    toggleEditing,
    done,
    submit,
  };
}
