import { computed, ref } from '@vue/composition-api';
import { useTimeoutFn } from '@vueuse/core';

import { useMessages } from '@/base/app';
import {
  BaseDialogFullScreen,
  BaseDialogFullScreenValue,
} from '@/base/app/components/molecules/BaseDialogFullScreenComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { DialogAnchorConfirm } from '@/base/app/components/organisms/DialogAnchorConfirmComposable';
import { clearDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { useProblemController } from '@/base/app/utils/ProblemUtils';
import { Exam, Problem, ProblemHeader } from '@/base/domains';
import { isFailed } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { useRoute, useRouter } from '@/utils/VueUtils';

import {
  useChangeEditingConfirmedContentWorkbookProblems,
  useChangeEditingConfirmedExamProblems,
  useCreateOrUpdateEditingCourseContentWorkbook,
  useGetEditingConfirmedContent,
  useGetEditingCourseContentBody,
  useGetEditingCourseContentWorkbook,
  useUpdateEditingCourseContentBody,
} from '../../../usecases';
import {
  ProblemItemClickAnchorPayload,
  ProblemItemClickPayload,
} from '../molecules/ProblemItemComposable';
import {
  ProblemItemDialog,
  ProblemItemDialogClickAnchorPayload,
} from '../molecules/ProblemItemDialogComposable';

export type ProblemListDialogRefreshPayload = {
  item: 'workbook' | 'exam';
  clear: boolean;
};

function useDialogFullScreen() {
  const dialogFullScreen = ref<BaseDialogFullScreen>();
  const dialog = ref<BaseDialogFullScreenValue>({ display: false });
  function opened() {
    return dialog.value.display;
  }
  function error(errors: ErrorMessage[]) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showErrorDialog(errors);
  }
  function info(message: string, ok: () => void) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showDialog(message, ok);
  }
  return { dialogFullScreen, dialog, opened, error, info };
}

function useItemDialog() {
  const itemDialog = ref<ProblemItemDialog>();
  function open(payload: ProblemItemClickPayload) {
    assertIsDefined(itemDialog.value);
    itemDialog.value.open({ problem: payload.problem });
  }
  return { itemDialog, open };
}

function useAnchorDialog() {
  const anchorDialog = ref<DialogAnchorConfirm>();
  function clickAnchor(
    payload: ProblemItemClickAnchorPayload | ProblemItemDialogClickAnchorPayload
  ) {
    assertIsDefined(anchorDialog.value);
    anchorDialog.value.confirm(payload.event);
  }
  return { anchorDialog, clickAnchor };
}

type Target = {
  id: string;
  type: 'text' | 'exam';
  isConfirmedEditing: boolean;
};

const WORKBOOK_EMPTY = {
  problems: [] as Problem[],
  problemHeaders: [] as ProblemHeader[],
};
type Workbook = typeof WORKBOOK_EMPTY;

type ContentBase = {
  id: string;
  version: number;
  isConfirmedEditing: boolean;
};

type ContentWorkbook = ContentBase & {
  type: 'workbook';
  workbook: Workbook;
  dataVersion?: number;
};
type ContentExam = ContentBase & {
  type: 'exam';
  body: Exam;
  dataVersion: number;
};

type Content = ContentWorkbook | ContentExam;

export function useProblemListDialog(
  emit: (name: string, args: ProblemListDialogRefreshPayload) => void
) {
  const msgs = useMessages({ prefix: 'contents.organisms.problemListDialog' });
  const route = useRoute();
  const router = useRouter();
  const { dialog, dialogFullScreen, opened, error, info } = useDialogFullScreen();
  const { itemDialog, open: openItem } = useItemDialog();

  const content = ref<Content>();
  const indexes = ref<number[]>([]);
  const savedIndexes = ref<number[]>();
  const loading = ref(false);

  const problemController = useProblemController({ content: computed(() => content.value) });

  function close() {
    if (content.value && savedIndexes.value)
      emit('refresh', { item: content.value.type, clear: true });
    dialog.value = { display: false };
    savedIndexes.value = undefined;
    const to = clearDialogQuery(route);
    if (to) router.replace(to);
  }

  const getConfirmedContent = useGetEditingConfirmedContent();
  const getEditingWorkbook = useGetEditingCourseContentWorkbook();
  async function fetchWorkbook(id: string, isConfirmedEditing: boolean) {
    if (isConfirmedEditing) {
      const res = await getConfirmedContent.execute({ id });
      if (isFailed(res)) return res.errors;
      if (!res.editingConfirmedContent || res.editingConfirmedContent.type !== 'text') return false;
      const { workbook, version, dataVersion } = res.editingConfirmedContent;
      content.value = {
        id,
        isConfirmedEditing,
        type: 'workbook',
        workbook: workbook ?? WORKBOOK_EMPTY,
        version,
        dataVersion,
      };
    } else {
      const res = await getEditingWorkbook.execute({ contentId: id });
      if (isFailed(res)) return res.errors;
      content.value = {
        id,
        isConfirmedEditing,
        type: 'workbook',
        workbook: res.workbook ?? WORKBOOK_EMPTY,
        version: 0,
        dataVersion: res.workbook?.dataVersion,
      };
    }
    return true;
  }

  const getEditingExam = useGetEditingCourseContentBody();
  async function fetchExam(id: string, isConfirmedEditing: boolean) {
    if (isConfirmedEditing) {
      const res = await getConfirmedContent.execute({ id });
      if (isFailed(res)) return res.errors;
      if (!res.editingConfirmedContent || res.editingConfirmedContent.type !== 'exam') return false;
      const { body, version, dataVersion } = res.editingConfirmedContent;
      content.value = { id, isConfirmedEditing, type: 'exam', body, version, dataVersion };
    } else {
      const res = await getEditingExam.execute({ contentId: id });
      if (isFailed(res)) return res.errors;
      if (res.body.type !== 'exam') return false;
      content.value = {
        id,
        isConfirmedEditing,
        type: 'exam',
        body: res.body.body,
        version: 0,
        dataVersion: res.body.dataVersion,
      };
    }
    return true;
  }

  async function fetch(target: Target) {
    loading.value = true;
    let ret: boolean | ErrorMessage[];
    switch (target.type) {
      case 'text':
        ret = await fetchWorkbook(target.id, target.isConfirmedEditing);
        break;
      case 'exam':
        ret = await fetchExam(target.id, target.isConfirmedEditing);
        break;
      default:
        ret = false;
    }
    loading.value = false;
    if (Array.isArray(ret)) error(ret);
  }

  async function open(target: Target) {
    dialog.value = { display: true };
    await fetch(target);
    if (!content.value) {
      info(msgs.of('noData').value, close);
      return;
    }
    problemController.init();
    indexes.value = problemController.problems.value?.map((item) => item.index) ?? [];
  }

  const changeConfirmedWorkbook = useChangeEditingConfirmedContentWorkbookProblems();
  const changeEditingWorkbook = useCreateOrUpdateEditingCourseContentWorkbook();
  async function updateWorkbook() {
    assertIsDefined(content.value, 'content');
    if (content.value.type !== 'workbook') return false;
    const { id, workbook, dataVersion: expectedDataVersion, isConfirmedEditing } = content.value;
    const copied = [...indexes.value];
    const problems = copied
      .map((index, i) => {
        const p = workbook.problems.find((item) => item.index === index);
        if (!p) return [];
        return [{ ...p, index: i }];
      })
      .flat();
    if (isConfirmedEditing) {
      const dv = expectedDataVersion ?? 0;
      const res = await changeConfirmedWorkbook.execute({ id, problems, expectedDataVersion: dv });
      if (isFailed(res)) return res.errors;
      content.value = { ...content.value, dataVersion: res.editingConfirmedContent.dataVersion };
    } else {
      const res = await changeEditingWorkbook.execute({ id, problems, expectedDataVersion });
      if (isFailed(res)) return res.errors;
      content.value = { ...content.value, dataVersion: res.workbook.dataVersion };
    }
    savedIndexes.value = copied;
    return true;
  }

  const changeConfirmedExam = useChangeEditingConfirmedExamProblems();
  const changeEditingExam = useUpdateEditingCourseContentBody();
  async function updateExam() {
    assertIsDefined(content.value, 'content');
    if (content.value.type !== 'exam') return false;
    const { id, body, dataVersion: expectedDataVersion, isConfirmedEditing } = content.value;
    const copied = [...indexes.value];
    const problems = copied
      .map((index, i) => {
        const p = body.problems.find((item) => item.index === index);
        if (!p) return [];
        return [{ ...p, index: i }];
      })
      .flat();
    if (isConfirmedEditing) {
      const res = await changeConfirmedExam.execute({ id, problems, expectedDataVersion });
      if (isFailed(res)) return res.errors;
      content.value = { ...content.value, dataVersion: res.editingConfirmedContent.dataVersion };
    } else {
      const res = await changeEditingExam.execute({
        id,
        body: { ...body, problems },
        expectedDataVersion,
      });
      if (isFailed(res)) return res.errors;
      content.value = { ...content.value, dataVersion: res.body.dataVersion };
    }
    savedIndexes.value = copied;
    return true;
  }

  async function update() {
    assertIsDefined(content.value, 'content');
    dialog.value = { ...dialog.value, status: 'updating' };
    const { type } = content.value;
    switch (type) {
      case 'workbook': {
        const ret = await updateWorkbook();
        if (ret === true) {
          dialog.value = { ...dialog.value, status: 'updated' };
        } else {
          dialog.value = { ...dialog.value, status: 'changed' };
          if (Array.isArray(ret)) error(ret);
        }
        break;
      }
      case 'exam': {
        const ret = await updateExam();
        if (ret === true) {
          dialog.value = { ...dialog.value, status: 'updated' };
        } else {
          dialog.value = { ...dialog.value, status: 'changed' };
          if (Array.isArray(ret)) error(ret);
        }
        break;
      }
      default:
    }
  }

  function change() {
    dialog.value = { ...dialog.value, status: 'updating' };
    useTimeoutFn(update, 3000);
  }

  const subtitle = computed(() => {
    if (!content.value) return undefined;
    const { type } = content.value;
    const key = type === 'workbook' ? 'sortWorkbookProblems' : 'sortExamProblems';
    return msgs.of(key).value;
  });

  const items = computed(() =>
    indexes.value
      .map((index) => {
        const p = problemController.problems.value?.find((item) => item.index === index);
        if (!p) return [];
        const savedIndex = savedIndexes.value?.findIndex((i) => i === index);
        if (savedIndex === undefined) return [p];
        return [{ ...p, no: savedIndex + 1 }];
      })
      .flat()
  );

  return {
    dialog,
    dialogFullScreen,
    itemDialog,
    loading,
    indexes,
    items,
    subtitle,
    title: msgs.of('editContent'),
    labelNoData: msgs.of('noData'),
    close,
    open,
    openItem,
    opened,
    change,
    ...useAnchorDialog(),
  };
}

export type ProblemListDialog = ReturnType<typeof useProblemListDialog>;
