import { computed, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { BaseDialogOk } from '@/base/app/components/molecules/BaseDialogOkComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { Exam } from '@/base/domains';
import { isFailed } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';

import {
  useChangeConfirmedExamPassingStandard,
  useChangeEditingCourseExamPassingStandard,
  useGetEditingConfirmedContent,
  useGetEditingCourseContentBody,
} from '../../../usecases';
import { ExamPassingStandardForm } from '../molecules/ExamPassingStandardFormComposable';

type Target = {
  contentId: string;
  isConfirmedEditing: boolean;
};

function useErrorDialog() {
  const errorDialog = ref<BaseDialogOk>();
  function error(errors: ErrorMessage[], ok: () => void) {
    assertIsDefined(errorDialog.value);
    errorDialog.value.error(errors, undefined, ok);
  }
  return { errorDialog, error };
}

export type ExamPassingStandardDialogRefreshPayload = {
  item: 'exam';
};

export function useExamPassingStandardDialog(
  emit: (name: string, args: ExamPassingStandardDialogRefreshPayload) => void
) {
  const msgs = useMessages({ prefix: 'contents.organisms.examPassingStandardDialog' });

  const dialog = ref(false);
  const form = ref<ExamPassingStandardForm>();
  const { errorDialog, error } = useErrorDialog();

  const target = ref<Target>();
  const exam = ref<{ body: Exam; dataVersion: number }>();
  const input = ref<number>();
  const errors = ref<ErrorMessage[]>();
  const loading = ref(false);

  function close() {
    dialog.value = false;
    target.value = undefined;
    exam.value = undefined;
    if (form.value) form.value.reset();
  }
  watch(dialog, (newVal) => {
    if (newVal) return;
    close();
  });

  const getEditingExam = useGetEditingCourseContentBody();
  async function fetchEditing() {
    assertIsDefined(target.value, 'target');
    const { contentId } = target.value;
    const res = await getEditingExam.execute({ contentId });
    if (isFailed(res)) {
      exam.value = undefined;
      return res.errors;
    }
    if (res.body.type !== 'exam') {
      exam.value = undefined;
      return [msgs.of('noData').value];
    }
    exam.value = { body: res.body.body, dataVersion: res.body.dataVersion };
    return true;
  }

  const getConfirmedExam = useGetEditingConfirmedContent();
  async function fetchConfirmed() {
    assertIsDefined(target.value, 'target');
    const { contentId } = target.value;
    const res = await getConfirmedExam.execute({ id: contentId });
    if (isFailed(res)) {
      exam.value = undefined;
      return res.errors;
    }
    if (!res.editingConfirmedContent || res.editingConfirmedContent?.type !== 'exam') {
      exam.value = undefined;
      return [msgs.of('noData').value];
    }
    exam.value = {
      body: res.editingConfirmedContent.body,
      dataVersion: res.editingConfirmedContent.dataVersion,
    };
    return true;
  }

  async function open(payload: Target) {
    dialog.value = true;
    target.value = payload;
    loading.value = true;
    if (target.value.isConfirmedEditing) {
      const ret = await fetchConfirmed();
      if (ret !== true) {
        error(ret, close);
        loading.value = false;
        return;
      }
    } else {
      const ret = await fetchEditing();
      if (ret !== true) {
        error(ret, close);
        loading.value = false;
        return;
      }
    }
    input.value = exam.value?.body.passingStandard;
    loading.value = false;
  }

  const changeEditingExam = useChangeEditingCourseExamPassingStandard();
  async function updateEditingExam(passingStandard?: number) {
    assertIsDefined(target.value, 'target');
    assertIsDefined(exam.value, 'exam');
    const { contentId } = target.value;
    const { dataVersion } = exam.value;
    const res = await changeEditingExam.execute({
      id: contentId,
      expectedDataVersion: dataVersion,
      passingStandard,
    });
    if (isFailed(res)) return res.errors;
    return true;
  }

  const changeConfirmedExam = useChangeConfirmedExamPassingStandard();
  async function updateConfirmedExam(passingStandard?: number) {
    assertIsDefined(target.value, 'target');
    assertIsDefined(exam.value, 'exam');
    const { contentId } = target.value;
    const { dataVersion } = exam.value;
    const res = await changeConfirmedExam.execute({
      id: contentId,
      expectedDataVersion: dataVersion,
      passingStandard,
    });
    if (isFailed(res)) return res.errors;
    return true;
  }

  async function submit() {
    assertIsDefined(target.value, 'target');
    errors.value = undefined;
    loading.value = true;
    if (target.value.isConfirmedEditing) {
      const ret = await updateConfirmedExam(input.value);
      if (ret !== true) {
        errors.value = ret;
        loading.value = false;
        return;
      }
    } else {
      const ret = await updateEditingExam(input.value);
      if (ret !== true) {
        errors.value = ret;
        loading.value = false;
        return;
      }
    }
    loading.value = false;
    close();
    emit('refresh', { item: 'exam' });
  }

  const max = computed(() => {
    if (!exam.value) return undefined;
    const count = exam.value.body.problems.length;
    if (count === 0) return undefined;
    return count;
  });

  return {
    dialog,
    form,
    errorDialog,
    input,
    errors,
    loading,
    max,
    labelClose: msgs.of('close'),
    labelSubmit: msgs.of('update'),
    close,
    open,
    submit,
  };
}

export type ExamPassingStandardDialog = ReturnType<typeof useExamPassingStandardDialog>;
