import { onMounted, ref, watch } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';

export type CourseNameFormValue = {
  name: string;
};

export type PropsCourseNameForm = {
  value: CourseNameFormValue;
};

export function useCourseNameForm(
  props: PropsCourseNameForm,
  emit: (name: string, arg: CourseNameFormValue) => void
) {
  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const input = ref<CourseNameFormValue>({ name: '' });

  function change(value?: string) {
    Object.assign(input.value, value);
  }
  async function submit() {
    const valid = await observer.value?.validate();
    if (!valid) return;
    emit('submit', { ...input.value });
  }

  function init() {
    input.value = { ...props.value };
  }
  onMounted(init);
  watch(() => props.value, init);

  function reset() {
    if (observer.value) observer.value.reset();
  }
  const msgs = useMessages({ prefix: 'contents.molecules.courseNameForm' });
  return { observer, input, labelName: msgs.of('name'), change, submit, reset };
}

export type CourseNameForm = ReturnType<typeof useCourseNameForm>;
