import { onMounted, ref, watch } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';
import { ContentType } from '@/base/domains';

type InputValue = {
  name: string;
  type: ContentType;
  requiredTime: string;
};

export type ContentNameFormValue = {
  name: string;
  type: ContentType;
  requiredTime: number;
};

export type PropsContentNameForm = {
  value: ContentNameFormValue;
};

export function useContentNameForm(
  props: PropsContentNameForm,
  emit: (name: string, arg: ContentNameFormValue) => void
) {
  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const input = ref<InputValue>({ name: '', type: 'text', requiredTime: '15' });

  async function submit() {
    const valid = await observer.value?.validate();
    if (!valid) return;
    const requiredTime = parseInt(input.value.requiredTime, 10);
    emit('submit', { name: input.value.name, type: input.value.type, requiredTime });
  }

  function init() {
    input.value = {
      name: props.value.name,
      type: props.value.type,
      requiredTime: props.value.requiredTime.toString(10),
    };
  }
  onMounted(init);
  watch(() => props.value, init);

  function reset() {
    if (observer.value) observer.value.reset();
  }

  const msgs = useMessages({ prefix: 'contents.molecules.contentNameForm' });
  return {
    observer,
    input,
    contentTypes: msgs.listOf('contentTypes'),
    labelContentsType: msgs.of('contentsType'),
    labelContentsName: msgs.of('contentsName'),
    labelRequiredTime: msgs.of('requiredTime'),
    suffixRequiredTime: msgs.of('minute'),
    submit,
    reset,
  };
}

export type ContentNameForm = ReturnType<typeof useContentNameForm>;
