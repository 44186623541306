import { computed, ref } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';

import {
  BaseTable,
  BaseTableActionPayload,
} from '@/base/app/components/molecules/BaseTableComposable';
import { useMessages } from '@/base/app/Messages';

import { CourseStatus } from '../../../domains';

const HEADERS: DataTableHeader[] = [
  { value: 'name', text: '', class: 'base-table-name' },
  { value: 'statusLabel', text: '', width: 200 },
  { value: 'hasEditingContentLabel', text: '', width: 200 },
];

function useTable() {
  const table = ref<BaseTable>();
  function clear() {
    if (table.value) table.value.clearSelection();
  }
  return { table, clear };
}

export type CourseTableItem = {
  name: string;
  id: string;
  status: CourseStatus;
  hasEditingContent: boolean;
};

export type CourseTableActionPayload = {
  event: string;
  selected: CourseTableItem[];
};

export type PropsCourseTable = {
  courses: CourseTableItem[];
  headerKeys: string[];
};

export function useCourseTable(
  props: PropsCourseTable,
  emit: (name: string, args: CourseTableActionPayload) => void
) {
  const msgs = useMessages({ prefix: 'contents.molecules.courseTable' });
  const headers = computed(() =>
    props.headerKeys
      .map((value) => HEADERS.find((h) => h.value === value))
      .filter((h) => !!h)
      .map((h) => ({ ...h, text: h ? msgs.of(h.value).value : '' }))
  );

  const items = computed(() => {
    const labelCreating = msgs.of('creating').value;
    const labelFixed = msgs.of('fixed').value;
    const labelEditing = msgs.of('hasEditingContent').value;
    return props.courses.map((item) => ({
      ...item,
      statusLabel: item.status === 'editing' ? labelCreating : labelFixed,
      hasEditingContentLabel: item.hasEditingContent ? labelEditing : undefined,
    }));
  });

  function action(payload: BaseTableActionPayload) {
    const selected = payload.selected
      .map((id) => props.courses.find((c) => c.id === id))
      .filter((c) => c) as CourseTableItem[];
    emit('action', { ...payload, selected });
  }

  return { headers, items, action, ...useTable() };
}

export type CourseTable = ReturnType<typeof useCourseTable>;
