






































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseRadioGroup from '@/base/app/components/atoms/BaseRadioGroup.vue';
import BaseText from '@/base/app/components/atoms/BaseText.vue';

import {
  ContentNameFormValue,
  PropsContentNameForm,
  useContentNameForm,
} from './ContentNameFormComposable';

type Props = PropsContentNameForm;

export default defineComponent({
  name: 'ContentsContentNameForm',
  components: { BaseText, BaseRadioGroup },
  model: { prop: 'value', event: 'submit' },
  props: { value: { type: Object as PropType<ContentNameFormValue>, required: true } },
  emits: ['submit'],
  setup(props: Props, { emit }: SetupContext) {
    return useContentNameForm(props, emit);
  },
});
