import { computed, ref } from '@vue/composition-api';
import { useWindowSize } from '@vueuse/core';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';
import {
  BaseDialogFullScreen,
  BaseDialogFullScreenValue,
} from '@/base/app/components/molecules/BaseDialogFullScreenComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { TextEditorValue } from '@/base/app/components/molecules/TextEditorComposable';
import { clearDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { Text } from '@/base/domains';
import { isSucceeded } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { useRoute, useRouter } from '@/utils/VueUtils';

import {
  ChangeEditingConfirmedContentBodyRequest,
  UpdateEditingCourseContentBodyRequest,
  useChangeEditingConfirmedContentBody,
  useGetEditingConfirmedContent,
  useGetEditingCourseContentBody,
  useUpdateEditingCourseContentBody,
} from '../../../usecases';

type Form = {
  body: TextEditorValue;
  dataVersion: number;
};

type TextBodyDialogContent = {
  id: string;
};

function useDialogFullScreen() {
  const dialogFullScreen = ref<BaseDialogFullScreen>();
  const dialog = ref<BaseDialogFullScreenValue>({ display: false });
  function opened() {
    return dialog.value.display;
  }
  function error(errors: ErrorMessage[]) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showErrorDialog(errors);
  }
  function info(msg: string, ok?: () => void) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showDialog(msg, ok);
  }
  return { dialogFullScreen, dialog, opened, error, info };
}

export type TextBodyDialogRefreshPayload = {
  item: 'text';
};

export type PropsTextBodyDialog = {
  isConfirmedEditing: boolean;
};

export function useTextBodyDialog(
  props: PropsTextBodyDialog,
  emit: (name: string, arg: TextBodyDialogRefreshPayload) => void
) {
  const msgs = useMessages({ prefix: 'contents.organisms.textBodyDialog' });
  const route = useRoute();
  const router = useRouter();
  const { dialogFullScreen, dialog, opened, error, info } = useDialogFullScreen();

  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const content = ref<TextBodyDialogContent>();
  const loading = ref(false);
  const input = ref<Form>({ body: '', dataVersion: 0 });

  const getConfirmedBody = useGetEditingConfirmedContent();
  async function fetchConfirmedContent(contentId: string) {
    const res = await getConfirmedBody.execute({ id: contentId });
    if (isSucceeded(res) && res.editingConfirmedContent?.type === 'text') {
      const text = res.editingConfirmedContent.body as Text;
      return {
        id: res.editingConfirmedContent.id,
        body: text.body,
        dataVersion: res.editingConfirmedContent.dataVersion,
      };
    }
    return undefined;
  }

  const getBody = useGetEditingCourseContentBody();
  async function fetchContent(contentId: string) {
    const res = await getBody.execute({ contentId });
    if (isSucceeded(res) && res.body.type === 'text') {
      const text = res.body.body as Text;
      return { id: res.body.id, body: text.body, dataVersion: res.body.dataVersion };
    }
    return undefined;
  }

  async function fetch(contentId: string) {
    loading.value = true;
    let ret: (TextBodyDialogContent & Form) | undefined;
    if (props.isConfirmedEditing) {
      ret = await fetchConfirmedContent(contentId);
    } else {
      ret = await fetchContent(contentId);
    }
    if (ret) {
      content.value = { id: ret.id };
      input.value = { body: ret.body, dataVersion: ret.dataVersion };
    } else {
      content.value = undefined;
      input.value = { body: '', dataVersion: 0 };
    }
    loading.value = false;
  }

  function close() {
    dialog.value = { display: false };
    content.value = undefined;
    input.value = { body: '', dataVersion: 0 };
    const to = clearDialogQuery(route);
    if (to) router.replace(to);
  }

  async function open(payload: { id: string }) {
    dialog.value = { display: true };
    await fetch(payload.id);
    if (!content.value) info(msgs.of('noData').value, close);
  }

  const changeConfirmedBody = useChangeEditingConfirmedContentBody();
  async function updateConfirmedEditing(req: ChangeEditingConfirmedContentBodyRequest) {
    const res = await changeConfirmedBody.execute(req);
    if (isSucceeded(res)) {
      return { dataVersion: res.editingConfirmedContent.dataVersion };
    }
    return { errors: res.errors };
  }

  const changeBody = useUpdateEditingCourseContentBody();
  async function updateEditingCourse(req: UpdateEditingCourseContentBodyRequest) {
    const res = await changeBody.execute(req);
    if (isSucceeded(res)) {
      return { dataVersion: res.body.dataVersion };
    }
    return { errors: res.errors };
  }

  async function change() {
    dialog.value = { ...dialog.value, status: 'changed' };
    assertIsDefined(observer.value);
    const valid = await observer.value.validate();
    if (!valid) return;

    assertIsDefined(content.value, 'content');
    const { id } = content.value;
    const expectedDataVersion = input.value.dataVersion;

    let ret: { dataVersion: number } | { errors: ErrorMessage[] };
    if (props.isConfirmedEditing) {
      ret = await updateConfirmedEditing({
        id,
        body: { body: input.value.body ?? '' },
        expectedDataVersion,
      });
    } else {
      ret = await updateEditingCourse({
        id,
        body: { body: input.value.body ?? '' },
        expectedDataVersion,
      });
    }
    if ('errors' in ret) {
      dialog.value = { ...dialog.value, status: 'changed' };
      error(ret.errors);
      return;
    }

    dialog.value = { ...dialog.value, status: 'updated' };
    input.value = { ...input.value, dataVersion: ret.dataVersion };
    emit('refresh', { item: 'text' });
  }

  const notFound = computed(() => !content.value);

  const windowSize = useWindowSize();
  const textEditorSize = computed(() => {
    // iOS/Safariでは100vhにアドレスバーの長さが含まれてしまうので
    // windowサイズ（innerHeight）から高さを算出する。
    return {
      height: `max(calc(${windowSize.height.value}px - 220px), 6rem)`,
      maxHeight: `max(calc(${windowSize.height.value}px - 220px), 6rem)`,
    };
  });

  return {
    dialogFullScreen,
    dialog,
    observer,
    loading,
    notFound,
    input,
    title: msgs.of('editContent'),
    subtitle: msgs.of('textBody'),
    textEditorSize,
    close,
    open,
    opened,
    change,
  };
}

export type TextBodyDialog = ReturnType<typeof useTextBodyDialog>;
