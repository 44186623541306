import { nextTick, ref } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { TextEditorValue } from '@/base/app/components/molecules/TextEditorComposable';

export type ProblemHeaderFormValue = {
  body: TextEditorValue;
  id?: string;
};

export type ProblemHeaderDialogPayload = {
  value: ProblemHeaderFormValue;
  done: (errors?: ErrorMessage[]) => void;
};

export function useProblemHeaderDialog(
  emit: (name: string, arg: ProblemHeaderDialogPayload) => void
) {
  const dialog = ref(false);
  const loading = ref(false);
  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const input = ref<ProblemHeaderFormValue>({ body: '' });
  const errors = ref<ErrorMessage[]>();

  function close() {
    dialog.value = false;
    input.value = { body: '' };
    errors.value = undefined;
  }

  function open(payload?: { id: string; body: string }) {
    input.value = { body: '', ...payload };
    dialog.value = true;
    nextTick(() => {
      if (observer.value) observer.value.reset();
    });
  }

  function change(payload: object) {
    Object.assign(input.value, payload);
  }

  function done(failed?: ErrorMessage[]) {
    loading.value = false;
    errors.value = failed;
    if (failed) return;
    close();
  }

  async function submit() {
    const valid = await observer.value?.validate();
    if (!valid) return;
    loading.value = true;
    emit('submit', { value: { ...input.value }, done });
  }
  const msgs = useMessages({ prefix: 'contents.molecules.problemHeaderDialog' });
  return {
    dialog,
    loading,
    observer,
    input,
    errors,
    labelProblemHeader: msgs.of('problemHeader'),
    labelUpdate: msgs.of('update'),
    labelAdd: msgs.of('add'),
    labelClose: msgs.of('close'),
    close,
    open,
    change,
    submit,
  };
}

export type ProblemHeaderDialog = ReturnType<typeof useProblemHeaderDialog>;
