import { ref } from '@vue/composition-api';

import { isSucceeded } from '@/base/usecases';
import { injectionKeyOf, readonly } from '@/utils/VueUtils';

import { GetCoursesForEditingCourse, useGetCoursesForEditing } from '../../usecases';

export function useCoursesStore() {
  const courses = ref<GetCoursesForEditingCourse[]>([]);
  const loading = ref(false);

  const getCourses = useGetCoursesForEditing();
  async function fetch() {
    loading.value = true;
    const res = await getCourses.execute({});
    if (isSucceeded(res)) courses.value = res.courses;
    loading.value = false;
  }

  return { courses: readonly(courses), loading, fetch };
}

export type CoursesStore = ReturnType<typeof useCoursesStore>;

export const CoursesStoreKey = injectionKeyOf<CoursesStore>({
  boundedContext: 'contents',
  type: 'store',
  name: 'CoursesStore',
});
