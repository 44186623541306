





























import { computed, defineComponent, provide } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import MyApp from '@/base/app/components/MyApp.vue';

import Course from '../components/organisms/Course.vue';
import { CourseStoreKey, useCourseStore } from '../stores';

type Props = {
  edit: boolean;
};

export default defineComponent({
  name: 'ContentsCoursePage',
  components: { MyApp, Course },
  props: {
    id: { type: String, required: true },
    edit: { type: Boolean, default: false },
  },
  setup(props: Props) {
    const store = useCourseStore();
    provide(CourseStoreKey, store);

    const msgs = useMessages({ prefix: 'contents.pages.coursePage' });
    const label = computed(() => {
      if (!store.course.value) return undefined;
      const key = store.course.value.status === 'editing' ? 'courseNameEditing' : 'courseNameFixed';
      return msgs.of(key, { name: store.course.value.name || '' }).value;
    });
    const icon = computed(() => (props.edit ? 'mdi-pencil' : 'mdi-pencil-off'));
    const iconDescription = computed(() => msgs.of(props.edit ? 'canEdit' : 'cannotEdit').value);

    return { label, icon, iconDescription, loading: store.loading, notFound: store.notFound };
  },
});
