






























































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseButtonIcon from '@/base/app/components/atoms/BaseButtonIcon.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import ContentFooter from '@/base/app/components/molecules/ContentFooter.vue';
import ContentHeader from '@/base/app/components/molecules/ContentHeader.vue';

import { GetCourseContentDetailsContent } from '../../../usecases';
import ContentHeaderDialog from './ContentHeaderDialog.vue';
import { PropsContentText, useContentText } from './ContentTextComposable';
import TextBodyDialog from './TextBodyDialog.vue';

type Props = PropsContentText;

export default defineComponent({
  name: 'ContentsContentText',
  components: {
    ContentHeader,
    BaseMarkdown,
    ContentFooter,
    BaseButtonIcon,
    ContentHeaderDialog,
    TextBodyDialog,
  },
  props: {
    content: { type: Object as PropType<GetCourseContentDetailsContent>, required: true },
    isConfirmedEditing: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  emits: ['click-anchor', 'refresh'],
  setup(props: Props, { emit }: SetupContext) {
    return useContentText(props, emit);
  },
});
