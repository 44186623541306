

































import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseDialogFullScreen from '@/base/app/components/molecules/BaseDialogFullScreen.vue';
import TextEditor from '@/base/app/components/molecules/TextEditor.vue';
import Loading from '@/base/app/components/organisms/Loading.vue';

import { PropsTextBodyDialog, useTextBodyDialog } from './TextBodyDialogComposable';

type Props = PropsTextBodyDialog;

export default defineComponent({
  name: 'ContentsTextBodyDialog',
  components: { BaseDialogFullScreen, TextEditor, Loading },
  props: { isConfirmedEditing: { type: Boolean, default: false } },
  emits: ['refresh'],
  setup(props: Props, { emit }: SetupContext) {
    return useTextBodyDialog(props, emit);
  },
});
