import { computed, nextTick, onMounted, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import {
  WindowControlItem,
  WindowControlValue,
} from '@/base/app/components/molecules/WindowControlComposable';
import { DialogAnchorConfirm } from '@/base/app/components/organisms/DialogAnchorConfirmComposable';
import { DialogName, DialogQuery, useDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import {
  delayScroll,
  FuncSaveScrollPositionPageChanged,
  useSaveScrollPosition,
} from '@/base/app/utils/DomUtils';
import { ProblemUtilsNavigatorValue, useProblemController } from '@/base/app/utils/ProblemUtils';
import { Optional } from '@/base/types';
import { assertIsDefined } from '@/utils/Asserts';
import { requiredInject } from '@/utils/VueUtils';

import { ContentStoreKey } from '../../stores';
import {
  ContentExamChoicePayload,
  ContentExamClickAnchorPayload,
  ContentExamRefreshPayload,
  ContentExamToggleFlagPayload,
} from './ContentExamComposable';
import { ContentTextClickAnchorPayload, ContentTextRefreshPayload } from './ContentTextComposable';
import {
  ContentWorkbookCheckPayload,
  ContentWorkbookChoicePayload,
  ContentWorkbookClearPayload,
  ContentWorkbookClickAnchorPayload,
  ContentWorkbookRefreshPayload,
} from './ContentWorkbookComposable';

function useAnchorDialog() {
  const anchorDialog = ref<DialogAnchorConfirm>();
  function clickAnchor(
    payload:
      | ContentTextClickAnchorPayload
      | ContentWorkbookClickAnchorPayload
      | ContentExamClickAnchorPayload
  ) {
    assertIsDefined(anchorDialog.value);
    anchorDialog.value.confirm(payload.event);
  }
  return { anchorDialog, clickAnchor };
}

export type PropsContentContainer = {
  id: string;
  v: string;
  readonly: boolean;
};

export function useContentContainer(props: PropsContentContainer) {
  const msgs = useMessages({ prefix: 'contents.organisms.contentContainer' });
  const { getQuery: getWorkbookProblemQuery } = useDialogQuery(
    DialogName.CONTENTS_WORKBOOK_PROBLEM
  );
  const { getQuery: getExamBodyQuery } = useDialogQuery(DialogName.CONTENTS_EXAM_BODY);

  const {
    content,
    editable,
    isConfirmedEditing,
    isWorkbookOnly,
    fetch: fetchContent,
  } = requiredInject(ContentStoreKey);

  const windowValue = ref<WindowControlValue>('');
  const windowItems = ref<WindowControlItem[]>([]);

  let funcChangedWindowValue: Optional<FuncSaveScrollPositionPageChanged>;
  function onChangedWindowValue(func: FuncSaveScrollPositionPageChanged) {
    funcChangedWindowValue = func;
  }

  function changeWindowValue(v: WindowControlValue) {
    const o = windowValue.value;
    windowValue.value = v;
    if (o !== v && funcChangedWindowValue) funcChangedWindowValue(v, o);
  }

  const scrollController = useSaveScrollPosition(
    {},
    { className: 'contents-content-container', saveScrollPosition: true },
    onChangedWindowValue
  );

  const problemController = useProblemController({
    content: computed(() => {
      if (!content.value) return undefined;
      const { type } = content.value;
      if (
        type === 'text' &&
        'workbook' in content.value &&
        content.value.workbook &&
        content.value.workbook.problems.length > 0
      ) {
        return { type: 'workbook' as const, workbook: content.value.workbook };
      }
      if (type === 'exam') {
        return { type: 'exam' as const, body: content.value.body };
      }
      return undefined;
    }),
  });

  function changeProblemIndex(v: ProblemUtilsNavigatorValue) {
    problemController.move(v);
    nextTick(() => delayScroll('top'));
  }

  function clearProblemValue(payload: ContentWorkbookClearPayload) {
    problemController.clear(payload.index);
  }

  function checkProblemValue(payload: ContentWorkbookCheckPayload) {
    problemController.score(payload.index);
  }

  function choiceProblemValue(payload: ContentWorkbookChoicePayload | ContentExamChoicePayload) {
    problemController.choice(payload.index, payload.values);
  }

  function toggleProblemFlag(payload: ContentExamToggleFlagPayload) {
    problemController.toggleFlag(payload.index);
  }

  function fetched() {
    windowValue.value = '';
    windowItems.value.splice(0);

    const type = content.value?.type;
    if (!type) return;

    switch (type) {
      case 'exam': {
        problemController.init({ enableRaiseFlag: props.readonly });
        windowItems.value = [{ label: msgs.of('exam').value, value: 'exam' }];
        break;
      }
      case 'text': {
        problemController.init({ enablePassed: props.readonly });
        if (props.readonly && isWorkbookOnly.value) {
          windowItems.value = [{ label: msgs.of('workbook').value, value: 'workbook' }];
          return;
        }
        windowItems.value = [
          { label: msgs.of('text').value, value: 'text' },
          { label: msgs.of('workbook').value, value: 'workbook' },
        ];
        break;
      }
      default:
    }
  }

  function getQueryIndex(q: DialogQuery) {
    if (q.params && 'index' in q.params && Number.isFinite(parseInt(q.params.index, 10))) {
      return parseInt(q.params.index, 10);
    }
    return undefined;
  }

  async function fetch(
    payload?: ContentTextRefreshPayload | ContentWorkbookRefreshPayload | ContentExamRefreshPayload
  ) {
    scrollController.clearScrollPositions();
    await fetchContent(props.id, props.v);
    fetched();

    if (payload) {
      changeWindowValue(payload.item);
      if ('clear' in payload && payload.clear === true) problemController.init();
      if ('index' in payload && payload.index !== undefined) changeProblemIndex(payload.index);
      return;
    }

    const [first] = windowItems.value;
    if (first) windowValue.value = first.value;

    if (content.value?.type === 'text') {
      const q = getWorkbookProblemQuery();
      if (q && windowItems.value.some((item) => item.value === 'workbook')) {
        const i = getQueryIndex(q);
        nextTick(() => {
          windowValue.value = 'workbook';
          if (i !== undefined) changeProblemIndex(i);
        });
      }
    } else if (content.value?.type === 'exam') {
      const q = getExamBodyQuery();
      if (q) {
        const i = getQueryIndex(q);
        nextTick(() => {
          if (i !== undefined) changeProblemIndex(i);
        });
      }
    }
  }
  onMounted(fetch);
  watch(
    () => props.id,
    () => fetch()
  );

  const disabled = computed(() => props.readonly || !editable.value);

  return {
    content,
    windowValue,
    windowItems,
    disabled,
    isConfirmedEditing,
    problemIndex: problemController.value,
    problems: problemController.problems,
    problemNavigator: problemController.navigator,
    changeWindowValue,
    changeProblemIndex,
    clearProblemValue,
    checkProblemValue,
    choiceProblemValue,
    toggleProblemFlag,
    refresh: fetch,
    ...useAnchorDialog(),
  };
}
