






























































































































import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseButtonIcon from '@/base/app/components/atoms/BaseButtonIcon.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import BaseRadioGroup from '@/base/app/components/atoms/BaseRadioGroup.vue';
import BaseDialogConfirm from '@/base/app/components/molecules/BaseDialogConfirm.vue';
import BaseDialogFullScreen from '@/base/app/components/molecules/BaseDialogFullScreen.vue';
import TextEditor from '@/base/app/components/molecules/TextEditor.vue';
import Loading from '@/base/app/components/organisms/Loading.vue';

import ProblemHeaderDialog from '../molecules/ProblemHeaderDialog.vue';
import ProblemHeadersSelector from '../molecules/ProblemHeadersSelector.vue';
import { useProblemDialog } from './ProblemDialogComposable';

export default defineComponent({
  name: 'ContentsProblemDialog',
  components: {
    BaseDialogFullScreen,
    BaseButton,
    BaseButtonIcon,
    BaseRadioGroup,
    TextEditor,
    Loading,
    BaseMarkdown,
    ProblemHeadersSelector,
    ProblemHeaderDialog,
    BaseDialogConfirm,
  },
  emits: ['refresh'],
  setup(_, { emit }: SetupContext) {
    return useProblemDialog(emit);
  },
});
