import { computed } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { ContentType } from '@/base/domains';

export type PropsContentItem = {
  content: ContentItemContent;
};

export type ContentItemContent = {
  id: string;
  type: ContentType;
  name: string;
  requiredTime: number;
  index: number;
};

export function useContentItem(props: PropsContentItem) {
  const msgs = useMessages({ prefix: 'contents.molecules.contentItem' });
  const labelRequiredTime = computed(
    () => msgs.of('requiredTime', { min: props.content.requiredTime }).value
  );
  const labelType = computed(() => msgs.of(props.content.type).value);

  return { labelRequiredTime, labelType };
}
