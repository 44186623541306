































import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import ErrorMessages from '@/base/app/components/molecules/ErrorMessages.vue';

import { useContentVersionEditor } from './ContentVersionEditorComposable';
import ContentVersionForm from './ContentVersionForm.vue';

export default defineComponent({
  name: 'ContentsContentVersionEditor',
  components: { BaseButton, ContentVersionForm, ErrorMessages },
  inheritAttrs: false,
  props: {
    disabled: { type: Boolean, default: false },
  },
  emits: ['submit'],
  setup(_, { emit }: SetupContext) {
    return useContentVersionEditor(emit);
  },
});
