




















































import { defineComponent } from '@vue/composition-api';

import RefreshButton from '@/base/app/components/atoms/RefreshButton.vue';
import BaseDialogConfirm from '@/base/app/components/molecules/BaseDialogConfirm.vue';
import BaseDialogOk from '@/base/app/components/molecules/BaseDialogOk.vue';
import DialogAnchorConfirm from '@/base/app/components/organisms/DialogAnchorConfirm.vue';

import CourseTable from '../molecules/CourseTable.vue';
import CourseNameDialog from './CourseNameDialog.vue';
import { useCourses } from './CoursesComposable';

export default defineComponent({
  name: 'ContentsCourses',
  components: {
    CourseTable,
    RefreshButton,
    CourseNameDialog,
    BaseDialogConfirm,
    BaseDialogOk,
    DialogAnchorConfirm,
  },
  setup() {
    return useCourses();
  },
});
