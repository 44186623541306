





















































































































import { defineComponent } from '@vue/composition-api';

import BaseButtonIcon from '@/base/app/components/atoms/BaseButtonIcon.vue';
import RefreshButton from '@/base/app/components/atoms/RefreshButton.vue';
import BaseDialogConfirm from '@/base/app/components/molecules/BaseDialogConfirm.vue';
import BaseDialogOk from '@/base/app/components/molecules/BaseDialogOk.vue';
import ContentTable from '@/base/app/components/molecules/ContentTable.vue';
import WindowControl from '@/base/app/components/molecules/WindowControl.vue';
import ContentListDialog from '@/contents/app/components/organisms/ContentListDialog.vue';

import CourseDetails from '../molecules/CourseDetails.vue';
import GroupTable from '../molecules/GroupTable.vue';
import ContentAddDialog from './ContentAddDialog.vue';
import ContentConfirmEditingDialog from './ContentConfirmEditingDialog.vue';
import ContentVersionsDialog from './ContentVersionsDialog.vue';
import CourseBackgroundDialog from './CourseBackgroundDialog.vue';
import { PropsCourse, useCourse } from './CourseComposable';
import CourseDescriptionDialog from './CourseDescriptionDialog.vue';
import GroupCourseContentsDialog from './GroupCourseContentsDialog.vue';

type Props = PropsCourse;

export default defineComponent({
  name: 'ContentsCourse',
  components: {
    WindowControl,
    CourseDetails,
    ContentTable,
    GroupTable,
    ContentAddDialog,
    ContentListDialog,
    ContentVersionsDialog,
    ContentConfirmEditingDialog,
    CourseBackgroundDialog,
    CourseDescriptionDialog,
    GroupCourseContentsDialog,
    RefreshButton,
    BaseDialogConfirm,
    BaseDialogOk,
    BaseButtonIcon,
  },
  props: {
    id: { type: String, required: true },
    readonly: { type: Boolean, default: true },
    side: { type: Boolean, default: false },
    sideWidth: { type: Number, default: 280 },
  },
  setup(props: Props) {
    return useCourse(props);
  },
});
