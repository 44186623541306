import { computed, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { ContentId } from '@/base/domains';
import { isFailed } from '@/base/usecases';
import { useChangeContentVersionDescription } from '@/contents/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { requiredInject } from '@/utils/VueUtils';

import { CourseStoreKey } from '../../stores';
import { ContentVersionEditorSubmitPayload } from '../molecules/ContentVersionEditorComposable';

type ContentVersionsDialogContent = {
  id: ContentId;
  disabled: boolean;
};

export type ContentVersionDialogOpenPayload = { id: string; v: string };

export function useContentVersionsDialog(
  emit: (name: string, args: void | ContentVersionDialogOpenPayload) => void
) {
  const msgs = useMessages({ prefix: 'contents.organisms.contentVersionsDialog' });

  const { course } = requiredInject(CourseStoreKey);

  const dialog = ref(false);
  const target = ref<ContentVersionsDialogContent>();
  const disabled = computed(() => target.value?.disabled);

  const index = ref();
  let updated = false;

  const content = computed(() => {
    if (!target.value || !course.value || course.value.type === 'editing_course') return undefined;
    const { id } = target.value;
    const c = course.value.contents.find((item) => item.id === id);
    if (!c) return undefined;
    return {
      id: c.id,
      name: c.name,
      versions: c.versions
        .map((item) => ({
          version: item.version,
          description: item.versionDescription,
          editing: item.status === 'editing',
          label:
            item.status === 'editing'
              ? msgs.of('versionEditing', { version: item.version }).value
              : msgs.of('version', { version: item.version }).value,
        }))
        .sort((a, b) => b.version - a.version),
    };
  });

  function close() {
    dialog.value = false;
    target.value = undefined;
    index.value = undefined;
    if (updated) emit('done');
  }
  watch(dialog, (newVal) => {
    if (newVal) return;
    close();
  });

  function open(payload: ContentVersionsDialogContent) {
    dialog.value = true;
    target.value = payload;
    updated = false;
  }

  const update = useChangeContentVersionDescription();
  async function submit(v: { version: number }, payload: ContentVersionEditorSubmitPayload) {
    assertIsDefined(target.value, 'target');
    const { id } = target.value;
    const res = await update.execute({
      id,
      version: v.version,
      versionDescription: payload.value.description || '',
    });
    if (isFailed(res)) {
      payload.done(res.errors);
      return;
    }
    payload.done();
    updated = true;
  }

  function openVersion(item: { version: number }) {
    assertIsDefined(content.value, 'content');
    const { id } = content.value;
    emit('open', { id, v: item.version.toString() });
  }

  return {
    dialog,
    content,
    disabled,
    index,
    labelClose: msgs.of('close'),
    labelNoData: msgs.of('noData'),
    close,
    open,
    submit,
    openVersion,
  };
}

export type ContentVersionsDialog = ReturnType<typeof useContentVersionsDialog>;
