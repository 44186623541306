import { computed } from '@vue/composition-api';

export type CourseBackgroundRadioValue = {
  color: string;
  checked: boolean;
};

export type PropsCourseBackgroundRadio = {
  color: string;
  checked: boolean;
  width: string | number;
};

export function useCourseBackgroundRadio(
  props: PropsCourseBackgroundRadio,
  emit: (name: string, arg: CourseBackgroundRadioValue) => void
) {
  const colorWidth = computed(() => {
    let w = props.width;
    if (typeof props.width === 'number') w = `${props.width}px`;
    return `calc(${w} - 36px)`;
  });
  function change() {
    emit('change', { color: props.color, checked: !props.checked });
  }
  return { colorWidth, change };
}
