import { computed, onMounted, ref, watch } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';
import { isDefined } from '@/utils/TsUtils';

export type ExamPassingStandardFormValue = number | undefined;

export type PropsExamPassingStandardForm = {
  value: ExamPassingStandardFormValue;
  problemCount?: number;
};

export function useExamPassingStandardForm(
  props: PropsExamPassingStandardForm,
  emit: (name: string, args: ExamPassingStandardFormValue) => void
) {
  const msgs = useMessages({ prefix: 'contents.molecules.examPassingStandardForm' });
  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const input = ref<string>();

  function init() {
    input.value = props.value?.toString() ?? undefined;
  }
  onMounted(init);
  watch(() => props.value, init);

  function toInt(v?: string) {
    if (!isDefined(v)) return undefined;
    const i = parseInt(v, 10);
    if (Number.isFinite(i)) return i;
    return undefined;
  }

  async function submit() {
    const valid = await observer.value?.validate();
    if (!valid) return;
    emit('submit', toInt(input.value));
  }

  function reset() {
    if (observer.value) observer.value.reset();
  }

  const limit = computed(() => (isDefined(props.problemCount) ? props.problemCount : 999));
  const suffix = computed(() => {
    if (isDefined(props.problemCount))
      return msgs.of('passingStandardLimit', { max: props.problemCount }).value;
    return msgs.of('passingStandardUnit').value;
  });

  return {
    observer,
    input,
    limit,
    suffix,
    label: msgs.of('passingStandard'),
    submit,
    reset,
  };
}

export type ExamPassingStandardForm = ReturnType<typeof useExamPassingStandardForm>;
