import { computed, onMounted, ref, watch } from '@vue/composition-api';
import { useTimeoutFn } from '@vueuse/core';

import { useMessages } from '@/base/app';
import { BaseDialogOk } from '@/base/app/components/molecules/BaseDialogOkComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import {
  ProblemViewChoiceValue,
  ProblemViewClickAnchorPayload,
} from '@/base/app/components/molecules/ProblemViewComposable';
import { DialogName, useDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { getElementRect } from '@/base/app/utils/DomUtils';
import {
  ProblemUtilsNavigator,
  ProblemUtilsNavigatorValue,
  ProblemUtilsProblem,
} from '@/base/app/utils/ProblemUtils';
import { waitTransition } from '@/base/app/utils/TransitionUtils';
import { useVuetify } from '@/base/app/utils/VuetifyUtils';
import { Optional } from '@/base/types';
import { isSucceeded } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { useRoute } from '@/utils/VueUtils';

import {
  GetCourseContentDetailsContent,
  useChangeEditingConfirmedContentWorkbookProblems,
  useCreateOrUpdateEditingCourseContentWorkbook,
  useGetEditingConfirmedContent,
  useGetEditingCourseContentWorkbook,
} from '../../../usecases';
import { ProblemDialog, ProblemDialogRefreshPayload } from './ProblemDialogComposable';
import { ProblemListDialog, ProblemListDialogRefreshPayload } from './ProblemListDialogComposable';

const STYLE_EMPTY = {
  class: undefined as Optional<string>,
  style: undefined as Optional<Record<string, string>>,
};
const STYLES = {
  frame: STYLE_EMPTY,
  windows: STYLE_EMPTY,
  problem: { minHeight: '75vh' as Optional<string> },
};
type Styles = typeof STYLES;

function useErrorDialog() {
  const errorDialog = ref<BaseDialogOk>();
  function error(errors: ErrorMessage[]) {
    assertIsDefined(errorDialog.value);
    errorDialog.value.error(errors);
  }
  return { errorDialog, error };
}

function useProblemDialog(props: PropsContentWorkbook) {
  const problemDialog = ref<ProblemDialog>();
  const { getQuery, moveTo } = useDialogQuery(DialogName.CONTENTS_WORKBOOK_PROBLEM);
  function moveToProblem(index?: Optional<number>) {
    if (index === undefined) moveTo();
    else moveTo({ index: `${index}` });
  }
  function watchProblemDialogQuery() {
    if (!problemDialog.value) return;
    const q = getQuery();
    if (q && q.params && 'index' in q.params) {
      if (!Number.isFinite(parseInt(q.params.index, 10))) return;
      problemDialog.value.open({
        id: props.content.id,
        isConfirmedEditing: props.isConfirmedEditing,
        index: parseInt(q.params.index, 10),
      });
    } else if (q) {
      problemDialog.value.open({
        id: props.content.id,
        isConfirmedEditing: props.isConfirmedEditing,
      });
    } else if (!q && problemDialog.value.opened()) {
      problemDialog.value.close();
    }
  }
  return { problemDialog, moveToProblem, watchProblemDialogQuery };
}

function useProblemListDialog(props: PropsContentWorkbook) {
  const problemListDialog = ref<ProblemListDialog>();
  const { getQuery, moveTo: moveToProblemList } = useDialogQuery(DialogName.CONTENTS_PROBLEM_LIST);
  function watchProblemListDialogQuery() {
    if (!problemListDialog.value) return;
    const q = getQuery();
    if (q) {
      problemListDialog.value.open({
        id: props.content.id,
        isConfirmedEditing: props.isConfirmedEditing,
        type: 'text',
      });
    } else if (!q && problemListDialog.value.opened()) {
      problemListDialog.value.close();
    }
  }
  return { problemListDialog, moveToProblemList, watchProblemListDialogQuery };
}

type ContentWorkbookIndex = number;

export type ContentWorkbookCheckPayload = {
  index: ContentWorkbookIndex;
};

export type ContentWorkbookChoicePayload = {
  index: ContentWorkbookIndex;
  values: number[];
};

export type ContentWorkbookClearPayload = {
  index: ContentWorkbookIndex;
};

export type ContentWorkbookClickAnchorPayload = ProblemViewClickAnchorPayload;

export type ContentWorkbookRefreshPayload =
  | ProblemDialogRefreshPayload
  | ProblemListDialogRefreshPayload;

export type PropsContentWorkbook = {
  index: ProblemUtilsNavigatorValue;
  content: GetCourseContentDetailsContent;
  problems: ProblemUtilsProblem[];
  problemNavigator?: ProblemUtilsNavigator;
  isConfirmedEditing: boolean;
  showProminent?: boolean;
  shrinkLimit?: number;
  hide: boolean;
  disabled: boolean;
};

export function useContentWorkbook(
  props: PropsContentWorkbook,
  emit: (
    name: string,
    arg:
      | ProblemUtilsNavigatorValue
      | ContentWorkbookCheckPayload
      | ContentWorkbookChoicePayload
      | ContentWorkbookClearPayload
      | ContentWorkbookClickAnchorPayload
      | ContentWorkbookRefreshPayload
  ) => void
) {
  const msgs = useMessages({ prefix: 'contents.organisms.contentWorkbook' });
  const route = useRoute();
  const { errorDialog, error } = useErrorDialog();
  const { problemDialog, moveToProblem, watchProblemDialogQuery } = useProblemDialog(props);
  const { problemListDialog, moveToProblemList, watchProblemListDialogQuery } =
    useProblemListDialog(props);

  function initDialog() {
    watchProblemDialogQuery();
    watchProblemListDialogQuery();
  }

  function init() {
    initDialog();
  }

  const getWorkbookConfirmed = useGetEditingConfirmedContent();
  async function fetchWorkbookForEditingConfirmed(contentId: string) {
    const res = await getWorkbookConfirmed.execute({ id: contentId });
    if (isSucceeded(res)) {
      assertIsDefined(res.editingConfirmedContent, 'editingConfirmedContent');
      if ('workbook' in res.editingConfirmedContent) {
        return {
          dataVersion: res.editingConfirmedContent.dataVersion,
          exists: !!res.editingConfirmedContent.workbook,
        };
      }
      return false;
    }
    error(res.errors);
    return false;
  }

  const getWorkbook = useGetEditingCourseContentWorkbook();
  async function fetchWorkbookForEditing(contentId: string) {
    const res = await getWorkbook.execute({ contentId });
    if (isSucceeded(res)) return { dataVersion: res.workbook?.dataVersion, exists: !!res.workbook };
    error(res.errors);
    return false;
  }

  const createWorkbookConfirmed = useChangeEditingConfirmedContentWorkbookProblems();
  async function createWorkbookForEditingConfirmed(contentId: string, dataVersion: number) {
    const res = await createWorkbookConfirmed.execute({
      id: contentId,
      problems: [],
      expectedDataVersion: dataVersion,
    });
    if (isSucceeded(res)) return true;
    error(res.errors);
    return false;
  }

  const createWorkbook = useCreateOrUpdateEditingCourseContentWorkbook();
  async function createWorkbookForEditing(contentId: string) {
    const res = await createWorkbook.execute({ id: contentId, problems: [] });
    if (isSucceeded(res)) return true;
    error(res.errors);
    return false;
  }

  const loading = ref(false);
  async function create() {
    loading.value = true;
    const { id } = props.content;
    let wb: { dataVersion?: number; exists: boolean } | false;
    if (props.isConfirmedEditing) {
      wb = await fetchWorkbookForEditingConfirmed(id);
    } else {
      wb = await fetchWorkbookForEditing(id);
    }
    if (!wb) {
      loading.value = false;
      return;
    }
    if (wb.exists) {
      loading.value = false;
      moveToProblem();
      return;
    }
    let retCreate = false;
    if (props.isConfirmedEditing) {
      assertIsDefined(wb.dataVersion, 'dataVersion');
      retCreate = await createWorkbookForEditingConfirmed(id, wb.dataVersion);
    } else {
      retCreate = await createWorkbookForEditing(id);
    }
    if (!retCreate) {
      loading.value = false;
      return;
    }
    waitTransition(() => {
      loading.value = false;
      moveToProblem();
    });
  }

  function change(payload: ProblemUtilsNavigatorValue) {
    emit('change', payload);
  }

  function prev() {
    if (!props.problemNavigator || props.problemNavigator.prev.value === undefined) return;
    emit('change', props.problemNavigator.prev.value);
  }

  function next() {
    if (!props.problemNavigator || props.problemNavigator.next.value === undefined) return;
    emit('change', props.problemNavigator.next.value);
  }

  function choiceValue(v: ProblemViewChoiceValue) {
    if (props.index === 'results') return;
    emit('choice-value', { index: props.index, values: v });
  }

  function checkValue() {
    if (props.index === 'results') return;
    emit('check-value', { index: props.index });
  }

  function clearValue() {
    if (props.index === 'results') return;
    const { index } = props;
    emit('clear-value', { index });
  }

  function clickAnchor(payload: ProblemViewClickAnchorPayload) {
    emit('click-anchor', payload);
  }

  function refresh(payload: ProblemDialogRefreshPayload | ProblemListDialogRefreshPayload) {
    emit('refresh', payload);
  }

  const navigatorLabel = computed(() => {
    if (props.disabled) return undefined;
    return msgs.of('problems', { size: props.problems.length }).value;
  });

  const progressValue = computed(() => {
    if (!props.problemNavigator) return undefined;
    const total = props.problemNavigator.items.filter((item) => item.value !== 'results').length;
    const count = props.problemNavigator.items.filter((item) => item.passed).length;
    return (count / total) * 100;
  });

  const { mobile } = useVuetify();
  const vWindow = ref<Vue>();
  const styles = ref<Styles>(STYLES);

  function calculateStyle() {
    if (!vWindow.value || props.hide) return;
    const rectVWindow = vWindow.value.$el.getBoundingClientRect();
    const { top: vwTop } = rectVWindow;
    if (props.index === 'results' || mobile.value) {
      styles.value = {
        ...STYLES,
        problem: { minHeight: 'calc(100vh - 48px)' },
      };
    } else {
      const rectMain = getElementRect('.content-workbook');
      if (!rectMain) return;
      const rectBottom = getElementRect('.content-workbook-bottom');
      styles.value = {
        ...STYLES,
        frame: { class: undefined, style: { maxHeight: `calc(100vh - ${rectMain.top + 8}px)` } },
        windows: { class: 'overflow-y-auto', style: undefined },
        problem: { minHeight: `calc(100vh - ${vwTop + (rectBottom?.height ?? 0) + 16}px)` },
      };
    }
  }
  onMounted(() => {
    init();
    useTimeoutFn(calculateStyle, 1000);
  });
  watch(
    () => [props.index, props.hide, mobile.value],
    () => useTimeoutFn(calculateStyle, 500)
  );
  watch(
    () => route.query,
    () => {
      initDialog();
      useTimeoutFn(calculateStyle, 1000);
    }
  );

  return {
    loading,
    navigatorLabel,
    progressValue,
    vWindow,
    styles,
    problemDialog,
    problemListDialog,
    errorDialog,
    labelCreate: msgs.of('create'),
    labelAdd: msgs.of('add'),
    labelEdit: msgs.of('edit'),
    labelSort: msgs.of('sort'),
    labelNoWorkbook: msgs.of('noWorkbook'),
    moveToProblem,
    moveToProblemList,
    create,
    change,
    prev,
    next,
    clearValue,
    checkValue,
    choiceValue,
    clickAnchor,
    refresh,
  };
}
