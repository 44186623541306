import { computed, onMounted, Ref, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { toQuery } from '@/base/app/components/atoms/ReturnButtonComposable';
import { BaseDialogConfirm } from '@/base/app/components/molecules/BaseDialogConfirmComposable';
import { BaseDialogOk } from '@/base/app/components/molecules/BaseDialogOkComposable';
import {
  ContentTable,
  ContentTableActionPayload,
  ContentTableItem,
} from '@/base/app/components/molecules/ContentTableComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { useGlobalStore } from '@/base/app/store';
import { DialogName, useDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { waitTransition } from '@/base/app/utils/TransitionUtils';
import { Optional } from '@/base/types';
import { isFailed, isSucceeded } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { requiredInject, useRoute, useRouter } from '@/utils/VueUtils';

import { useChangeEditingCourseContents, useStartConfirmedContentEditing } from '../../../usecases';
import { CourseStoreCourse, CourseStoreKey } from '../../stores';
import { GroupTableItem } from '../molecules/GroupTableComposable';
import { ContentAddDialog } from './ContentAddDialogComposable';
import { ContentConfirmEditingDialog } from './ContentConfirmEditingDialogComposable';
import { ContentListDialog } from './ContentListDialogComposable';
import {
  ContentVersionDialogOpenPayload,
  ContentVersionsDialog,
} from './ContentVersionsDialogComposable';
import { CourseBackgroundDialog } from './CourseBackgroundDialogComposable';
import { CourseDescriptionDialog } from './CourseDescriptionDialogComposable';
import { GroupCourseContentsDialog } from './GroupCourseContentsDialogComposable';

const MENUS = [
  { value: 'edit', single: true, editable: true },
  { value: 'add', editable: true },
  { value: 'sort', editable: true, confirmed: true },
  { value: 'remove', required: true, editable: true },
  { value: 'correction', single: true, confirmed: true },
  { value: 'fix', single: true, confirmed: true },
];

const WINDOW_ITEMS = ['contents', 'details', 'groups'];

const HEADER_KEYS = ['no', 'name', 'typeLabel', 'requiredTime'];

export type PropsCourse = {
  id: string;
  readonly: boolean;
};

function useConfirmDialog() {
  const confirmDialog = ref<BaseDialogConfirm>();
  function open(msg: string, ok: () => void) {
    assertIsDefined(confirmDialog.value);
    confirmDialog.value.open(msg, ok);
  }
  return { confirmDialog, open };
}

function useErrorDialog() {
  const errorDialog = ref<BaseDialogOk>();
  function error(errors: ErrorMessage[]) {
    assertIsDefined(errorDialog.value);
    errorDialog.value.error(errors);
  }
  return { errorDialog, error };
}

function useContentAddDialog(props: PropsCourse) {
  const contentAddDialog = ref<ContentAddDialog>();
  function open() {
    assertIsDefined(contentAddDialog.value);
    contentAddDialog.value.open({ courseId: props.id });
  }
  return { contentAddDialog, open };
}

function useContentListDialog(props: PropsCourse) {
  const contentListDialog = ref<ContentListDialog>();
  const { getQuery, moveTo: moveToContentList } = useDialogQuery(DialogName.CONTENTS_CONTENT_LIST);
  function watchContentListDialogQuery() {
    if (!contentListDialog.value) return false;
    const q = getQuery();
    if (q) {
      contentListDialog.value.open({ id: props.id });
      return true;
    }
    if (!q && contentListDialog.value.opened()) {
      contentListDialog.value.close();
    }
    return false;
  }
  return { contentListDialog, moveToContentList, watchContentListDialogQuery };
}

function useContentVersionsDialog() {
  const contentVersionsDialog = ref<ContentVersionsDialog>();
  function open(payload: { id: string; disabled: boolean }) {
    assertIsDefined(contentVersionsDialog.value);
    contentVersionsDialog.value.open(payload);
  }
  return { contentVersionsDialog, open };
}

function useContentConfirmEditingDialog() {
  const contentConfirmEditingDialog = ref<ContentConfirmEditingDialog>();
  function open(payload: { id: string }) {
    assertIsDefined(contentConfirmEditingDialog.value);
    contentConfirmEditingDialog.value.open(payload);
  }
  return { contentConfirmEditingDialog, open };
}

function useCourseBackgroundDialog(course: Ref<Optional<CourseStoreCourse>>) {
  const backgroundDialog = ref<CourseBackgroundDialog>();
  const {
    getQuery,
    moveTo: moveToBackground,
    existsQuery,
  } = useDialogQuery(DialogName.CONTENTS_COURSE_BACKGROUND);
  function watchBackgroundDialogQuery() {
    if (!backgroundDialog.value) return false;
    const q = getQuery();
    if (q && course.value) {
      backgroundDialog.value.open(course.value);
      return true;
    }
    if (!q && !existsQuery() && backgroundDialog.value.opened()) {
      backgroundDialog.value.close();
    }
    return false;
  }
  return { backgroundDialog, moveToBackground, watchBackgroundDialogQuery };
}

function useDescriptionDialog(course: Ref<Optional<CourseStoreCourse>>) {
  const descriptionDialog = ref<CourseDescriptionDialog>();
  const { getQuery, moveTo: moveToDescription } = useDialogQuery(
    DialogName.CONTENTS_CONTENT_DESCRIPTION
  );
  function watchDescriptionDialogQuery() {
    if (!descriptionDialog.value) return false;
    const q = getQuery();
    if (q && course.value) {
      descriptionDialog.value.open({
        id: course.value.id,
        description: course.value.description ?? '',
        status: course.value.status,
      });
      return true;
    }
    if (!q && descriptionDialog.value.opened()) {
      descriptionDialog.value.close();
    }
    return false;
  }
  return { descriptionDialog, moveToDescription, watchDescriptionDialogQuery };
}

function useGroupCourseContentsDialog() {
  const groupCourseContentsDialog = ref<GroupCourseContentsDialog>();
  function open(payload: { groupId: string; groupName: string; courseId: string }) {
    assertIsDefined(groupCourseContentsDialog.value);
    groupCourseContentsDialog.value.open(payload);
  }
  return { groupCourseContentsDialog, open };
}

function useContentTable() {
  const contentTable = ref<ContentTable>();
  function clear() {
    if (contentTable.value) contentTable.value.clear();
  }
  return { contentTable, clear };
}

export function useCourse(props: PropsCourse) {
  const msgs = useMessages({ prefix: 'contents.organisms.course' });
  const route = useRoute();
  const router = useRouter();
  const { findUser } = useGlobalStore();
  const { confirmDialog, open: confirm } = useConfirmDialog();
  const { errorDialog, error } = useErrorDialog();
  const { contentAddDialog, open: openContentAdd } = useContentAddDialog(props);
  const { contentListDialog, moveToContentList, watchContentListDialogQuery } =
    useContentListDialog(props);
  const { contentVersionsDialog, open: openContentVersions } = useContentVersionsDialog();
  const { contentConfirmEditingDialog, open: openContentConfirmEditing } =
    useContentConfirmEditingDialog();
  const { groupCourseContentsDialog, open: openGroupCourseContents } =
    useGroupCourseContentsDialog();
  const { contentTable, clear: clearTableSelection } = useContentTable();

  const { course, groups, loading, fetch: fetchCourse } = requiredInject(CourseStoreKey);
  const { descriptionDialog, moveToDescription, watchDescriptionDialogQuery } =
    useDescriptionDialog(course);
  const { backgroundDialog, moveToBackground, watchBackgroundDialogQuery } =
    useCourseBackgroundDialog(course);

  const contents = computed<ContentTableItem[]>(() => {
    if (course.value && course.value.contents) {
      if (course.value.type === 'editing_course')
        return course.value.contents.map((item) => ({
          ...item,
          version: 1,
          hasEditingVersion: false,
        }));
      return course.value.contents.map((item) => {
        const [last] = item.versions
          .map((v) => ({ v: v.version, h: v.status === 'editing' }))
          .sort((a, b) => a.v - b.v)
          .slice(-1);
        if (!last) return { ...item, version: 1, hasEditingVersion: false };
        return { ...item, version: last.v, hasEditingVersion: last.h };
      });
    }
    return [];
  });
  const windowVal = ref('contents');
  const editing = computed(() => !props.readonly && course.value?.status !== 'disabled');
  const versionCreatedName = computed(() => {
    if (!course.value || !course.value.versionCreatedBy) return undefined;
    return (
      findUser(course.value.versionCreatedBy)?.name ||
      msgs.of('unknownUser', { id: course.value.versionCreatedBy }).value
    );
  });
  const contentLastUpdatedName = computed(() => {
    if (!course.value || !course.value.contentLastUpdatedBy) return undefined;
    return (
      findUser(course.value.contentLastUpdatedBy)?.name ||
      msgs.of('unknownUser', { id: course.value.contentLastUpdatedBy }).value
    );
  });
  const confirmedName = computed(() => {
    if (!course.value || !course.value.confirmedBy) return undefined;
    return (
      findUser(course.value.confirmedBy)?.name ||
      msgs.of('unknownUser', { id: course.value.confirmedBy }).value
    );
  });

  async function fetch(v = 'contents') {
    await fetchCourse(props.id);
    windowVal.value = v;
  }

  function initDialog() {
    if (watchContentListDialogQuery()) {
      windowVal.value = 'contents';
    } else if (watchDescriptionDialogQuery() || watchBackgroundDialogQuery()) {
      windowVal.value = 'details';
    }
  }

  async function init() {
    await fetch();
    initDialog();
  }
  onMounted(init);
  watch(() => route.query, initDialog);

  function done(v?: string) {
    clearTableSelection();
    fetch(v);
  }
  watch(
    () => props.id,
    () => {
      done();
    }
  );

  const changeCourse = useChangeEditingCourseContents();
  async function remove(ids: string[]) {
    assertIsDefined(course.value, 'course');
    const replaced = course.value.contents.filter((c) => !ids.includes(c.id));
    const res = await changeCourse.execute({ id: props.id, contents: replaced });
    if (isSucceeded(res)) return true;
    return res.errors;
  }

  async function openContent(item: ContentTableItem, edit: boolean) {
    const v = item.version ?? 1;
    const query = { edit: edit ? '' : undefined, ...toQuery(route, route.query) };
    router.push({ name: 'adminContent', params: { id: item.id, v: v.toString() }, query });
  }

  const startConfirmedContentEditing = useStartConfirmedContentEditing();
  async function openConfirmedContent(item: ContentTableItem, edit: boolean) {
    let v = item.version;
    assertIsDefined(v, 'version');
    if (edit && !item.hasEditingVersion) {
      const res = await startConfirmedContentEditing.execute({ id: item.id });
      if (isFailed(res)) return res.errors;
      v = res.editingConfirmedContent.version;
    }
    const query = { edit: edit ? '' : undefined, ...toQuery(route, route.query) };
    router.push({ name: 'adminContent', params: { id: item.id, v: v.toString() }, query });
    return true;
  }

  async function action({ event, selected }: ContentTableActionPayload) {
    switch (event) {
      case 'add':
        openContentAdd();
        break;
      case 'sort': {
        if (course.value?.status === 'enabled') {
          confirm(msgs.of('confirmEditingConfirmedCourse').value, moveToContentList);
          return;
        }
        moveToContentList();
        break;
      }
      case 'show': {
        const [first] = selected;
        assertIsDefined(first, 'first');
        openContent(first, false);
        break;
      }
      case 'remove': {
        if (selected.length === 0) return;
        confirm(msgs.of('confirmRemove').value, async () => {
          loading.value = true;
          const ret = await remove(selected.map((item) => item.id));
          if (ret !== true) {
            loading.value = false;
            error(ret);
            return;
          }
          waitTransition(done);
        });
        break;
      }
      case 'edit': {
        const [first] = selected;
        assertIsDefined(first, 'first');
        openContent(first, true);
        break;
      }
      case 'correction': {
        const [first] = selected;
        assertIsDefined(first, 'first');
        loading.value = true;
        const ret = await openConfirmedContent(first, true);
        loading.value = false;
        if (ret !== true) error(ret);
        break;
      }
      case 'fix': {
        const [first] = selected;
        assertIsDefined(first, 'first');
        if (!first.hasEditingVersion) {
          error([msgs.of('errorNoEditingVersion').value]);
          return;
        }
        openContentConfirmEditing({ id: first.id });
        break;
      }
      case 'versions': {
        const [first] = selected;
        assertIsDefined(first, 'first');
        openContentVersions({
          id: first.id,
          disabled: course.value?.status !== 'enabled' || props.readonly,
        });
        break;
      }
      default:
    }
  }

  function moveTo(to: 'description' | 'background' | 'image') {
    switch (to) {
      case 'description':
        moveToDescription();
        break;
      case 'background':
        moveToBackground();
        break;
      default:
    }
  }

  function confirmMoveTo(to: 'description' | 'background' | 'image') {
    if (course.value?.status === 'enabled' && to !== 'image') {
      confirm(msgs.of('confirmEditingConfirmedCourse').value, () => moveTo(to));
      return;
    }
    moveTo(to);
  }

  function moveToContent(payload: ContentVersionDialogOpenPayload) {
    const query = { ...toQuery(route, route.query) };
    router.push({ name: 'adminContent', params: payload, query });
  }

  function openGroupCourseContentsDialog(item: GroupTableItem) {
    openGroupCourseContents({
      groupId: item.id,
      groupName: item.name,
      courseId: props.id,
    });
  }

  const menus = computed(() => {
    if (!course.value || course.value.status === 'disabled' || props.readonly) return [];
    if (course.value.status === 'editing')
      return MENUS.filter((m) => m.editable).map((m) => ({
        ...m,
        label: msgs.of(m.value).value,
      }));
    return MENUS.filter((m) => m.confirmed).map((m) => ({
      ...m,
      label: msgs.of(m.value).value,
    }));
  });

  const headerKeys = computed(() => {
    if (course.value?.status === 'editing') return HEADER_KEYS;
    return [...HEADER_KEYS, 'hasEditingVersionLabel', 'preview'];
  });

  const windowItems = computed(() =>
    WINDOW_ITEMS.map((value) => ({ value, label: msgs.of(value).value }))
  );

  return {
    errorDialog,
    confirmDialog,
    contentAddDialog,
    contentListDialog,
    contentVersionsDialog,
    contentConfirmEditingDialog,
    backgroundDialog,
    descriptionDialog,
    groupCourseContentsDialog,
    course,
    contents,
    courseGroups: groups,
    windowVal,
    loading,
    editing,
    menus,
    headerKeys,
    windowItems,
    contentTable,
    versionCreatedName,
    confirmedName,
    contentLastUpdatedName,
    labelVersions: msgs.of('versions'),
    action,
    done,
    moveTo,
    moveToContent,
    confirmMoveTo,
    openGroupCourseContentsDialog,
  };
}
