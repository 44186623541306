import { computed, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { BaseDialogConfirm } from '@/base/app/components/molecules/BaseDialogConfirmComposable';
import { Problem, ProblemHeader } from '@/base/domains';
import { Optional } from '@/base/types';
import { assertIsDefined } from '@/utils/Asserts';

function useConfirmDialog() {
  const confirmDialog = ref<BaseDialogConfirm>();
  function open(msg: string, ok: () => void) {
    assertIsDefined(confirmDialog.value);
    confirmDialog.value.open(msg, ok);
  }
  return { confirmDialog, open };
}

type Header = {
  id: string;
  body: string;
  sortKey: number;
  count: number;
};

export type PropsProblemHeadersSelector = {
  value?: string;
  problemHeaders: ProblemHeader[];
  problems: Problem[];
  scrollName?: string;
};

export type ProblemHeaderSelectPayload = {
  id: string;
};

export type ProblemHeaderOpenPayload = Optional<{
  id: string;
}>;

export type ProblemHeaderRemovePayload = {
  id: string;
};

export function useProblemHeadersSelector(
  props: PropsProblemHeadersSelector,
  emit: (
    name: string,
    arg: ProblemHeaderSelectPayload | ProblemHeaderOpenPayload | ProblemHeaderRemovePayload
  ) => void
) {
  const msgs = useMessages({ prefix: 'contents.molecules.problemHeaderSelector' });
  const { confirmDialog, open: confirm } = useConfirmDialog();
  const menu = ref(false);

  function close() {
    menu.value = false;
  }

  function changedMenu(opened: boolean) {
    if (!props.scrollName) return;
    const [el] = document.getElementsByClassName(props.scrollName);
    if (!el) return;
    if (opened) el.addEventListener('scroll', close);
    else el.removeEventListener('scroll', close);
  }
  watch(menu, changedMenu);

  const headers = computed<Header[]>(() =>
    props.problemHeaders
      .map((ph) => {
        const idx = props.problems.filter((p) => p.headerId === ph.id).map((p) => p.index) || [];
        return { id: ph.id, body: ph.body, sortKey: Math.max(...idx, -1), count: idx.length };
      })
      .sort((a, b) => b.sortKey - a.sortKey)
  );

  function select(id: string) {
    emit('change', { id });
    close();
  }
  function openNew() {
    emit('open', undefined);
    close();
  }
  function openEdit(id: string) {
    emit('open', { id });
    close();
  }
  function remove(id: string) {
    let message = msgs.of('confirmRemove').value;
    const header = headers.value.find((item) => item.id === id);
    if (header && header.count > 0)
      message = msgs.of('confirmRemoveUsed', { count: header.count }).value;
    confirm(message, () => {
      emit('remove', { id });
      close();
    });
  }
  return {
    confirmDialog,
    menu,
    headers,
    labelClose: msgs.of('close'),
    labelDelete: msgs.of('delete'),
    labelEdit: msgs.of('edit'),
    labelNewArticle: msgs.of('newArticle'),
    labelNoData: msgs.of('noData'),
    labelNoSelect: msgs.of('noSelect'),
    close,
    select,
    openNew,
    openEdit,
    remove,
  };
}
