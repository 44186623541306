
























import { defineComponent } from '@vue/composition-api';

import BaseButtonIcon from '@/base/app/components/atoms/BaseButtonIcon.vue';
import ContentTable from '@/base/app/components/molecules/ContentTable.vue';

import { useGroupCourseContentsDialog } from './GroupCourseContentsDialogComposable';

export default defineComponent({
  name: 'ContentsGroupCourseContentsDialog',
  components: { BaseButtonIcon, ContentTable },
  inheritAttrs: false,
  setup() {
    return useGroupCourseContentsDialog();
  },
});
