









import { defineComponent, SetupContext } from '@vue/composition-api';

import CourseBackground from '@/base/app/components/molecules/CourseBackground.vue';

import {
  PropsCourseBackgroundRadio,
  useCourseBackgroundRadio,
} from './CourseBackgroundRadioComposable';

type Props = PropsCourseBackgroundRadio;

export default defineComponent({
  name: 'ContentsCourseBackgroundRadio',
  components: { CourseBackground },
  inheritAttrs: false,
  props: {
    color: { type: String, required: true },
    checked: { type: Boolean, default: false },
    height: { type: [String, Number], default: 'auto' },
    width: { type: [String, Number], default: 'auto' },
  },
  emits: ['change'],
  setup(props: Props, { emit }: SetupContext) {
    return useCourseBackgroundRadio(props, emit);
  },
});
