import { computed } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { BaseMarkdownClickAnchorPayload } from '@/base/app/components/atoms/BaseMarkdownComposable';
import { ProblemUtilsProblem } from '@/base/app/utils/ProblemUtils';

export type PropsProblemItem = {
  index: number;
  problem: ProblemUtilsProblem;
};

export type ProblemItemClickPayload = PropsProblemItem;

export type ProblemItemClickAnchorPayload = BaseMarkdownClickAnchorPayload;

export function useProblemItem(
  props: PropsProblemItem,
  emit: (name: string, arg: ProblemItemClickPayload | ProblemItemClickAnchorPayload) => void
) {
  const msgs = useMessages({ prefix: 'contents.molecules.problemItem' });
  const labelNo = computed(() => msgs.of('no', { no: props.problem.no }).value);

  function click() {
    emit('click', props);
  }

  function clickAnchor(payload: BaseMarkdownClickAnchorPayload) {
    emit('click-anchor', payload);
  }

  return { labelNo, click, clickAnchor };
}
