import { ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import {
  BaseDialogFullScreen,
  BaseDialogFullScreenValue,
} from '@/base/app/components/molecules/BaseDialogFullScreenComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { TextEditorValue } from '@/base/app/components/molecules/TextEditorComposable';
import { clearDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { isSucceeded } from '@/base/usecases';
import { CourseStatus } from '@/contents/domains';
import { assertIsDefined } from '@/utils/Asserts';
import { useRoute, useRouter } from '@/utils/VueUtils';

import {
  useChangeConfirmedCourseDescription,
  useChangeEditingCourseDescription,
} from '../../../usecases';

type Target = {
  id: string;
  description: TextEditorValue;
  status: CourseStatus;
};

function useDialogFullScreen() {
  const dialogFullScreen = ref<BaseDialogFullScreen>();
  const dialog = ref<BaseDialogFullScreenValue>({ display: false });
  function opened() {
    return dialog.value.display;
  }
  function error(errors: ErrorMessage[]) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showErrorDialog(errors);
  }
  return { dialogFullScreen, dialog, opened, error };
}

export function useCourseDescriptionDialog(emit: (name: string) => void) {
  const msgs = useMessages({ prefix: 'contents.organisms.courseDescriptionDialog' });
  const route = useRoute();
  const router = useRouter();
  const { dialogFullScreen, dialog, opened, error } = useDialogFullScreen();

  const target = ref<Target>();

  function close() {
    dialog.value = { display: false };
    target.value = undefined;
    const to = clearDialogQuery(route);
    if (to) router.replace(to);
  }

  function open(payload: Target) {
    target.value = {
      id: payload.id,
      description: payload.description || '',
      status: payload.status,
    };
    dialog.value = { display: true };
  }

  const changeDescription = useChangeEditingCourseDescription();
  const changeConfirmedCourseDescription = useChangeConfirmedCourseDescription();
  async function change(v?: string) {
    assertIsDefined(target.value, 'target');
    dialog.value = { ...dialog.value, status: 'updating' };
    const { id } = target.value;
    if (target.value.status === 'enabled') {
      const res = await changeConfirmedCourseDescription.execute({ id, description: v || '' });
      if (isSucceeded(res)) {
        dialog.value = { ...dialog.value, status: 'updated' };
        emit('done');
      } else {
        dialog.value = { ...dialog.value, status: 'changed' };
        error(res.errors);
      }
      return;
    }
    const res = await changeDescription.execute({ id, description: v || '' });
    if (isSucceeded(res)) {
      dialog.value = { ...dialog.value, status: 'updated' };
      emit('done');
    } else {
      dialog.value = { ...dialog.value, status: 'changed' };
      error(res.errors);
    }
  }

  return {
    dialogFullScreen,
    dialog,
    target,
    title: msgs.of('editCourse'),
    labelDescription: msgs.of('description'),
    close,
    open,
    opened,
    change,
  };
}

export type CourseDescriptionDialog = ReturnType<typeof useCourseDescriptionDialog>;
