
























import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import ErrorMessages from '@/base/app/components/molecules/ErrorMessages.vue';

import ContentNameForm from '../molecules/ContentNameForm.vue';
import { useContentAddDialog } from './ContentAddDialogComposable';

export default defineComponent({
  name: 'ContentsContentAddDialog',
  components: { BaseButton, ContentNameForm, ErrorMessages },
  inheritAttrs: false,
  emits: ['done'],
  setup(_, { emit }: SetupContext) {
    return useContentAddDialog(emit);
  },
});
