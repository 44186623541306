
























import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import { ProblemUtilsProblem } from '@/base/app/utils/ProblemUtils';

import { PropsProblemItem, useProblemItem } from './ProblemItemComposable';

type Props = PropsProblemItem;

export default defineComponent({
  name: 'ContentsProblemItem',
  components: { BaseMarkdown },
  inheritAttrs: false,
  props: {
    index: { type: Number, required: true },
    problem: { type: Object as PropType<ProblemUtilsProblem>, required: true },
  },
  emits: ['click', 'click-anchor'],
  setup(props: Props, { emit }: SetupContext) {
    return useProblemItem(props, emit);
  },
});
