























import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseText from '@/base/app/components/atoms/BaseText.vue';

import {
  ExamPassingStandardFormValue,
  PropsExamPassingStandardForm,
  useExamPassingStandardForm,
} from './ExamPassingStandardFormComposable';

type Props = PropsExamPassingStandardForm;

export default defineComponent({
  name: 'ContentsExamPassingStandardForm',
  components: { BaseText },
  model: { prop: 'value', event: 'submit' },
  props: {
    value: { type: Number as PropType<ExamPassingStandardFormValue>, default: undefined },
    problemCount: { type: Number, default: undefined },
  },
  emits: ['submit'],
  setup(props: Props, { emit }: SetupContext) {
    return useExamPassingStandardForm(props, emit);
  },
});
