import { computed, ref } from '@vue/composition-api';
import CropperJs from 'cropperjs';

import { useMessages } from '@/base/app';
import {
  BaseDialogFullScreen,
  BaseDialogFullScreenValue,
} from '@/base/app/components/molecules/BaseDialogFullScreenComposable';
import { Cropper } from '@/base/app/components/molecules/CropperComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { KNOWTE_COURSE_BACKGROUND_COLORS } from '@/base/app/utils/ColorUtils';
import { clearDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { useVuetify } from '@/base/app/utils/VuetifyUtils';
import { CourseFontColorOnImage } from '@/base/domains';
import { Optional } from '@/base/types';
import { isSucceeded } from '@/base/usecases';
import {
  useChangeConfirmedCourseColorOrImage,
  useChangeEditingCourseColorOrImage,
} from '@/contents/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { useRoute, useRouter } from '@/utils/VueUtils';

import { CourseStatus } from '../../../domains';
import { CourseBackgroundRadioValue } from '../molecules/CourseBackgroundRadioComposable';

const COLORS_FONT: CourseFontColorOnImage[] = ['black', 'white'];

type Course = {
  id: string;
  name: string;
  status: CourseStatus;
  color?: string;
  image?: string;
  fontColorOnImage?: CourseFontColorOnImage;
};

function useDialogFullScreen() {
  const dialogFullScreen = ref<BaseDialogFullScreen>();
  const dialog = ref<BaseDialogFullScreenValue>({ display: false });
  function opened() {
    return dialog.value.display;
  }
  function error(errors: ErrorMessage[]) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showErrorDialog(errors);
  }
  return { dialogFullScreen, dialog, opened, error };
}

function useCropper() {
  const cropper = ref<Cropper>();
  async function crop(option?: CropperJs.GetCroppedCanvasOptions) {
    assertIsDefined(cropper.value);
    const { canvas } = cropper.value.crop(option);
    return new Promise<Optional<Blob>>((resolve) =>
      canvas.toBlob((b) => resolve(b || undefined), 'image/jpeg')
    );
  }
  function destroy() {
    if (!cropper.value) return;
    cropper.value.destroy();
  }
  return { cropper, crop, destroy };
}

export function useCourseBackgroundDialog(emit: (name: string) => void) {
  const msgs = useMessages({ prefix: 'contents.organisms.courseBackgroundDialog' });
  const route = useRoute();
  const router = useRouter();
  const { theme: vTheme } = useVuetify();
  const { dialogFullScreen, dialog, opened, error } = useDialogFullScreen();
  const { cropper, crop, destroy } = useCropper();

  const theme = ref<string>();
  const course = ref<Course>();
  const cached = ref(false);
  const editing = ref(false);
  const cropping = ref(false);

  function close() {
    dialog.value = { display: false };
    course.value = undefined;
    cached.value = false;
    editing.value = false;
    destroy();
    const to = clearDialogQuery(route);
    if (to) router.replace(to);
  }

  function open(target: Course) {
    theme.value = vTheme.value?.dark ? 'dark' : 'light';
    course.value = { ...target };
    dialog.value = { display: true };
  }

  const changeCourse = useChangeEditingCourseColorOrImage();
  const changeConfirmedCourse = useChangeConfirmedCourseColorOrImage();
  async function changeColor(payload: CourseBackgroundRadioValue) {
    if (!payload.checked) return;
    assertIsDefined(course.value, 'course');
    const { id, status } = course.value;
    editing.value = false;
    course.value = { ...course.value, color: payload.color, image: undefined };
    dialog.value = { ...dialog.value, status: 'updating' };
    if (status === 'enabled') {
      const res = await changeConfirmedCourse.execute({ id, color: payload.color });
      if (isSucceeded(res)) {
        course.value = {
          ...course.value,
          color: res.course.color,
          image: res.course.image,
          fontColorOnImage: res.course.fontColorOnImage,
        };
        dialog.value = { ...dialog.value, status: 'updated' };
        emit('done');
      } else {
        dialog.value = { ...dialog.value, status: 'changed' };
        error(res.errors);
      }
      return;
    }
    const res = await changeCourse.execute({ id, color: payload.color });
    if (isSucceeded(res)) {
      course.value = {
        ...course.value,
        color: res.course.color,
        image: res.course.image,
        fontColorOnImage: res.course.fontColorOnImage,
      };
      dialog.value = { ...dialog.value, status: 'updated' };
      emit('done');
    } else {
      dialog.value = { ...dialog.value, status: 'changed' };
      error(res.errors);
    }
  }

  async function uploadImage() {
    assertIsDefined(course.value, 'course');
    cropping.value = true;
    const blob = await crop({ width: 400, height: 200 });
    if (!blob) {
      cropping.value = false;
      error([msgs.of('failedImageConversion').value]);
      return;
    }
    dialog.value = { ...dialog.value, status: 'updating' };
    const { id, status } = course.value;
    if (status === 'enabled') {
      const res = await changeConfirmedCourse.execute({ id, image: blob });
      if (isSucceeded(res)) {
        course.value = {
          ...course.value,
          color: res.course.color,
          image: res.course.image,
          fontColorOnImage: res.course.fontColorOnImage,
        };
        dialog.value = { ...dialog.value, status: 'updated' };
        cropping.value = false;
        editing.value = false;
        emit('done');
      } else {
        dialog.value = { ...dialog.value, status: 'changed' };
        cropping.value = false;
        error(res.errors);
      }
      return;
    }
    const res = await changeCourse.execute({ id, image: blob });
    if (isSucceeded(res)) {
      course.value = {
        ...course.value,
        color: res.course.color,
        image: res.course.image,
        fontColorOnImage: res.course.fontColorOnImage,
      };
      dialog.value = { ...dialog.value, status: 'updated' };
      cropping.value = false;
      editing.value = false;
      emit('done');
    } else {
      dialog.value = { ...dialog.value, status: 'changed' };
      cropping.value = false;
      error(res.errors);
    }
  }

  async function changeImageFontColor(v: CourseFontColorOnImage) {
    assertIsDefined(course.value, 'course');
    const { id, status } = course.value;
    course.value = { ...course.value, fontColorOnImage: v };
    dialog.value = { ...dialog.value, status: 'updating' };
    if (status === 'enabled') {
      const res = await changeConfirmedCourse.execute({ id, fontColorOnImage: v });
      if (isSucceeded(res)) {
        course.value = {
          ...course.value,
          color: res.course.color,
          image: res.course.image,
          fontColorOnImage: res.course.fontColorOnImage,
        };
        dialog.value = { ...dialog.value, status: 'updated' };
        emit('done');
      } else {
        dialog.value = { ...dialog.value, status: 'changed' };
        error(res.errors);
      }
      return;
    }
    const res = await changeCourse.execute({ id, fontColorOnImage: v });
    if (isSucceeded(res)) {
      course.value = {
        ...course.value,
        color: res.course.color,
        image: res.course.image,
        fontColorOnImage: res.course.fontColorOnImage,
      };
      dialog.value = { ...dialog.value, status: 'updated' };
      emit('done');
    } else {
      dialog.value = { ...dialog.value, status: 'changed' };
      error(res.errors);
    }
  }

  const color = computed(() =>
    course.value?.image ? undefined : course.value?.color || 'default'
  );
  const fontColors = computed(() =>
    COLORS_FONT.map((value) => ({ value, label: msgs.of(value).value }))
  );

  return {
    dialogFullScreen,
    dialog,
    cropper,
    theme,
    course,
    cached,
    editing,
    cropping,
    color,
    colors: KNOWTE_COURSE_BACKGROUND_COLORS,
    fontColors,
    size: { width: 140, height: 30 },
    title: msgs.of('editCourse'),
    subtitle: msgs.of('titleBackground'),
    labelTheme: msgs.of('theme'),
    labelLight: msgs.of('light'),
    labelDark: msgs.of('dark'),
    labelFontColor: msgs.of('fontColor'),
    labelUseImage: msgs.of('useImageFile'),
    labelUpload: msgs.of('uploadImage'),
    labelChange: msgs.of('changeImage'),
    labelCancel: msgs.of('cancel'),
    close,
    open,
    opened,
    changeColor,
    uploadImage,
    changeImageFontColor,
  };
}

export type CourseBackgroundDialog = ReturnType<typeof useCourseBackgroundDialog>;
