


















import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseDialogOk from '@/base/app/components/molecules/BaseDialogOk.vue';
import ErrorMessages from '@/base/app/components/molecules/ErrorMessages.vue';

import ExamPassingStandardForm from '../molecules/ExamPassingStandardForm.vue';
import { useExamPassingStandardDialog } from './ExamPassingStandardDialogComposable';

export default defineComponent({
  name: 'ContentsExamPassingStandardDialog',
  components: { ExamPassingStandardForm, BaseButton, ErrorMessages, BaseDialogOk },
  inheritAttrs: false,
  emits: ['refresh'],
  setup(_, { emit }: SetupContext) {
    return useExamPassingStandardDialog(emit);
  },
});
