import { computed } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { Course } from '@/base/domains';

const DATE_FORMAT = 'YYYY/MM/DD HH:mm';

export type PropsCourseDetails = {
  course: Course;
  versionCreatedName?: string;
  confirmedName?: string;
  contentLastUpdatedName?: string;
};

export function useCourseDetails(props: PropsCourseDetails, emit: (name: string) => void) {
  const msgs = useMessages({ prefix: 'contents.molecules.courseDetails' });

  const updateList = computed(() => {
    const ret: { title: string; name: string; date: string }[] = [];
    if (props.versionCreatedName)
      ret.push({
        title: msgs.of('versionCreated').value,
        name: props.versionCreatedName,
        date: props.course.versionCreatedAt?.format(DATE_FORMAT) ?? '---',
      });
    if (props.confirmedName)
      ret.push({
        title: msgs.of('confirmed').value,
        name: props.confirmedName,
        date: props.course.confirmedAt.format(DATE_FORMAT),
      });
    if (props.contentLastUpdatedName)
      ret.push({
        title: msgs.of('contentLastUpdated').value,
        name: props.contentLastUpdatedName,
        date: props.course.contentLastUpdatedAt?.format(DATE_FORMAT) ?? '---',
      });
    return ret;
  });

  function open(key: string) {
    emit(`open-${key}`);
  }

  return {
    updateList,
    labelBackGround: msgs.of('backGround'),
    labelEdit: msgs.of('edit'),
    labelDescription: msgs.of('description'),
    open,
  };
}
