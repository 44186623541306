

























































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseButtonIcon from '@/base/app/components/atoms/BaseButtonIcon.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import BaseDialogConfirm from '@/base/app/components/molecules/BaseDialogConfirm.vue';
import { Problem, ProblemHeader } from '@/base/domains';

import {
  PropsProblemHeadersSelector,
  useProblemHeadersSelector,
} from './ProblemHeadersSelectorComposable';

type Props = PropsProblemHeadersSelector;

export default defineComponent({
  name: 'ContentsProblemHeadersSelector',
  components: { BaseButton, BaseButtonIcon, BaseMarkdown, BaseDialogConfirm },
  props: {
    value: { type: String, default: undefined },
    problemHeaders: { type: Array as PropType<ProblemHeader[]>, default: () => [] },
    problems: { type: Array as PropType<Problem[]>, default: () => [] },
    scrollName: { type: String, default: undefined },
  },
  emits: ['change', 'remove', 'open'],
  setup(props: Props, { emit }: SetupContext) {
    return useProblemHeadersSelector(props, emit);
  },
});
