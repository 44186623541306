







import { defineComponent, provide } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import MyApp from '@/base/app/components/MyApp.vue';

import Courses from '../components/organisms/Courses.vue';
import { CoursesStoreKey, useCoursesStore } from '../stores';

export default defineComponent({
  name: 'ContentsCoursesPage',
  components: { MyApp, Courses },
  setup() {
    const coursesStore = useCoursesStore();
    provide(CoursesStoreKey, coursesStore);
    const msgs = useMessages({ prefix: 'contents.pages.coursesPage' });
    return { labelCourseManagement: msgs.of('courseManagement') };
  },
});
