















import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseTable from '@/base/app/components/molecules/BaseTable.vue';

import { GroupTableItem, PropsGroupTable, useGroupTable } from './GroupTableComposable';

type Props = PropsGroupTable;

export default defineComponent({
  name: 'ContentsGroupTable',
  components: { BaseTable },
  inheritAttrs: false,
  props: {
    groups: { type: Array as PropType<GroupTableItem[]>, default: () => [] },
    headerKeys: {
      type: Array as PropType<string[]>,
      default: () => ['name', 'preview', 'statusLabel'],
    },
  },
  emits: ['action'],
  setup(props: Props, { emit }: SetupContext) {
    return useGroupTable(props, emit);
  },
});
