import { onMounted, ref, watch } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';

export type ContentVersionValue = {
  description?: string;
};

export type PropsContentVersionForm = {
  value: ContentVersionValue;
};

export function useContentVersionForm(
  props: PropsContentVersionForm,
  emit: (name: string, args: ContentVersionValue) => void
) {
  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const input = ref<ContentVersionValue>({ description: undefined });

  function init() {
    input.value = { ...props.value };
  }
  onMounted(init);
  watch(() => props.value, init);

  async function submit() {
    const valid = await observer.value?.validate();
    if (valid) emit('submit', { ...input.value });
    return valid;
  }

  async function validate() {
    const valid = await observer.value?.validate();
    return valid;
  }

  function reset() {
    if (observer.value) observer.value.reset();
  }

  const msgs = useMessages({ prefix: 'contents.molecules.contentVersionForm' });
  return {
    observer,
    input,
    labelDescription: msgs.of('versionDescription'),
    init,
    submit,
    validate,
    reset,
  };
}

export type ContentVersionForm = ReturnType<typeof useContentVersionForm>;
