



































import { defineComponent, SetupContext } from '@vue/composition-api';
import draggable from 'vuedraggable';

import BaseDialogFullScreen from '@/base/app/components/molecules/BaseDialogFullScreen.vue';
import Loading from '@/base/app/components/organisms/Loading.vue';

import ContentItem from '../molecules/ContentItem.vue';
import { useContentListDialog } from './ContentListDialogComposable';

export default defineComponent({
  name: 'ContentsContentListDialog',
  components: {
    BaseDialogFullScreen,
    ContentItem,
    Loading,
    draggable,
  },
  emits: ['done'],
  setup(_, { emit }: SetupContext) {
    return useContentListDialog(emit);
  },
});
