









































































import { defineComponent } from '@vue/composition-api';

import WindowControl from '@/base/app/components/molecules/WindowControl.vue';
import DialogAnchorConfirm from '@/base/app/components/organisms/DialogAnchorConfirm.vue';

import { PropsContentContainer, useContentContainer } from './ContentContainerComposable';
import ContentExam from './ContentExam.vue';
import ContentText from './ContentText.vue';
import ContentWorkbook from './ContentWorkbook.vue';

type Props = PropsContentContainer;

export default defineComponent({
  name: 'ContentsContentContainer',
  components: {
    WindowControl,
    ContentText,
    ContentWorkbook,
    ContentExam,
    DialogAnchorConfirm,
  },
  props: {
    id: { type: String, required: true },
    v: { type: String, required: true },
    readonly: { type: Boolean, default: false },
    topHeight: { type: Number, default: 64 },
    sideWidth: { type: Number, default: 300 },
    side: { type: Boolean, default: false },
  },
  setup(props: Props) {
    return useContentContainer(props);
  },
});
