
























import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import ErrorMessages from '@/base/app/components/molecules/ErrorMessages.vue';

import CourseNameForm from '../molecules/CourseNameForm.vue';
import { PropsCourseNameDialog, useCourseNameDialog } from './CourseNameDialogComposable';

type Props = PropsCourseNameDialog;

export default defineComponent({
  name: 'ContentsCourseDialog',
  components: { BaseButton, CourseNameForm, ErrorMessages },
  inheritAttrs: false,
  props: { from: { type: String, required: true } },
  emits: ['done'],
  setup(props: Props, { emit }: SetupContext) {
    return useCourseNameDialog(props, emit);
  },
});
