













import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseTable from './BaseTable.vue';
import { ContentTableItem, PropsContentTable, useContentTable } from './ContentTableComposable';

type Props = PropsContentTable;

export default defineComponent({
  name: 'BaseContentTable',
  components: { BaseTable },
  inheritAttrs: false,
  props: {
    contents: { type: Array as PropType<ContentTableItem[]>, default: () => [] },
    headerKeys: {
      type: Array as PropType<string[]>,
      default: () => ['no', 'name', 'typeLabel', 'requiredTime'],
    },
  },
  emits: ['action'],
  setup(props: Props, { emit }: SetupContext) {
    return useContentTable(props, emit);
  },
});
