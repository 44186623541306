import { computed, ref } from '@vue/composition-api';
import { useTimeoutFn } from '@vueuse/core';

import { Optional } from '@/base/types';
import { isSucceeded } from '@/base/usecases';
import { config } from '@/config';
import { injectionKeyOf, readonly } from '@/utils/VueUtils';

import { GetCourseContentDetailsContent, useGetCourseContentDetails } from '../../usecases';

type ContentStoreContent = GetCourseContentDetailsContent;

export function useContentStore() {
  const content = ref<ContentStoreContent>();
  const loading = ref(false);

  const getContent = useGetCourseContentDetails();
  async function fetchContent(id: string, version: number): Promise<Optional<ContentStoreContent>> {
    const res = await getContent.execute({ id, version });
    if (isSucceeded(res)) return res.content;
    return undefined;
  }

  async function fetchContentRetry(id: string, version: number) {
    const c = await fetchContent(id, version);
    if (c) return c;
    if (config().app.fetchRetry.interval < 0 || config().app.fetchRetry.max <= 0) return undefined;
    return new Promise<Optional<ContentStoreContent>>((resolve) => {
      let count = 0;
      const { start } = useTimeoutFn(async () => {
        const ret = await fetchContent(id, version);
        if (ret) {
          resolve(ret);
        } else if (count < config().app.fetchRetry.max) {
          count += 1;
          start();
        } else {
          resolve(undefined);
        }
      }, config().app.fetchRetry.interval * 1000);
    });
  }

  async function fetch(id: string, v: string) {
    const version = parseInt(v, 10);
    if (!Number.isInteger(version)) {
      content.value = undefined;
      return;
    }
    loading.value = true;
    content.value = await fetchContentRetry(id, version);
    loading.value = false;
  }

  const notFound = computed(() => !content.value);
  const editable = computed(
    () =>
      content.value?.courseContentType === 'confirmed_course_content_editing' ||
      content.value?.courseContentType === 'editing_course_content'
  );
  const isConfirmedEditing = computed(
    () => content.value?.courseContentType === 'confirmed_course_content_editing'
  );
  const isWorkbookOnly = computed(() => {
    if (!content.value || content.value.type !== 'text') return false;
    if (content.value.body.body || !content.value.workbook) return false;
    return content.value.workbook.problems.length > 0;
  });

  return {
    content: readonly(content),
    loading,
    notFound,
    editable,
    isConfirmedEditing,
    isWorkbookOnly,
    fetch,
  };
}

export type ContentStore = ReturnType<typeof useContentStore>;

export const ContentStoreKey = injectionKeyOf<ContentStore>({
  boundedContext: 'contents',
  type: 'store',
  name: 'ContentStore',
});
