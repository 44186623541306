import { computed, ref } from '@vue/composition-api';
import { useTimeoutFn } from '@vueuse/core';

import { Optional } from '@/base/types';
import { isSucceeded } from '@/base/usecases';
import { config } from '@/config';
import { injectionKeyOf, readonly } from '@/utils/VueUtils';

import {
  GetCourseForEditingCourse,
  GetGroupsByCourseGroup,
  useGetCourseForEditing,
  useGetGroupsByCourse,
} from '../../usecases';

export type CourseStoreCourse = GetCourseForEditingCourse;

export function useCourseStore() {
  const course = ref<CourseStoreCourse>();
  const groups = ref<GetGroupsByCourseGroup[]>([]);
  const loading = ref(false);

  const getCourse = useGetCourseForEditing();
  async function fetchCourse(id: string) {
    const res = await getCourse.execute({ id });
    if (isSucceeded(res)) return res.course;
    return undefined;
  }

  async function fetchCourseRetry(id: string) {
    const c = await fetchCourse(id);
    if (c) return c;
    if (config().app.fetchRetry.interval < 0 || config().app.fetchRetry.max <= 0) return undefined;
    return new Promise<Optional<GetCourseForEditingCourse>>((resolve) => {
      let count = 0;
      const { start } = useTimeoutFn(async () => {
        const ret = await fetchCourse(id);
        if (ret) {
          resolve(ret);
        } else if (count < config().app.fetchRetry.max) {
          count += 1;
          start();
        } else {
          resolve(undefined);
        }
      }, config().app.fetchRetry.interval * 1000);
    });
  }

  const getGroups = useGetGroupsByCourse();
  async function fetchGroups(id: string) {
    const res = await getGroups.execute({ id });
    if (isSucceeded(res)) return res.groups;
    return [];
  }

  async function fetch(id: string) {
    loading.value = true;
    course.value = await fetchCourseRetry(id);
    if (course.value) {
      groups.value = await fetchGroups(id);
    } else {
      groups.value = [];
    }
    loading.value = false;
  }

  const notFound = computed(() => !course.value);

  return {
    course: readonly(course),
    groups: readonly(groups),
    loading,
    notFound,
    fetch,
  };
}

export type CourseStore = ReturnType<typeof useCourseStore>;

export const CourseStoreKey = injectionKeyOf<CourseStore>({
  boundedContext: 'contents',
  type: 'store',
  name: 'CourseStore',
});
