
































































































































import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseDialogFullScreen from '@/base/app/components/molecules/BaseDialogFullScreen.vue';
import CourseBackground from '@/base/app/components/molecules/CourseBackground.vue';
import Cropper from '@/base/app/components/molecules/Cropper.vue';

import CourseBackgroundRadio from '../molecules/CourseBackgroundRadio.vue';
import { useCourseBackgroundDialog } from './CourseBackgroundDialogComposable';

export default defineComponent({
  name: 'ContentsCourseBackgroundDialog',
  components: {
    BaseDialogFullScreen,
    BaseButton,
    CourseBackground,
    CourseBackgroundRadio,
    Cropper,
  },
  emits: ['done'],
  setup(_, { emit }: SetupContext) {
    return useCourseBackgroundDialog(emit);
  },
});
