
















































import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseText from '@/base/app/components/atoms/BaseText.vue';
import BaseDialogFullScreen from '@/base/app/components/molecules/BaseDialogFullScreen.vue';
import Loading from '@/base/app/components/organisms/Loading.vue';

import { useContentHeaderDialog } from './ContentHeaderDialogComposable';

export default defineComponent({
  name: 'ContentsContentHeaderDialog',
  components: { BaseDialogFullScreen, BaseText, Loading },
  emits: ['refresh'],
  setup(_, { emit }: SetupContext) {
    return useContentHeaderDialog(emit);
  },
});
