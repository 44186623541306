





























































import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseButtonIcon from '@/base/app/components/atoms/BaseButtonIcon.vue';

import ContentVersionEditor from '../molecules/ContentVersionEditor.vue';
import { useContentVersionsDialog } from './ContentVersionsDialogComposable';

export default defineComponent({
  name: 'ContentsContentsVersionsDialog',
  components: { BaseButtonIcon, BaseButton, ContentVersionEditor },
  inheritAttrs: false,
  emits: ['done', 'open'],
  setup(_, { emit }: SetupContext) {
    return useContentVersionsDialog(emit);
  },
});
