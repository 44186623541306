import { ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { ContentTableItem } from '@/base/app/components/molecules/ContentTableComposable';
import { CourseId, GroupId, GroupName } from '@/base/domains';
import { isSucceeded } from '@/base/usecases';

import { useGetGroupTrainingCourse } from '../../../usecases';

const HEADER_KEYS = ['no', 'name', 'typeLabel', 'version'];

type GroupCourseContentsDialogContent = {
  groupId: GroupId;
  groupName: GroupName;
  courseId: CourseId;
};

export function useGroupCourseContentsDialog() {
  const msgs = useMessages({ prefix: 'contents.organisms.groupCourseContentsDialog' });

  const dialog = ref(false);
  const loading = ref(false);
  const groupName = ref<GroupName>('');
  const groupCourseContents = ref<ContentTableItem[]>([]);

  const getGroupTrainingCourse = useGetGroupTrainingCourse();
  async function fetchGroupCourse(courseId: string, groupId: string) {
    const groupCourse = await getGroupTrainingCourse.execute({ courseId, groupId });
    if (!isSucceeded(groupCourse)) return;
    groupCourseContents.value =
      groupCourse.groupTrainingCourse?.contents.map((c) => {
        return {
          id: c.id,
          type: c.type.toString(),
          name: c.name,
          version: c.version,
        };
      }) ?? [];
  }

  function close() {
    dialog.value = false;
  }

  async function open(payload: GroupCourseContentsDialogContent) {
    dialog.value = true;
    groupName.value = payload.groupName;
    loading.value = true;
    await fetchGroupCourse(payload.courseId, payload.groupId);
    loading.value = false;
  }

  return {
    dialog,
    loading,
    items: groupCourseContents,
    groupName,
    headerKeys: HEADER_KEYS,
    labelClose: msgs.of('close'),
    close,
    open,
  };
}

export type GroupCourseContentsDialog = ReturnType<typeof useGroupCourseContentsDialog>;
