import { computed, nextTick, ref } from '@vue/composition-api';
import { useTimeoutFn } from '@vueuse/core';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';
import { BaseDialogConfirm } from '@/base/app/components/molecules/BaseDialogConfirmComposable';
import {
  BaseDialogFullScreen,
  BaseDialogFullScreenValue,
} from '@/base/app/components/molecules/BaseDialogFullScreenComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { clearDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { Exam, Problem, ProblemHeader } from '@/base/domains';
import { isSucceeded } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { useRoute, useRouter } from '@/utils/VueUtils';

import {
  AddProblemHeaderToEditingConfirmedContentRequest,
  AddProblemHeaderToEditingCourseContentRequest,
  ChangeEditingConfirmedContentBodyRequest,
  ChangeEditingConfirmedContentProblemHeaderRequest,
  ChangeEditingCourseContentProblemHeaderRequest,
  RemoveProblemHeaderFromEditingConfirmedContentRequest,
  RemoveProblemHeaderFromEditingCourseContentRequest,
  UpdateEditingCourseContentBodyRequest,
  useAddProblemHeaderToEditingConfirmedContent,
  useAddProblemHeaderToEditingCourseContent,
  useChangeEditingConfirmedContentBody,
  useChangeEditingConfirmedContentProblemHeader,
  useChangeEditingCourseContentProblemHeader,
  useGetEditingConfirmedContent,
  useGetEditingCourseContentBody,
  useRemoveProblemHeaderFromEditingConfirmedContent,
  useRemoveProblemHeaderFromEditingCourseContent,
  useUpdateEditingCourseContentBody,
} from '../../../usecases';
import {
  ProblemHeaderDialog,
  ProblemHeaderDialogPayload,
} from '../molecules/ProblemHeaderDialogComposable';
import { ProblemHeaderSelectPayload } from '../molecules/ProblemHeadersSelectorComposable';
import {
  changeProblems,
  convert,
  DEFAULT_PROBLEM_FORM,
  ProblemForm,
  ProblemOptionForm,
  validateProblems,
} from './ProblemDialogComposable';

const CHANGE_DELAY = 500;

function useDialogFullScreen() {
  const dialogFullScreen = ref<BaseDialogFullScreen>();
  const dialog = ref<BaseDialogFullScreenValue>({ display: false });
  function opened() {
    return dialog.value.display;
  }
  function toast(messages?: string[]) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showToast(messages);
  }
  function error(errors: ErrorMessage[]) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showErrorDialog(errors);
  }
  function info(message: string, ok: () => void) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showDialog(message, ok);
  }
  return { dialogFullScreen, dialog, opened, toast, error, info };
}

function useConfirmDialog() {
  const confirmDialog = ref<BaseDialogConfirm>();
  function open(msg: string, ok: () => void) {
    assertIsDefined(confirmDialog.value);
    confirmDialog.value.open(msg, ok);
  }
  return { confirmDialog, open };
}

export type ExamBodyDialogRefreshPayload = {
  item: 'exam';
  index?: number;
};

type ExamBodyDialogContent = {
  id: string;
  isConfirmedEditing: boolean;
  problems: Problem[];
  problemHeaders: ProblemHeader[];
};

export function useExamBodyDialog(emit: (name: string, arg: ExamBodyDialogRefreshPayload) => void) {
  const msgs = useMessages({ prefix: 'contents.organisms.examBodyDialog' });
  const route = useRoute();
  const router = useRouter();
  const { dialogFullScreen, dialog, opened, toast, error, info } = useDialogFullScreen();
  const { confirmDialog, open: confirm } = useConfirmDialog();

  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const content = ref<ExamBodyDialogContent>();
  const loading = ref(false);
  const input = ref<ProblemForm>({ ...DEFAULT_PROBLEM_FORM });
  const inputOptions = ref<ProblemOptionForm[]>([]);

  const getBodyConfirmedEditing = useGetEditingConfirmedContent();
  async function fetchBodyConfirmedEditing(contentId: string) {
    const res = await getBodyConfirmedEditing.execute({ id: contentId });
    if (isSucceeded(res) && res.editingConfirmedContent?.type === 'exam') {
      const exam = res.editingConfirmedContent;
      return { ...exam.body, id: exam.id, dataVersion: exam.dataVersion };
    }
    return undefined;
  }

  const getBody = useGetEditingCourseContentBody();
  async function fetchBody(contentId: string) {
    const res = await getBody.execute({ contentId });
    if (isSucceeded(res) && res.body.type === 'exam') {
      const exam = res.body.body;
      return { ...exam, id: res.body.id, dataVersion: res.body.dataVersion };
    }
    return undefined;
  }

  async function fetch(contentId: string, isConfirmedEditing: boolean, index?: number) {
    loading.value = true;
    let ret:
      | (Omit<ExamBodyDialogContent, 'isConfirmedEditing'> & { dataVersion: number })
      | undefined;
    if (isConfirmedEditing) {
      ret = await fetchBodyConfirmedEditing(contentId);
    } else {
      ret = await fetchBody(contentId);
    }
    if (ret) {
      content.value = {
        id: ret.id,
        problems: ret.problems,
        problemHeaders: ret.problemHeaders,
        isConfirmedEditing,
      };
      const converted = convert(ret.problems, ret.dataVersion, index);
      if (converted) {
        input.value = converted.input;
        inputOptions.value = converted.inputOptions;
      } else {
        content.value = undefined;
        input.value = { ...DEFAULT_PROBLEM_FORM };
        inputOptions.value = [];
      }
    } else {
      content.value = undefined;
      input.value = { ...DEFAULT_PROBLEM_FORM };
      inputOptions.value = [];
    }
    loading.value = false;
  }

  function close() {
    toast();
    dialog.value = { display: false };
    content.value = undefined;
    input.value = { ...DEFAULT_PROBLEM_FORM };
    inputOptions.value = [];
    const to = clearDialogQuery(route);
    if (to) router.replace(to);
  }

  async function open(payload: { id: string; isConfirmedEditing: boolean; index?: number }) {
    dialog.value = { display: true };
    await fetch(payload.id, payload.isConfirmedEditing, payload.index);
    if (!content.value) info(msgs.of('noData').value, close);
    nextTick(() => toast());
  }

  const changeBodyConfirmedEditing = useChangeEditingConfirmedContentBody();
  async function updateConfirmedEditing(req: ChangeEditingConfirmedContentBodyRequest) {
    const res = await changeBodyConfirmedEditing.execute(req);
    if (isSucceeded(res)) {
      const exam = res.editingConfirmedContent.body as Exam;
      return { problems: exam.problems, dataVersion: res.editingConfirmedContent.dataVersion };
    }
    return { errors: res.errors };
  }

  const changeBody = useUpdateEditingCourseContentBody();
  async function updateEditingCourse(req: UpdateEditingCourseContentBodyRequest) {
    const res = await changeBody.execute(req);
    if (isSucceeded(res)) {
      const exam = res.body.body as Exam;
      return { problems: exam.problems, dataVersion: res.body.dataVersion };
    }
    return { errors: res.errors };
  }

  async function update(problems: Problem[], expectedDataVersion: number) {
    assertIsDefined(content.value, 'content');
    const { id, isConfirmedEditing, problemHeaders } = content.value;

    dialog.value = { ...dialog.value, status: 'updating' };

    let ret: { problems: Problem[]; dataVersion: number } | { errors: ErrorMessage[] };
    if (isConfirmedEditing) {
      ret = await updateConfirmedEditing({
        id,
        body: { problems, problemHeaders },
        expectedDataVersion,
      });
    } else {
      ret = await updateEditingCourse({
        id,
        body: { problems, problemHeaders },
        expectedDataVersion,
      });
    }
    if ('errors' in ret) {
      dialog.value = { ...dialog.value, status: 'changed' };
      error(ret.errors);
      return false;
    }

    dialog.value = { ...dialog.value, status: 'updated' };

    content.value = { ...content.value, problems: ret.problems };
    input.value = { ...input.value, dataVersion: ret.dataVersion };
    return true;
  }

  async function changeDelay() {
    dialog.value = { ...dialog.value, status: 'changed' };
    if (!observer.value) return;
    assertIsDefined(observer.value);
    toast();
    const valid = await observer.value.validate();
    if (!valid) return;

    const validProblem = validateProblems(input.value, inputOptions.value);
    if (validProblem.errors.length > 0) {
      toast(validProblem.errors.map((key) => msgs.of(key).value));
      return;
    }

    assertIsDefined(content.value, 'content');
    assertIsDefined(input.value.dataVersion, 'dataVersion');
    const { problems, index } = changeProblems(content.value.problems, validProblem.problem);
    const ret = await update(problems, input.value.dataVersion);
    if (ret) {
      input.value = { ...input.value, index };
      emit('refresh', { item: 'exam', index });
    }
  }

  const waitingChange = ref(0);
  function tryChange() {
    if (waitingChange.value === 0) return;
    waitingChange.value = 0;
    changeDelay();
  }

  function change() {
    waitingChange.value += 1;
    useTimeoutFn(tryChange, CHANGE_DELAY);
  }

  async function remove() {
    const c = content.value;
    assertIsDefined(c, 'content');
    const version = input.value.dataVersion;
    const problem = c.problems.find((item) => item.index === input.value.index);
    assertIsDefined(version, 'dataVersion');
    assertIsDefined(problem, 'problem');
    confirm(msgs.of('confirmRemove').value, async () => {
      dialog.value = { ...dialog.value, status: 'updating' };
      const { problems } = changeProblems(c.problems, problem, true);
      const ret = await update(problems, version);
      if (ret) {
        emit('refresh', { item: 'exam' });
        close();
      }
    });
  }

  function toggleCorrect(option: ProblemOptionForm) {
    Object.assign(option, { correct: !option.correct });
    change();
  }

  function scrollToEnd() {
    const [e] = document.getElementsByClassName('exam-body-dialog');
    if (!e || !e.firstChild) return;
    const { height } = (e.firstChild as Element).getBoundingClientRect();
    e.scrollTo({ top: height });
  }

  function addOption() {
    const option = { text: '', correct: false };
    inputOptions.value.push(option);
    dialog.value = { ...dialog.value, status: 'changed' };
    nextTick(() => useTimeoutFn(scrollToEnd, 300));
  }
  function removeOption(index: number) {
    inputOptions.value.splice(index, 1);
    change();
  }

  function clearProblemHeaderId() {
    input.value.headerId = undefined;
    change();
  }

  function updateProblemHeaderId(payload: ProblemHeaderSelectPayload) {
    input.value.headerId = payload.id;
    change();
  }

  const removeProblemHeaderConfirmedEditing = useRemoveProblemHeaderFromEditingConfirmedContent();
  async function deleteProblemHeaderConfirmedEditing(
    req: RemoveProblemHeaderFromEditingConfirmedContentRequest
  ) {
    const res = await removeProblemHeaderConfirmedEditing.execute(req);
    if (isSucceeded(res)) {
      const exam = res.editingConfirmedContent.body as Exam;
      return {
        problemHeaders: exam.problemHeaders,
        dataVersion: res.editingConfirmedContent.dataVersion,
      };
    }
    return { errors: res.errors };
  }

  const removeProblemHeaderEditingCourse = useRemoveProblemHeaderFromEditingCourseContent();
  async function deleteProblemHeaderEditingCourse(
    req: RemoveProblemHeaderFromEditingCourseContentRequest
  ) {
    const res = await removeProblemHeaderEditingCourse.execute(req);
    if (isSucceeded(res)) {
      const exam = res.content.body as Exam;
      return { problemHeaders: exam.problemHeaders, dataVersion: res.content.dataVersion };
    }
    return { errors: res.errors };
  }

  async function removeProblemHeader(payload: ProblemHeaderSelectPayload) {
    assertIsDefined(content.value, 'content');

    const { id, isConfirmedEditing } = content.value;
    const expectedDataVersion = input.value.dataVersion;
    assertIsDefined(expectedDataVersion, 'dataVersion');

    dialog.value = { ...dialog.value, status: 'updating' };

    let ret: { problemHeaders: ProblemHeader[]; dataVersion: number } | { errors: ErrorMessage[] };
    if (isConfirmedEditing) {
      ret = await deleteProblemHeaderConfirmedEditing({
        id,
        problemHeaderId: payload.id,
        expectedDataVersion,
      });
    } else {
      ret = await deleteProblemHeaderEditingCourse({
        id,
        problemHeaderId: payload.id,
        expectedDataVersion,
      });
    }
    if ('errors' in ret) {
      dialog.value = { ...dialog.value, status: 'changed' };
      error(ret.errors);
      return;
    }

    dialog.value = { ...dialog.value, status: 'updated' };

    content.value = { ...content.value, problemHeaders: ret.problemHeaders };
    input.value = { ...input.value, dataVersion: ret.dataVersion };
    if (input.value.headerId === payload.id) clearProblemHeaderId();
  }

  const problemHeaders = computed(() => content.value?.problemHeaders ?? []);
  const problems = computed(() => content.value?.problems ?? []);
  const problemHeaderDialog = ref<ProblemHeaderDialog>();
  function openProblemHeader(payload?: ProblemHeaderSelectPayload) {
    assertIsDefined(problemHeaderDialog.value);
    if (payload) {
      const header = problemHeaders.value.find((item) => item.id === payload.id);
      if (header) problemHeaderDialog.value.open(header);
      return;
    }
    problemHeaderDialog.value.open();
  }

  const addProblemHeaderConfirmedEditing = useAddProblemHeaderToEditingConfirmedContent();
  async function createProblemHeaderConfirmedEditing(
    req: AddProblemHeaderToEditingConfirmedContentRequest
  ) {
    const res = await addProblemHeaderConfirmedEditing.execute(req);
    if (isSucceeded(res)) {
      const exam = res.editingConfirmedContent.body as Exam;
      return {
        problemHeaders: exam.problemHeaders,
        dataVersion: res.editingConfirmedContent.dataVersion,
      };
    }
    return { errors: res.errors };
  }

  const changeProblemHeaderConfirmedEditing = useChangeEditingConfirmedContentProblemHeader();
  async function updateProblemHeaderConfirmedEditing(
    req: ChangeEditingConfirmedContentProblemHeaderRequest
  ) {
    const res = await changeProblemHeaderConfirmedEditing.execute(req);
    if (isSucceeded(res)) {
      const exam = res.editingConfirmedContent.body as Exam;
      return {
        problemHeaders: exam.problemHeaders,
        dataVersion: res.editingConfirmedContent.dataVersion,
      };
    }
    return { errors: res.errors };
  }

  const addProblemHeaderEditingCourse = useAddProblemHeaderToEditingCourseContent();
  async function createProblemHeaderEditingCourse(
    req: AddProblemHeaderToEditingCourseContentRequest
  ) {
    const res = await addProblemHeaderEditingCourse.execute(req);
    if (isSucceeded(res)) {
      const exam = res.content.body as Exam;
      return {
        problemHeaders: exam.problemHeaders,
        dataVersion: res.content.dataVersion,
      };
    }
    return { errors: res.errors };
  }

  const changeProblemHeaderEditingCourse = useChangeEditingCourseContentProblemHeader();
  async function updateProblemHeaderEditingCourse(
    req: ChangeEditingCourseContentProblemHeaderRequest
  ) {
    const res = await changeProblemHeaderEditingCourse.execute(req);
    if (isSucceeded(res)) {
      const exam = res.content.body as Exam;
      return {
        problemHeaders: exam.problemHeaders,
        dataVersion: res.content.dataVersion,
      };
    }
    return { errors: res.errors };
  }

  async function changeProblemHeader(payload: ProblemHeaderDialogPayload) {
    assertIsDefined(content.value, 'content');

    const { id, isConfirmedEditing } = content.value;
    const expectedDataVersion = input.value.dataVersion;
    assertIsDefined(expectedDataVersion, 'dataVersion');

    let ret: { problemHeaders: ProblemHeader[]; dataVersion: number } | { errors: ErrorMessage[] };
    if (isConfirmedEditing && payload.value.id) {
      ret = await updateProblemHeaderConfirmedEditing({
        id,
        problemHeaderId: payload.value.id,
        body: payload.value.body ?? '',
        expectedDataVersion,
      });
    } else if (isConfirmedEditing) {
      ret = await createProblemHeaderConfirmedEditing({
        id,
        body: payload.value.body ?? '',
        expectedDataVersion,
      });
    } else if (payload.value.id) {
      ret = await updateProblemHeaderEditingCourse({
        id,
        problemHeaderId: payload.value.id,
        body: payload.value.body ?? '',
        expectedDataVersion,
      });
    } else {
      ret = await createProblemHeaderEditingCourse({
        id,
        body: payload.value.body ?? '',
        expectedDataVersion,
      });
    }
    if ('errors' in ret) {
      payload.done(ret.errors);
      return;
    }
    content.value = { ...content.value, problemHeaders: ret.problemHeaders };
    input.value = { ...input.value, dataVersion: ret.dataVersion };
    payload.done();
  }

  const notFound = computed(() => !content.value);
  const problemHeaderBody = computed(() => {
    if (input.value.headerId) {
      const header = problemHeaders.value.find((item) => item.id === input.value.headerId);
      if (header) return header.body;
      return msgs.of('unknownHeader', { id: input.value.headerId }).value;
    }
    return undefined;
  });
  const choiceTypes = computed(() =>
    msgs.listOf('choiceTypes').value.map((item) => ({ ...item, value: item.value === 'multiple' }))
  );

  return {
    dialogFullScreen,
    confirmDialog,
    dialog,
    observer,
    content,
    input,
    inputOptions,
    problemHeaders,
    problems,
    loading,
    problemHeaderDialog,
    notFound,
    problemHeaderBody,
    choiceTypes,
    title: msgs.of('editContent'),
    subtitle: msgs.of('exam'),
    labelChoiceType: msgs.of('choiceType'),
    labelBody: msgs.of('body'),
    labelBodyParticular: msgs.of('bodyParticular'),
    labelClearHeader: msgs.of('clearHeader'),
    labelCommentary: msgs.of('commentary'),
    labelOptions: msgs.of('options'),
    labelAddOption: msgs.of('addOption'),
    labelRemoveOption: msgs.of('removeOption'),
    labelCorrect: msgs.of('correct'),
    labelInCorrect: msgs.of('incorrect'),
    labelRemove: msgs.of('remove'),
    delay: CHANGE_DELAY + 100,
    close,
    open,
    opened,
    change,
    remove,
    toggleCorrect,
    addOption,
    clearProblemHeaderId,
    updateProblemHeaderId,
    removeProblemHeader,
    removeOption,
    openProblemHeader,
    changeProblemHeader,
  };
}

export type ExamBodyDialog = ReturnType<typeof useExamBodyDialog>;
