





















import { computed, defineComponent, provide } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import MyApp from '@/base/app/components/MyApp.vue';

import ContentContainer from '../components/organisms/ContentContainer.vue';
import { ContentStoreKey, useContentStore } from '../stores';

type Props = {
  edit: boolean;
};

export default defineComponent({
  name: 'ContentsContentPage',
  components: { MyApp, ContentContainer },
  props: {
    id: { type: String, required: true },
    v: { type: String, required: true },
    edit: { type: Boolean, default: false },
  },
  setup(props: Props) {
    const store = useContentStore();
    provide(ContentStoreKey, store);

    const msgs = useMessages({ prefix: 'contents.pages.contentPage' });
    const label = computed(() => {
      if (!store.content.value) return undefined;
      let key = 'contentNameFixed';
      if (store.content.value.courseContentType === 'editing_course_content')
        key = 'contentNameEditable';
      else if (store.content.value.courseContentType === 'confirmed_course_content_editing')
        key = 'contentNameConfirmedEditing';
      return msgs.of(key, { name: store.content.value.name, version: store.content.value.version })
        .value;
    });
    const icon = computed(() =>
      props.edit && store.editable.value ? 'mdi-pencil' : 'mdi-pencil-off'
    );
    const iconDescription = computed(
      () => msgs.of(props.edit && store.editable.value ? 'canEdit' : 'cannotEdit').value
    );

    return {
      label,
      icon,
      iconDescription,
      loading: store.loading,
      notFound: store.notFound,
    };
  },
});
