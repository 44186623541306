import { computed, nextTick, ref } from '@vue/composition-api';
import { useTimeoutFn } from '@vueuse/core';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';
import { BaseDialogConfirm } from '@/base/app/components/molecules/BaseDialogConfirmComposable';
import {
  BaseDialogFullScreen,
  BaseDialogFullScreenValue,
} from '@/base/app/components/molecules/BaseDialogFullScreenComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { TextEditorValue } from '@/base/app/components/molecules/TextEditorComposable';
import { clearDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { Problem, ProblemHeader } from '@/base/domains';
import { isSucceeded } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { useRoute, useRouter } from '@/utils/VueUtils';

import {
  AddProblemHeaderToEditingCourseContentWorkbookRequest,
  ChangeEditingConfirmedContentWorkbookProblemHeaderRequest,
  ChangeEditingConfirmedContentWorkbookProblemsRequest,
  ChangeEditingCourseContentWorkbookProblemHeaderRequest,
  CreateOrUpdateEditingCourseContentWorkbookRequest,
  RemoveProblemHeaderFromEditingConfirmedContentWorkbookRequest,
  RemoveProblemHeaderToEditingCourseContentWorkbookRequest,
  useAddProblemHeaderToEditingConfirmedContentWorkbook,
  useAddProblemHeaderToEditingCourseContentWorkbook,
  useChangeEditingConfirmedContentWorkbookProblemHeader,
  useChangeEditingConfirmedContentWorkbookProblems,
  useChangeEditingCourseContentWorkbookProblemHeader,
  useCreateOrUpdateEditingCourseContentWorkbook,
  useGetEditingConfirmedContent,
  useGetEditingCourseContentWorkbook,
  useRemoveProblemHeaderFromEditingConfirmedContentWorkbook,
  useRemoveProblemHeaderToEditingCourseContentWorkbook,
} from '../../../usecases';
import {
  ProblemHeaderDialog,
  ProblemHeaderDialogPayload,
} from '../molecules/ProblemHeaderDialogComposable';
import { ProblemHeaderSelectPayload } from '../molecules/ProblemHeadersSelectorComposable';

const CHANGE_DELAY = 500;

export type ProblemForm = {
  index: number;
  type: 'choice';
  multiple: boolean;
  body: TextEditorValue;
  commentary: TextEditorValue;
  headerId?: string;
  dataVersion?: number;
};

export const DEFAULT_PROBLEM_FORM: ProblemForm = {
  index: -1,
  type: 'choice',
  multiple: true,
  body: '',
  commentary: '',
  headerId: undefined,
  dataVersion: undefined,
};

export type ProblemOptionForm = {
  text: TextEditorValue;
  correct: boolean;
};

export function convert(problems?: Problem[], dataVersion?: number, index?: number) {
  if (index === undefined) {
    const input: ProblemForm = { ...DEFAULT_PROBLEM_FORM, dataVersion };
    const inputOptions: ProblemOptionForm[] = [{ text: '', correct: false }];
    return { input, inputOptions };
  }
  const problem = problems?.find((item) => item.index === index);
  if (!problem) return undefined;
  const input: ProblemForm = {
    index: problem.index,
    type: problem.type,
    multiple: problem.multiple,
    body: problem.body || '',
    commentary: problem.commentary || '',
    headerId: problem.headerId,
    dataVersion,
  };
  const inputOptions: ProblemOptionForm[] = problem.options.map((o) => ({
    text: o.text,
    correct: problem.answer.includes(o.index),
  }));
  return { input, inputOptions };
}

export function changeProblems(original: Problem[] | undefined, target: Problem, isRemove = false) {
  let { index } = target;
  const problems = [...(original || [])];
  const i = problems.findIndex((item) => item.index === target.index);
  if (isRemove && i !== -1) {
    problems.splice(i, 1);
  } else if (!isRemove && i === -1) {
    index = problems.push(target) - 1;
  } else if (!isRemove && i !== -1) {
    problems.splice(i, 1, target);
  }
  problems.forEach((item, j) => Object.assign(item, { index: j }));
  return { problems, index };
}

export function validateProblems(
  input: ProblemForm,
  inputOptions: ProblemOptionForm[]
): { problem: Problem; errors: string[] } {
  const errors: string[] = [];
  const options = inputOptions
    .filter((o) => !!o.text)
    .map((o, i) => ({ index: i, text: o.text ?? '' }));
  const answer = inputOptions
    .filter((o) => !!o.text)
    .map((o, i) => ({ index: i, correct: o.correct }))
    .filter((o) => o.correct)
    .map((o) => o.index);
  if (!input.body && !input.headerId) errors.push('errorBodyRequired');
  if (options.length === 0) errors.push('errorProblemRequired');
  if (answer.length === 0) errors.push('errorAnswerRequired');
  if (!input.multiple && answer.length > 1) errors.push('errorSingleAnswer');
  return {
    problem: { ...input, body: input.body ?? '', options, answer },
    errors,
  };
}

function useDialogFullScreen() {
  const dialogFullScreen = ref<BaseDialogFullScreen>();
  const dialog = ref<BaseDialogFullScreenValue>({ display: false });
  function opened() {
    return dialog.value.display;
  }
  function toast(messages?: string[]) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showToast(messages);
  }
  function error(errors: ErrorMessage[]) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showErrorDialog(errors);
  }
  function info(message: string, ok: () => void) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showDialog(message, ok);
  }
  return { dialogFullScreen, dialog, opened, toast, error, info };
}

function useConfirmDialog() {
  const confirmDialog = ref<BaseDialogConfirm>();
  function open(msg: string, ok: () => void) {
    assertIsDefined(confirmDialog.value);
    confirmDialog.value.open(msg, ok);
  }
  return { confirmDialog, open };
}

export type ProblemDialogRefreshPayload = {
  item: 'workbook';
  index?: number;
};

type ProblemDialogContent = {
  id: string;
  isConfirmedEditing: boolean;
  problems: Problem[];
  problemHeaders: ProblemHeader[];
};

export function useProblemDialog(emit: (name: string, arg: ProblemDialogRefreshPayload) => void) {
  const msgs = useMessages({ prefix: 'contents.organisms.problemDialog' });
  const route = useRoute();
  const router = useRouter();
  const { dialogFullScreen, dialog, opened, toast, error, info } = useDialogFullScreen();
  const { confirmDialog, open: confirm } = useConfirmDialog();

  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const content = ref<ProblemDialogContent>();
  const loading = ref(false);
  const input = ref<ProblemForm>({ ...DEFAULT_PROBLEM_FORM });
  const inputOptions = ref<ProblemOptionForm[]>([]);

  const getConfirmedWorkbook = useGetEditingConfirmedContent();
  async function fetchConfirmedWorkbook(contentId: string) {
    const res = await getConfirmedWorkbook.execute({ id: contentId });
    if (isSucceeded(res) && res.editingConfirmedContent?.type === 'text') {
      const text = res.editingConfirmedContent;
      return {
        id: text.id,
        problems: text.workbook?.problems ?? [],
        problemHeaders: text.workbook?.problemHeaders ?? [],
        dataVersion: text.dataVersion,
      };
    }
    return undefined;
  }

  const getWorkbook = useGetEditingCourseContentWorkbook();
  async function fetchWorkbook(contentId: string) {
    const res = await getWorkbook.execute({ contentId });
    if (isSucceeded(res)) {
      return {
        id: contentId,
        problems: res.workbook?.problems ?? [],
        problemHeaders: res.workbook?.problemHeaders ?? [],
        dataVersion: res.workbook?.dataVersion,
      };
    }
    return undefined;
  }

  async function fetch(contentId: string, isConfirmedEditing: boolean, index?: number) {
    loading.value = true;
    let ret:
      | (Omit<ProblemDialogContent, 'isConfirmedEditing'> & { dataVersion?: number })
      | undefined;
    if (isConfirmedEditing) {
      ret = await fetchConfirmedWorkbook(contentId);
    } else {
      ret = await fetchWorkbook(contentId);
    }
    if (ret) {
      content.value = {
        id: ret.id,
        problems: ret.problems,
        problemHeaders: ret.problemHeaders,
        isConfirmedEditing,
      };
      const converted = convert(ret.problems, ret.dataVersion, index);
      if (converted) {
        input.value = converted.input;
        inputOptions.value = converted.inputOptions;
      } else {
        content.value = undefined;
        input.value = { ...DEFAULT_PROBLEM_FORM };
        inputOptions.value = [];
      }
    } else {
      content.value = undefined;
      input.value = { ...DEFAULT_PROBLEM_FORM };
      inputOptions.value = [];
    }
    loading.value = false;
  }

  function close() {
    toast();
    dialog.value = { display: false };
    content.value = undefined;
    input.value = { ...DEFAULT_PROBLEM_FORM };
    inputOptions.value = [];
    const to = clearDialogQuery(route);
    if (to) router.replace(to);
  }

  async function open(payload: { id: string; isConfirmedEditing: boolean; index?: number }) {
    dialog.value = { display: true };
    await fetch(payload.id, payload.isConfirmedEditing, payload.index);
    if (!content.value) info(msgs.of('noData').value, close);
    nextTick(() => toast());
  }

  const changeWorkbookConfirmedEditing = useChangeEditingConfirmedContentWorkbookProblems();
  async function updateWorkbookConfirmedEditing(
    req: ChangeEditingConfirmedContentWorkbookProblemsRequest
  ) {
    const res = await changeWorkbookConfirmedEditing.execute(req);
    if (isSucceeded(res)) {
      if ('workbook' in res.editingConfirmedContent) {
        return {
          problems: res.editingConfirmedContent.workbook?.problems ?? [],
          dataVersion: res.editingConfirmedContent.dataVersion,
        };
      }
      return { errors: [msgs.of('noData').value] };
    }
    return { errors: res.errors };
  }

  const changeWorkbook = useCreateOrUpdateEditingCourseContentWorkbook();
  async function updateWorkbook(req: CreateOrUpdateEditingCourseContentWorkbookRequest) {
    const res = await changeWorkbook.execute(req);
    if (isSucceeded(res)) {
      const { workbook } = res;
      return { problems: workbook.problems, dataVersion: workbook.dataVersion };
    }
    return { errors: res.errors };
  }

  async function update(problems: Problem[], expectedDataVersion?: number) {
    assertIsDefined(content.value, 'content');
    const { id, isConfirmedEditing } = content.value;

    dialog.value = { ...dialog.value, status: 'updating' };

    let ret: { problems: Problem[]; dataVersion: number } | { errors: ErrorMessage[] };
    if (isConfirmedEditing) {
      assertIsDefined(expectedDataVersion, 'dataVersion');
      ret = await updateWorkbookConfirmedEditing({ id, expectedDataVersion, problems });
    } else {
      ret = await updateWorkbook({ id, expectedDataVersion, problems });
    }

    if ('errors' in ret) {
      dialog.value = { ...dialog.value, status: 'changed' };
      error(ret.errors);
      return false;
    }

    dialog.value = { ...dialog.value, status: 'updated' };

    content.value = { ...content.value, problems: ret.problems };
    input.value = { ...input.value, dataVersion: ret.dataVersion };
    return true;
  }

  async function changeDelay() {
    dialog.value = { ...dialog.value, status: 'changed' };
    if (!observer.value) return;
    assertIsDefined(observer.value);
    toast();
    const valid = await observer.value.validate();
    if (!valid) return;

    const validProblem = validateProblems(input.value, inputOptions.value);
    if (validProblem.errors.length > 0) {
      toast(validProblem.errors.map((key) => msgs.of(key).value));
      return;
    }

    assertIsDefined(content.value, 'content');
    const { problems, index } = changeProblems(content.value.problems, validProblem.problem);
    const success = await update(problems, input.value.dataVersion);
    if (success) {
      input.value = { ...input.value, index };
      emit('refresh', { item: 'workbook', index });
    }
  }

  const waitingChange = ref(0);
  function tryChange() {
    if (waitingChange.value === 0) return;
    waitingChange.value = 0;
    changeDelay();
  }

  function change() {
    waitingChange.value += 1;
    useTimeoutFn(tryChange, CHANGE_DELAY);
  }

  async function remove() {
    const c = content.value;
    assertIsDefined(c, 'content');
    const problem = c.problems.find((item) => item.index === input.value.index);
    assertIsDefined(problem, 'problem');
    confirm(msgs.of('confirmRemove').value, async () => {
      dialog.value = { ...dialog.value, status: 'updating' };
      const { problems } = changeProblems(c.problems, problem, true);
      const success = await update(problems, input.value.dataVersion);
      if (success) {
        emit('refresh', { item: 'workbook' });
        close();
      }
    });
  }

  function toggleCorrect(option: ProblemOptionForm) {
    Object.assign(option, { correct: !option.correct });
    change();
  }

  function scrollToEnd() {
    const [e] = document.getElementsByClassName('problem-dialog');
    if (!e || !e.firstChild) return;
    const { height } = (e.firstChild as Element).getBoundingClientRect();
    e.scrollTo({ top: height });
  }

  function addOption() {
    const option = { text: '', correct: false };
    inputOptions.value.push(option);
    dialog.value = { ...dialog.value, status: 'changed' };
    nextTick(() => useTimeoutFn(scrollToEnd, 300));
  }
  function removeOption(index: number) {
    inputOptions.value.splice(index, 1);
    change();
  }

  function clearProblemHeaderId() {
    input.value = { ...input.value, headerId: undefined };
    change();
  }

  function updateProblemHeaderId(payload: ProblemHeaderSelectPayload) {
    input.value = { ...input.value, headerId: payload.id };
    change();
  }

  const removeProblemHeaderConfirmedEditing =
    useRemoveProblemHeaderFromEditingConfirmedContentWorkbook();
  async function deleteProblemHeaderConfirmedEditing(
    req: RemoveProblemHeaderFromEditingConfirmedContentWorkbookRequest
  ) {
    const res = await removeProblemHeaderConfirmedEditing.execute(req);
    if (isSucceeded(res)) {
      if ('workbook' in res.editingConfirmedContent) {
        return {
          problemHeaders: res.editingConfirmedContent.workbook?.problemHeaders ?? [],
          dataVersion: res.editingConfirmedContent.dataVersion,
        };
      }
      return { errors: [msgs.of('noData').value] };
    }
    return { errors: res.errors };
  }

  const removeProblemHeaderEditingCourse = useRemoveProblemHeaderToEditingCourseContentWorkbook();
  async function deleteProblemHeaderEditingCourse(
    req: RemoveProblemHeaderToEditingCourseContentWorkbookRequest
  ) {
    const res = await removeProblemHeaderEditingCourse.execute(req);
    if (isSucceeded(res)) {
      const { workbook } = res;
      return { problemHeaders: workbook.problemHeaders, dataVersion: workbook.dataVersion };
    }
    return { errors: res.errors };
  }

  async function removeProblemHeader(payload: ProblemHeaderSelectPayload) {
    assertIsDefined(content.value, 'content');

    const { id, isConfirmedEditing } = content.value;
    const expectedDataVersion = input.value.dataVersion;
    assertIsDefined(expectedDataVersion, 'dataVersion');

    dialog.value = { ...dialog.value, status: 'updating' };

    let ret: { problemHeaders: ProblemHeader[]; dataVersion: number } | { errors: ErrorMessage[] };
    if (isConfirmedEditing) {
      ret = await deleteProblemHeaderConfirmedEditing({
        id,
        problemHeaderId: payload.id,
        expectedDataVersion,
      });
    } else {
      ret = await deleteProblemHeaderEditingCourse({
        id,
        problemHeaderId: payload.id,
        expectedDataVersion,
      });
    }
    if ('errors' in ret) {
      dialog.value = { ...dialog.value, status: 'changed' };
      error(ret.errors);
      return;
    }

    dialog.value = { ...dialog.value, status: 'updated' };

    content.value = { ...content.value, problemHeaders: ret.problemHeaders };
    input.value = { ...input.value, dataVersion: ret.dataVersion };
    if (input.value.headerId === payload.id) clearProblemHeaderId();
  }

  const problemHeaders = computed(() => content.value?.problemHeaders ?? []);
  const problems = computed(() => content.value?.problems ?? []);
  const problemHeaderDialog = ref<ProblemHeaderDialog>();
  function openProblemHeader(payload?: ProblemHeaderSelectPayload) {
    assertIsDefined(problemHeaderDialog.value);
    if (payload) {
      const header = problemHeaders.value.find((item) => item.id === payload.id);
      if (header) problemHeaderDialog.value.open(header);
      return;
    }
    problemHeaderDialog.value.open();
  }

  const addProblemHeaderConfirmedWorkbook = useAddProblemHeaderToEditingConfirmedContentWorkbook();
  async function createProblemHeaderConfirmedEditing(
    req: AddProblemHeaderToEditingCourseContentWorkbookRequest
  ) {
    const res = await addProblemHeaderConfirmedWorkbook.execute(req);
    if (isSucceeded(res)) {
      if ('workbook' in res.editingConfirmedContent) {
        return {
          problemHeaders: res.editingConfirmedContent.workbook?.problemHeaders ?? [],
          dataVersion: res.editingConfirmedContent.dataVersion,
        };
      }
      return { errors: [msgs.of('noData').value] };
    }
    return { errors: res.errors };
  }

  const changeProblemHeaderConfirmWorkbook =
    useChangeEditingConfirmedContentWorkbookProblemHeader();
  async function updateProblemHeaderConfirmedEditing(
    req: ChangeEditingConfirmedContentWorkbookProblemHeaderRequest
  ) {
    const res = await changeProblemHeaderConfirmWorkbook.execute(req);
    if (isSucceeded(res)) {
      if ('workbook' in res.editingConfirmedContent) {
        return {
          problemHeaders: res.editingConfirmedContent.workbook?.problemHeaders ?? [],
          dataVersion: res.editingConfirmedContent.dataVersion,
        };
      }
      return { errors: [msgs.of('noData').value] };
    }
    return { errors: res.errors };
  }

  const addProblemHeaderEditingCourse = useAddProblemHeaderToEditingCourseContentWorkbook();
  async function createProblemHeaderEditingCourse(
    req: AddProblemHeaderToEditingCourseContentWorkbookRequest
  ) {
    const res = await addProblemHeaderEditingCourse.execute(req);
    if (isSucceeded(res)) {
      const { workbook } = res;
      return { problemHeaders: workbook.problemHeaders, dataVersion: workbook.dataVersion };
    }
    return { errors: res.errors };
  }

  const changeProblemHeaderEditingCourse = useChangeEditingCourseContentWorkbookProblemHeader();
  async function updateProblemHeaderEditingCourse(
    req: ChangeEditingCourseContentWorkbookProblemHeaderRequest
  ) {
    const res = await changeProblemHeaderEditingCourse.execute(req);
    if (isSucceeded(res)) {
      const { workbook } = res;
      return { problemHeaders: workbook.problemHeaders, dataVersion: workbook.dataVersion };
    }
    return { errors: res.errors };
  }

  async function changeProblemHeader(payload: ProblemHeaderDialogPayload) {
    assertIsDefined(content.value, 'content');

    const { id, isConfirmedEditing } = content.value;
    const expectedDataVersion = input.value.dataVersion;
    assertIsDefined(expectedDataVersion, 'dataVersion');

    let ret: { problemHeaders: ProblemHeader[]; dataVersion: number } | { errors: ErrorMessage[] };
    if (isConfirmedEditing && payload.value.id) {
      ret = await updateProblemHeaderConfirmedEditing({
        id,
        problemHeaderId: payload.value.id,
        body: payload.value.body ?? '',
        expectedDataVersion,
      });
    } else if (isConfirmedEditing) {
      ret = await createProblemHeaderConfirmedEditing({
        id,
        body: payload.value.body ?? '',
        expectedDataVersion,
      });
    } else if (payload.value.id) {
      ret = await updateProblemHeaderEditingCourse({
        id,
        problemHeaderId: payload.value.id,
        body: payload.value.body ?? '',
        expectedDataVersion,
      });
    } else {
      ret = await createProblemHeaderEditingCourse({
        id,
        body: payload.value.body ?? '',
        expectedDataVersion,
      });
    }
    if ('errors' in ret) {
      payload.done(ret.errors);
      return;
    }
    content.value = { ...content.value, problemHeaders: ret.problemHeaders };
    input.value = { ...input.value, dataVersion: ret.dataVersion };
    payload.done();
  }

  const notFound = computed(() => !content.value);
  const problemHeaderBody = computed(() => {
    if (input.value.headerId) {
      const header = problemHeaders.value.find((item) => item.id === input.value.headerId);
      if (header) return header.body;
      return msgs.of('unknownHeader', { id: input.value.headerId }).value;
    }
    return undefined;
  });
  const choiceTypes = computed(() =>
    msgs.listOf('choiceTypes').value.map((item) => ({ ...item, value: item.value === 'multiple' }))
  );

  return {
    dialogFullScreen,
    confirmDialog,
    dialog,
    observer,
    content,
    input,
    inputOptions,
    problemHeaders,
    problems,
    loading,
    problemHeaderDialog,
    notFound,
    problemHeaderBody,
    choiceTypes,
    title: msgs.of('editContent'),
    subtitle: msgs.of('workbook'),
    labelChoiceType: msgs.of('choiceType'),
    labelBody: msgs.of('body'),
    labelBodyParticular: msgs.of('bodyParticular'),
    labelClearHeader: msgs.of('clearHeader'),
    labelCommentary: msgs.of('commentary'),
    labelOptions: msgs.of('options'),
    labelAddOption: msgs.of('addOption'),
    labelRemoveOption: msgs.of('removeOption'),
    labelCorrect: msgs.of('correct'),
    labelInCorrect: msgs.of('incorrect'),
    labelRemove: msgs.of('remove'),
    delay: CHANGE_DELAY + 100,
    close,
    open,
    opened,
    change,
    remove,
    toggleCorrect,
    addOption,
    removeOption,
    clearProblemHeaderId,
    updateProblemHeaderId,
    removeProblemHeader,
    openProblemHeader,
    changeProblemHeader,
  };
}

export type ProblemDialog = ReturnType<typeof useProblemDialog>;
