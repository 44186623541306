import { computed, ref } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';

import { useMessages } from '@/base/app';
import {
  BaseTable,
  BaseTableActionPayload,
} from '@/base/app/components/molecules/BaseTableComposable';

const HEADERS: DataTableHeader[] = [
  { value: 'name', text: '', class: 'base-table-name' },
  { value: 'preview', text: '', align: 'center', width: 300, sortable: false },
  { value: 'statusLabel', text: '', width: 200 },
];

function useTable() {
  const table = ref<BaseTable>();
  function clear() {
    if (table.value) table.value.clearSelection();
  }
  return { table, clear };
}

export type GroupTableItem = {
  id: string;
  name: string;
  enabled: boolean;
};

export type PropsGroupTable = {
  groups: GroupTableItem[];
  headerKeys: string[];
};

export type GroupTableActionPayload = {
  event: string;
  selected: GroupTableItem[];
};

export function useGroupTable(
  props: PropsGroupTable,
  emit: (name: string, args: GroupTableActionPayload) => void
) {
  const msgs = useMessages({ prefix: 'contents.molecules.groupTable' });

  const headers = computed(() =>
    props.headerKeys
      .map((value) => HEADERS.find((h) => h.value === value))
      .filter((h) => !!h)
      .map((h) => ({ ...h, text: h ? msgs.of(h.value).value : '' }))
  );
  const items = computed(() => {
    const enabled = msgs.of('enabled').value;
    const disabled = msgs.of('disabled').value;
    return props.groups
      .map((g) => ({ ...g, statusLabel: g.enabled ? enabled : disabled }))
      .sort((a, b) => {
        if (a.enabled === b.enabled) return a.name < b.name ? -1 : 1;
        return a.enabled > b.enabled ? -1 : 1;
      });
  });

  function action(payload: BaseTableActionPayload) {
    const selected = payload.selected
      .map((id) => props.groups.find((g) => g.id === id))
      .filter((g) => g) as GroupTableItem[];
    emit('action', { ...payload, selected });
  }

  return { headers, items, action, ...useTable() };
}

export type GroupTable = ReturnType<typeof useGroupTable>;
