import { computed, ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { waitTransition } from '@/base/app/utils/TransitionUtils';
import { ContentId } from '@/base/domains';
import { Optional } from '@/base/types';
import { isFailed } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';

import { useConfirmConfirmedContentEditing } from '../../../usecases';
import { ContentVersionForm, ContentVersionValue } from '../molecules/ContentVersionFormComposable';

type ContentConfirmEditingDialogTarget = {
  id: ContentId;
};

export function useContentConfirmEditingDialog(emit: (name: string) => void) {
  const dialog = ref(false);
  const displayDescription = ref(false);

  const target = ref<ContentConfirmEditingDialogTarget>();

  const form = ref<ContentVersionForm>();
  const input = ref<ContentVersionValue>({ description: '' });
  const errors = ref<ErrorMessage[]>();
  const loading = ref(false);

  function close() {
    dialog.value = false;
    displayDescription.value = false;
    input.value = { description: '' };
    errors.value = undefined;
    if (form.value) form.value.reset();
  }

  function open(payload: ContentConfirmEditingDialogTarget) {
    dialog.value = true;
    target.value = payload;
  }

  function toggleDisplayDescription() {
    displayDescription.value = !displayDescription.value;
  }

  const confirm = useConfirmConfirmedContentEditing();
  async function submit() {
    assertIsDefined(target.value, 'target');
    errors.value = undefined;

    let versionDescription: Optional<string>;
    if (displayDescription.value) {
      const valid = await form.value?.submit();
      if (!valid) return;
      versionDescription = input.value.description;
    }

    const { id } = target.value;

    loading.value = true;
    const res = await confirm.execute({ id, versionDescription });
    if (isFailed(res)) {
      loading.value = false;
      errors.value = res.errors;
      return;
    }
    waitTransition(() => {
      loading.value = false;
      close();
      emit('done');
    });
  }

  const msgs = useMessages({ prefix: 'contents.organisms.contentConfirmEditingDialog' });
  const labelVersionDescription = computed(
    () =>
      msgs.of(displayDescription.value ? 'addVersionDescription' : 'doNotAddVersionDescription')
        .value
  );

  return {
    dialog,
    displayDescription,
    form,
    input,
    errors,
    loading,
    labelVersionDescription,
    description: msgs.of('description'),
    hint: msgs.of('hintContentVersion'),
    labelFix: msgs.of('fix'),
    labelClose: msgs.of('close'),
    close,
    open,
    toggleDisplayDescription,
    submit,
  };
}

export type ContentConfirmEditingDialog = ReturnType<typeof useContentConfirmEditingDialog>;
