






































































































































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseButtonIcon from '@/base/app/components/atoms/BaseButtonIcon.vue';
import ProblemNavigator from '@/base/app/components/atoms/ProblemNavigator.vue';
import BaseDialogOk from '@/base/app/components/molecules/BaseDialogOk.vue';
import ContentFooter from '@/base/app/components/molecules/ContentFooter.vue';
import ProblemView from '@/base/app/components/molecules/ProblemView.vue';
import {
  ProblemUtilsNavigator,
  ProblemUtilsNavigatorValue,
  ProblemUtilsProblem,
} from '@/base/app/utils/ProblemUtils';

import { GetCourseContentDetailsContent } from '../../../usecases';
import { PropsContentWorkbook, useContentWorkbook } from './ContentWorkbookComposable';
import ProblemDialog from './ProblemDialog.vue';
import ProblemListDialog from './ProblemListDialog.vue';

type Props = PropsContentWorkbook;
export default defineComponent({
  name: 'ContentsWorkbook',
  components: {
    BaseButton,
    BaseButtonIcon,
    ProblemNavigator,
    ProblemView,
    ProblemDialog,
    ProblemListDialog,
    BaseDialogOk,
    ContentFooter,
  },
  props: {
    index: { type: [Number, String] as PropType<ProblemUtilsNavigatorValue>, default: 0 },
    content: { type: Object as PropType<GetCourseContentDetailsContent>, required: true },
    problems: { type: Array as PropType<ProblemUtilsProblem[]>, default: () => [] },
    problemNavigator: { type: Object as PropType<ProblemUtilsNavigator>, default: undefined },
    isConfirmedEditing: { type: Boolean, default: false },
    hide: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  emits: ['change', 'choice-value', 'check-value', 'clear-value', 'click-anchor', 'refresh'],
  setup(props: Props, { emit }: SetupContext) {
    return useContentWorkbook(props, emit);
  },
});
