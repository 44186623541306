var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-dialog-full-screen',{ref:"dialogFullScreen",attrs:{"title":_vm.title,"subtitle":_vm.subtitle},on:{"closed":_vm.close},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.course)?_c('v-sheet',{staticClass:"overflow-y-auto mx-auto my-5 pb-10",attrs:{"rounded":"","width":"min(800px, 80vw)","max-height":"calc(100vh - 100px)"}},[(!_vm.editing)?_c('v-sheet',{staticClass:"pa-10 mb-10",attrs:{"dark":_vm.theme === 'dark',"rounded":""}},[_c('div',{staticClass:"d-flex justify-end align-center mb-3"},[_c('div',{staticClass:"text-caption secondary--text px-3"},[_vm._v(_vm._s(_vm.labelTheme))]),_c('v-btn-toggle',{attrs:{"dense":"","mandatory":""},model:{value:(_vm.theme),callback:function ($$v) {_vm.theme=$$v},expression:"theme"}},[_c('v-btn',{attrs:{"small":"","value":"light"}},[_vm._v(_vm._s(_vm.labelLight))]),_c('v-btn',{attrs:{"small":"","value":"dark"}},[_vm._v(_vm._s(_vm.labelDark))])],1)],1),_c('course-background',{attrs:{"course-color":_vm.course.color,"course-image":_vm.course.image,"course-font-color-on-image":_vm.course.fontColorOnImage,"rounded":"","height":"min(240px, 40vh)"}},[_c('div',{staticClass:"d-flex mx-10 my-auto"},[_c('div',{staticClass:"drop-box pa-5"},[_c('span',{staticClass:"text-h6 text-sm-h4"},[_vm._v(_vm._s(_vm.course.name))])])]),(_vm.course.image && !_vm.editing)?_c('div',{staticClass:"drop-box pa-1",staticStyle:{"position":"absolute","bottom":"0.8rem","right":"0.8rem"}},[_c('base-button',{on:{"click":function($event){_vm.editing = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-image")]),_vm._v(" "+_vm._s(_vm.labelChange)+" ")],1)],1):_vm._e()])],1):_vm._e(),_c('div',{class:{
        'd-flex flex-column align-center justify-center pa-10 mb-10': _vm.editing,
        'd-none': !_vm.editing,
      },staticStyle:{"min-height":"calc(min(240px, 40vh) + 74px)"}},[_c('cropper',{ref:"cropper",staticStyle:{"width":"100%"},attrs:{"options":{
          aspectRatio: 800 / 400,
          viewMode: 1,
          rotatable: false,
          preview: '.course-background-dialog-preview',
        },"max-height":"min(400px, 40vh)"},on:{"ready":function($event){_vm.cached = true}}},[(_vm.cached)?_c('base-button',{staticClass:"ml-auto mt-3 mr-3",attrs:{"loading":_vm.cropping},on:{"click":_vm.uploadImage}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-upload")]),_vm._v(" "+_vm._s(_vm.labelUpload)+" ")],1):_vm._e(),(_vm.course.image)?_c('base-button',{staticClass:"mt-3 mr-3",attrs:{"text":"","outlined":false,"color":"secondary"},on:{"click":function($event){_vm.editing = false}}},[_vm._v(" "+_vm._s(_vm.labelCancel)+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"px-10"},[_c('v-row',{staticClass:"mb-10",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"px-3",attrs:{"cols":"auto"},on:{"click":function($event){_vm.editing = true}}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{attrs:{"icon":""}},[(_vm.course.image || _vm.editing)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-radiobox-marked")]):_c('v-icon',[_vm._v("mdi-radiobox-blank")])],1),_c('div',{staticStyle:{"cursor":"default"}},[_vm._v(_vm._s(_vm.labelUseImage))])],1)]),(_vm.course.image && !_vm.editing)?_c('v-col',{staticClass:"pl-10",attrs:{"cols":"auto"}},[_c('v-radio-group',{staticClass:"mt-0 pa-0",attrs:{"value":_vm.course.fontColorOnImage,"label":_vm.labelFontColor,"mandatory":"","dense":"","row":"","hide-details":""},on:{"change":_vm.changeImageFontColor}},_vm._l((_vm.fontColors),function(c,i){return _c('v-radio',_vm._b({key:i},'v-radio',c,false))}),1)],1):_vm._e()],1),_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.colors),function(c,i){return _c('course-background-radio',_vm._b({key:i,staticClass:"mx-3",attrs:{"color":c,"checked":c === _vm.color && !_vm.editing},on:{"change":_vm.changeColor}},'course-background-radio',_vm.size,false))}),1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }