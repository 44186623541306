import { computed, onMounted, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { BaseMarkdownClickAnchorPayload } from '@/base/app/components/atoms/BaseMarkdownComposable';
import { DialogName, useDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { Text } from '@/base/domains';
import { useRoute } from '@/utils/VueUtils';

import { GetCourseContentDetailsContent } from '../../../usecases';
import {
  ContentHeaderDialog,
  ContentHeaderDialogRefreshPayload,
} from './ContentHeaderDialogComposable';
import { TextBodyDialog, TextBodyDialogRefreshPayload } from './TextBodyDialogComposable';

export type ContentTextClickAnchorPayload = BaseMarkdownClickAnchorPayload;

export type ContentTextRefreshPayload =
  | TextBodyDialogRefreshPayload
  | ContentHeaderDialogRefreshPayload;

export type PropsContentText = {
  content: GetCourseContentDetailsContent;
  isConfirmedEditing: boolean;
  disabled: boolean;
};

function useHeaderDialog(props: PropsContentText) {
  const headerDialog = ref<ContentHeaderDialog>();
  const { getQuery, moveTo: moveToHeader } = useDialogQuery(DialogName.CONTENTS_HEADER);
  function watchHeaderDialogQuery() {
    if (!headerDialog.value) return;
    const q = getQuery();
    if (q) {
      headerDialog.value.open({ courseId: props.content.courseId, contentId: props.content.id });
    } else if (!q && headerDialog.value.opened()) {
      headerDialog.value.close();
    }
  }
  return { headerDialog, moveToHeader, watchHeaderDialogQuery };
}

function useBodyDialog(props: PropsContentText) {
  const bodyDialog = ref<TextBodyDialog>();
  const { getQuery, moveTo: moveToBody } = useDialogQuery(DialogName.CONTENTS_TEXT_BODY);
  function watchBodyDialogQuery() {
    if (!bodyDialog.value) return;
    const q = getQuery();
    if (q) {
      bodyDialog.value.open({ id: props.content.id });
    } else if (!q && bodyDialog.value.opened()) {
      bodyDialog.value.close();
    }
  }
  return { bodyDialog, moveToBody, watchBodyDialogQuery };
}

export function useContentText(
  props: PropsContentText,
  emit: (name: string, arg: ContentTextClickAnchorPayload | ContentTextRefreshPayload) => void
) {
  const msgs = useMessages({ prefix: 'contents.organisms.contentText' });
  const route = useRoute();
  const { headerDialog, moveToHeader, watchHeaderDialogQuery } = useHeaderDialog(props);
  const { bodyDialog, moveToBody, watchBodyDialogQuery } = useBodyDialog(props);

  const contentBody = computed(() => {
    if (props.content.type !== 'text') return undefined;
    return props.content.body as Text;
  });

  const existsWorkbook = computed(() => {
    if (props.content.type !== 'text') return false;
    const { workbook } = props.content;
    return workbook && workbook.problems.length > 0;
  });

  function initDialog() {
    watchHeaderDialogQuery();
    watchBodyDialogQuery();
  }

  function init() {
    initDialog();
  }
  onMounted(init);
  watch(() => route.query, initDialog);

  function clickAnchor(payload: BaseMarkdownClickAnchorPayload) {
    emit('click-anchor', payload);
  }

  function refresh(payload: TextBodyDialogRefreshPayload | ContentHeaderDialogRefreshPayload) {
    emit('refresh', payload);
  }

  return {
    contentBody,
    existsWorkbook,
    headerDialog,
    bodyDialog,
    labelEdit: msgs.of('edit'),
    moveToHeader,
    moveToBody,
    clickAnchor,
    refresh,
  };
}
