

































import { defineComponent, SetupContext } from '@vue/composition-api';
import draggable from 'vuedraggable';

import BaseDialogFullScreen from '@/base/app/components/molecules/BaseDialogFullScreen.vue';
import DialogAnchorConfirm from '@/base/app/components/organisms/DialogAnchorConfirm.vue';
import Loading from '@/base/app/components/organisms/Loading.vue';

import ProblemItem from '../molecules/ProblemItem.vue';
import ProblemItemDialog from '../molecules/ProblemItemDialog.vue';
import { useProblemListDialog } from './ProblemListDialogComposable';

export default defineComponent({
  name: 'ContentsProblemListDialog',
  components: {
    draggable,
    BaseDialogFullScreen,
    Loading,
    ProblemItem,
    ProblemItemDialog,
    DialogAnchorConfirm,
  },
  emits: ['refresh'],
  setup(_, { emit }: SetupContext) {
    return useProblemListDialog(emit);
  },
});
