


































































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseButtonIcon from '@/base/app/components/atoms/BaseButtonIcon.vue';
import CourseBackground from '@/base/app/components/molecules/CourseBackground.vue';
import { Course } from '@/base/domains';

import { PropsCourseDetails, useCourseDetails } from './CourseDetailsComposable';

type Props = PropsCourseDetails;

export default defineComponent({
  name: 'ContentsCourseDetails',
  components: { BaseButtonIcon, CourseBackground },
  props: {
    course: { type: Object as PropType<Course>, required: true },
    disabled: { type: Boolean, default: false },
    height: { type: String, default: undefined },
    versionCreatedName: { type: String, default: undefined },
    confirmedName: { type: String, default: undefined },
    contentLastUpdatedName: { type: String, default: undefined },
  },
  emits: ['open-color-or-image', 'open-description'],
  setup(props: Props, { emit }: SetupContext) {
    return useCourseDetails(props, emit);
  },
});
